import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { gaTrack } from '../../services/analytics';
import getFileUrl from '../../utils/getFileUrl';
import styles from './DeckBoxV3.module.scss';
import placeholderImage from '../../images/deck_placeholder.svg';
import { Deck } from '../../types';
import useToast from '../../hooks/useToast';
import variables from  '../../variables.module.scss';
import axios from '../../utils/axios';
import getDeckName from '../../utils/getDeckName';
import { getName, truncateText } from '../../utils/textUtils';

interface DeckBoxInterface {
  deck: Deck;
  onDeckUpdated: (deckId: string) => void;
}

const DeckBoxV3: React.FC<DeckBoxInterface> =
({ deck, onDeckUpdated }) => {
  const { t } = useTranslation('deck_list');
  const context = useContext(AppContext);
  const currentUser = context.currentUser;

  const toast = useToast();

  const deckName = getDeckName(deck, currentUser);

  const onFollowClicked = async (e: React.MouseEvent<HTMLElement>) => {
    gaTrack('Teachers Decks', 'Follow deck');
    e.preventDefault();
    await axios.post(`/decks/${deck.id}/follow`);

    toast({
      title: t('you_have_added_deck_title'),
      description: t('you_have_added_deck', { name: deckName }),
      status: 'success',
    });

    onDeckUpdated(deck.id);
  };

  return (
    <div className={styles.deckBox}>
      <Link className={styles.linkWrapper} to={`/deck?id=${deck.nid}`}>
        <Image
          className={styles.image}
          objectFit="cover"
          src={getFileUrl(deck.coverImageFile)}
          alt={deckName}
          fallback={<div className={styles.placeholderImageWrapper}>
            <img className={styles.placeholderImage} src={placeholderImage} alt={deckName} />
          </div>
          }
        />
        <div>
          <div className={styles.deckName}>
            {truncateText(deckName, 25)}
          </div>
          <div>{t('by_sb', { name: getName(deck.createdByUser.fullName) })}</div>
        </div>
      </Link>
      { (currentUser && !deck.following && deck.createdBy !== currentUser?.id) && (
        <Button
          _hover={{ filter: 'brightness(90%)' }}
          aria-label="play audio"
          className={styles.followButton}
          onClick={onFollowClicked}
          color="#F79411"
          lineHeight="39px"
          height="43px"
          paddingLeft="16px"
          textTransform="none"
          backgroundRepeat="no-repeat"
          backgroundSize="133px"
          backgroundColor="white"
          border="2px solid #F79411"
          marginLeft="10px"
          fontFamily={variables.normalFontFamily}
        >{t('follow')}</Button>)
      }
    </div>
  );
};

export default DeckBoxV3;
