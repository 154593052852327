import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Progress, ButtonProps, Button } from '@chakra-ui/react';

import DeckMenu from '../../components/DeckMenu';
import getDeckName from '../../utils/getDeckName';
import styles from './DeckCardTopBar.module.scss';
import { AppContext } from '../../AppContext';
import { Deck } from '../../types';

interface DeckCardTopBarInterface {
  getCards: (val: boolean) => void;
  deck: Deck | undefined;
  count: number | undefined;
}

const buttonStyle = {
  width: '86px',
  background: 'white',
  border: '1px solid #F79411',
  color: '#F79411',
  height: '39px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: '18px',
  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25))',
} as ButtonProps;

const DeckCardTopBar: React.FC<DeckCardTopBarInterface> = ({ deck, getCards, count }) => {

  const history = useHistory();
  const context = useContext(AppContext);
  const { t } = useTranslation('deck_card_list');

  const onDeckUpdated = (deleted: boolean) => {
    if (deleted) {
      history.replace('/decks');
    } else {
      getCards(true);
    }
  };

  const progress = deck ? (
    deck.cardsCount > 0 ? Math.floor(deck.userDeckScore / deck.cardsCount * 100) : 0) : 0;

  return <div className={styles.topBarOuter}>
      <div className={styles.topBar}>
        <div className={styles.row1}>
          <div className={styles.deckName}>
            <Link to={'/decks'} >
              {t('decks')}
            </Link> &gt; <span className={styles.deckNameBold}>
              {deck && getDeckName(deck, context.currentUser)}</span>
          </div>
          { deck && (
            <DeckMenu deck={deck} onDeckUpdated={onDeckUpdated} />
          )}
        </div>

        <div className={styles.row2}>
          <div className={styles.progress}>
            <Progress
              value={progress}
              height="6px"
              maxWidth="277px"
              borderRadius="6px"
              marginRight="14px"
              backgroundColor="#B3CDF5"
              flex="1"
            />
            <span className={styles.progressValue}>{ progress }%</span>
          </div>

          <div className={styles.deckCount}>
            {t('cards', { count, by: deck?.createdByUser.fullName })}
          </div>
        </div>

        <div className={styles.row3}>
          {t('progress')}
        </div>

        <div className={styles.row4}>
          <div>
            <Button
              {...buttonStyle}
              marginRight="8px"
              as={Link}
              to={`/play/${deck?.id}?limit=20`}
            >
              {t('play')}
            </Button>
            <Button
              {...buttonStyle}
              as={Link}
              to={`/deck?cardId=0&id=${deck?.nid}`}
            >
              {t('study')}
            </Button>
          </div>
        </div>

      </div>
    </div>
  ;
};

export default DeckCardTopBar;
