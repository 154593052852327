import { useState, Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { Image, IconButton, Button } from '@chakra-ui/react';
import imageCompression from 'browser-image-compression';
import ReactCardFlip from 'react-card-flip';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './CardView.module.scss';
import { File as FileType } from '../../types';
import playAudioIcon from '../../images/play-audio.svg';
import flipIcon from '../../images/flip.svg';
import cameraIcon from '../../images/camera.svg';
import refreshPhotoIcon from '../../images/refreshphoto.svg';
import getFileUrl from '../../utils/getFileUrl';
import downloadIcon from '../../images/download-card.svg';
import closeIcon from '../../images/close.svg';
import * as sfx from '../../sfx';
import { gaTrack } from '../../services/analytics';
import ScoreIcon from '../ScoreIcon';
import LoadingSpinner from '../LoadingSpinner';
import variables from  '../../variables.module.scss';
import Video from '../../components/Video';

interface CardViewInterface {
  imageUrl?: string | undefined;
  word: string;
  pronunciation: string;
  audio: FileType | string | undefined | null;
  meaning: string;
  onNextPhotoClick?: () => void;
  hideShadow?: boolean;
  onDownloadClicked?: () => void;
  onClose?: () => void;
  spin?: boolean | undefined;
  setSpin?: Dispatch<SetStateAction<boolean>>;
  score?: number | undefined;
  language: string | undefined;
  onImageLoaded?: (file: File) => void;
  onUploadClicked?: () => void;
}

const audioButtonStyleWithImage = {
  position: 'absolute',
  bottom: '12px',
  left: '18px',
};

const audioButtonStyleWithoutImage = {
  marginTop: '50px',
  marginBottom: '-80px',
};

const CardView: React.FC<CardViewInterface> = (
  { imageUrl, onImageLoaded, word, pronunciation, audio,
    meaning, onNextPhotoClick, hideShadow, onDownloadClicked, onClose,
    spin, score, language, onUploadClicked }) => {

  const [playing, setPlaying] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const { t } = useTranslation('card_view');

  const _onUploadClicked = () => {
    if (onUploadClicked) {
      gaTrack('Preview Card', 'Upload image');
      onUploadClicked();
    }
  };

  const onPlayClicked = () => {
    gaTrack('Preview Card', 'Playback audio');
    if (audio) {
      const player = new Audio(typeof audio === 'string' ? audio : getFileUrl(audio));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const onImageLoad = async (event: SyntheticEvent<HTMLImageElement, Event>) => {
    if (onImageLoaded) {
      try {
        const canvas = imageCompression.drawImageInCanvas(event.currentTarget);
        const file = await imageCompression.canvasToFile(
          canvas, 'image/jpeg', 'temp.jpeg', 0, 0.8);
        onImageLoaded(file);
      } catch {
        // do nothing
      }
    }
  };

  const audioButton = (
    <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        width="38px"
        height="38px"
        onClick={onPlayClicked}
        disabled={!audio || playing}
        icon={<img className={styles.audioButton} alt="play" src={playAudioIcon} />}
        {...((imageUrl ? audioButtonStyleWithImage : audioButtonStyleWithoutImage) as any)}
    />
  );

  const getWordStyleByLength = (length: number) => {
    const fontSize = Math.max(Math.min(Math.floor(51.7 + -14.6 * Math.log10(length)), 34), 8);
    const lineHeight = Math.max(Math.min(Math.floor(60.6 + -16.9 * Math.log10(length)), 39), 10);
    return { fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` };
  };

  const getMeaningStyleByLength = (length: number) => {
    const fontSize = Math.max(Math.min(Math.floor(51.7 + -14.6 * Math.log10(length)), 28), 10);
    const lineHeight = Math.max(Math.min(Math.floor(62.6 + -16.9 * Math.log10(length)), 39), 12);
    return { fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` };
  };

  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <div className={styles.cardPreviewOuter}>
        {onUploadClicked && (
          <IconButton
            onClick={_onUploadClicked}
            aria-label="cover"
            variant="outline"
            isRound={true}
            color="#F79411"
            borderColor="#F79411"
            width="63px"
            height="63px"
            position="absolute"
            top="-15px"
            right="-8px"
            background="white"
            zIndex="1"
            boxShadow= "5px 5px 4px -4px #a0a0a0"
            icon={<img alt="cover" src={cameraIcon} />}
          />
        )}
        {onNextPhotoClick && (
          <IconButton
            onClick={onNextPhotoClick}
            aria-label="cover"
            variant="outline"
            isRound={true}
            color="#F79411"
            borderColor="#F79411"
            width="63px"
            height="63px"
            position="absolute"
            top="60px"
            right="-8px"
            background="white"
            zIndex="1"
            boxShadow= "5px 5px 4px -4px #a0a0a0"
            icon={<img alt="cover" src={refreshPhotoIcon}/>}
          />
        )}
        <div className={classnames(styles.cardPreview, { [styles.hideShadow]: hideShadow })}>
          {imageUrl && (
            <div className={styles.imageWrapper}>
              <LoadingSpinner spin={!!spin}>
                <Video imageUrl={imageUrl} className={styles.video}>
                  <Image
                    className={styles.image}
                    objectFit="cover"
                    pointerEvents="none"
                    userSelect="none"
                    onLoad={onImageLoad}
                    crossOrigin={onImageLoaded ? 'anonymous' : undefined}
                    src={imageUrl}
                  />
                </Video>
                { score !== undefined && (
                <div className={styles.scoreWrapper}>
                  <div className={styles.scoreIcon}>
                    <ScoreIcon
                      score={score}
                    />
                  </div>
                </div>
                )}
              </LoadingSpinner>
              {audioButton}
            </div>
          )}
          <div className={styles.wordAndPronunciation}>
            { language === 'ja' && <div
              style={{ marginBottom: '6px' }}
              className={styles.pronunciation}>{pronunciation}</div> }
              <div className={classnames(styles.word)} style={getWordStyleByLength(word.length)}>
                {word}
              </div>
            { language !== 'ja' && <div
              style={{ marginTop: '10px' }}
              className={styles.pronunciation}>{pronunciation}</div> }
            {!imageUrl && audioButton}
          </div>
          <IconButton
            _hover={{ filter: 'brightness(90%)' }}
            aria-label="flip"
            background="none"
            height="30px"
            width="max-content"
            className={styles.flip}
            hidden={isFlipped}
            onClick={() => {
              setIsFlipped(!isFlipped);
              sfx.flip.play();
              gaTrack('Preview Card', 'Flip card');
            }}
            icon={<img alt="flip" width="30px" src={flipIcon} />}
          />
          { onDownloadClicked && (
              <div className={styles.downloadOuter}>
                <Button
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="download this card"
                  onClick={onDownloadClicked}
                  background={`url(${downloadIcon})`}
                  backgroundRepeat="round"
                  backgroundSize="cover"
                  color="#F79411"
                  width="100%"
                  height="100%"
                  textTransform="none"
                  paddingLeft={12}
                  fontFamily={variables.normalFontFamily}
                >{t('download')}</Button>
              </div>
            )}
        </div>
        { onClose && (
          <IconButton
            aria-label="close"
            icon={<img alt="close" src={closeIcon} />}
            onClick={onClose}
            position="absolute"
            right="0px"
            top="10px"
            width="40px"
            height="40px"
            variant="unstyled"
          />
        )}
      </div>

      <div className={classnames(styles.cardPreviewBack, { [styles.hideShadow]: hideShadow })}>
        <div className={styles.meaning}
        style={getMeaningStyleByLength(meaning.length)}
      >{meaning}</div>
        <IconButton
          _hover={{ filter: 'brightness(90%)' }}
          aria-label="flip"
          background="none"
          height="30px"
          hidden={!isFlipped}
          width="max-content"
          className={styles.flip}
          onClick={() => { setIsFlipped(!isFlipped); sfx.flip.play(); }}
          icon={<img alt="flip" width="30px" src={flipIcon} />}
        />
      </div>
    </ ReactCardFlip>
  );
};

export default CardView;
