import { Image } from '@chakra-ui/image';

import styles from './BlogPostBox.module.scss';
import { BlogPost } from '../../types';
import placeholderImage from '../../images/deck_placeholder.svg';

interface BlogPostBoxInterface {
  post: BlogPost;
}

const BlogPostBox: React.FC<BlogPostBoxInterface> = ({ post }) => {

  return (
    <a href={post.link} rel="noreferrer" target="_blank">
    <div className={styles.postWrapper}>
        <Image
          className={styles.image}
          objectFit="cover"
          src={post.thumbnailMedium}
          alt={post.title}
          fallback={<div className={styles.placeholderImageWrapper}>
            <img className={styles.placeholderImage} src={placeholderImage} alt={post.title} />
          </div>
          }
        />
        <div className={styles.infoWrapper}>
          <div className={styles.title}>{post.title}</div>
          <div className={styles.date}>{post.date}</div>
        </div>
    </div>
    </a>
  );
};

export default BlogPostBox;
