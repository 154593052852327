import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './TeachersDecksV2.module.scss';
import { Deck } from '../../types';
import DeckBoxV3 from '../../components/DeckBoxV3';

interface TeachersDecksInterface {
  decks: Deck[];
  onFollow: (deckId: string) => void;
}

const TeachersDecksV2: React.FC<TeachersDecksInterface> = ({ decks, onFollow }) => {
  return (
    <div>
      <div className={styles.teachersDecks}>
        {decks.map(deck => (
          <DeckBoxV3 deck={deck} key={deck.id} onDeckUpdated={onFollow} />
        ))}
      </div>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        className={styles.teachersDecksMobile}
      >
        {decks.map(deck => (
          <SwiperSlide className={styles.slide} key={deck.id}>
            <DeckBoxV3 deck={deck} key={deck.id} onDeckUpdated={onFollow} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TeachersDecksV2;
