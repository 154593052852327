import { useContext, useEffect, useState,
  useRef, ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import TopBar from '../../components/TopBar';
import { File as FileType, Language, Deck, RecommendedUser, FriendStatus } from '../../types';
import analytics, { gaTrack } from '../../services/analytics';
import compressImage from '../../utils/compressImage';
import SubMenu from '../../components/SubMenu';
import styles from './Account.module.scss';
import Settings from './Settings';
import useToast from '../../hooks/useToast';
import UnfriendDialog from './UnfriendDialog';
import Profile from './Profile';
import RecommendedProfile from './RecommendedProfile';

const Account = () => {
  const { t } = useTranslation('account');
  const context = useContext(AppContext);
  const { id: userId } = useParams() as any;
  const toast = useToast();

  const currentUser = context.currentUser;
  const isSelf = !!(currentUser && (currentUser.username === userId));

  const [fullName, setFullName] = useState('');
  const [friendStatus, setFriendStatus] = useState<FriendStatus>('none');
  const [avatarFileObject, setAvatarFileObject] = useState<FileType | null>(null);
  const [accountSection, setAccountSection] = useState(0);
  const [acquiredWords, setAcquiredWords] = useState(0);
  const [decksCreated, setDesksCreated] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [learningLanguages, setLearningLanguages] = useState<Language[]>([]);
  const [spin, setSpin] = useState<boolean>(true);
  const [decks, setDecks] = useState<Deck[]>([]);
  const [showUnfriendDialog, setShowUnfriendDialog] = useState(false);
  const [recommendedUser, setRecommendedUser] = useState<RecommendedUser>();

  const inputFile = useRef<HTMLInputElement>(null);

  const getDecks = useCallback(
    async () => {
      setSpin(true);
      setDecks([]);
      const response = await axios.get('/decks', { params: { userId, type: 'created' } });
      setDecks(response.data.data.decks);
      setSpin(false);
    },
    [userId],
  );

  const onDeckUpdated = () => {
    getDecks();
  };

  const onImageClick = () => {
    if (isSelf) {
      gaTrack('Account', 'Upload avatar');
      if (inputFile.current !== null) {
        inputFile.current.click();
      }
    }
  };

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (isSelf) {
      const fileList = event.target.files;
      if (fileList !== null && fileList.length > 0) {
        let file = fileList[0];
        file = (await compressImage(file))!;

        const formData = new FormData();
        formData.append('files', file);
        const response = await axios.post('/files', formData);

        await axios.put('users/me/avatar', { fileId: response.data.data.file.id });
        context.getCurrentUser();
      }
    }
  };

  const onAccountSubMenuClicked = (page: number) => {
    if (isSelf) {
      if (page === 0) {
        setAccountSection(0);
      } else if (page === 1) {
        setAccountSection(1);
      }
    }
  };

  const getStatistics = useCallback(
    async () => {
      const statistics = await axios.get(`/users/${userId}/statistics`);
      setAcquiredWords(statistics.data.data.acquired);
      setDesksCreated(statistics.data.data.decksCreated);
      setFriendsCount(statistics.data.data.friendsCount);
    },
    [userId],
  );

  const getUser = useCallback(
    async () => {
      if (!isSelf) {
        const response = await axios.get(`/users/${userId}`);
        const user = response.data.data.user;
        setFullName(user.fullName);
        setAvatarFileObject(user.avatarFile);
        setLearningLanguages(user.learningLanguages);
        setFriendStatus(user.friendStatus);
        setRecommendedUser(user.recommendedUser);
      }
    },
    [userId, isSelf],
  );

  useEffect(
    () => {
      if (currentUser) {
        setFriendStatus('none');
        getStatistics();
        getDecks();
        getUser();
      }
    },
    [getStatistics, userId, getUser, getDecks, currentUser],
  );

  useEffect(
    () => {
      analytics.page();
    },
    [],
  );

  useEffect(
    () => {
      context.setCurrentTab('account');
    },
    [context],
  );

  useEffect(
    () => {
      if (currentUser && isSelf) {
        setFullName(currentUser.fullName);
        setAvatarFileObject(currentUser.avatarFile);
        setLearningLanguages(currentUser.learningLanguages);
        setRecommendedUser(currentUser.recommendedUser);
      }
    },
    [
      isSelf,
      currentUser,
      setFullName,
      setAvatarFileObject,
      setLearningLanguages,
    ],
  );

  const subMenu = isSelf ? <div className={styles.subMenuWrapper}>
    <SubMenu
      onPageClick={onAccountSubMenuClicked}
      menu={[t('your_profile'), t('settings')]}
      currentIndex={accountSection}
      arrowColor={accountSection === 0 && !recommendedUser ? 'white' : 'blue'}
    />
    </div> : <></>;

  const onAddFriendClicked = async () => {
    if (!isSelf) {
      try {
        gaTrack('Account', 'Add friend');
        await axios.post(
          '/friendRequests/', { userId });
      } catch (e) {
        toast({
          title: t('error'),
          description: e.response ?  e.response.data.data.message : t('error_desc'),
          status: 'error',
        });
      }

      getUser();
    }
  };

  const onUnfriendClicked = () => {
    setShowUnfriendDialog(true);
  };

  const onUnfriendSuccess = () => {
    setShowUnfriendDialog(false);
    getUser();
  };

  const locked = friendStatus !== 'isFriend' && !isSelf;

  return (
    <div className={styles.outer}>
      <TopBar backgroundColor={recommendedUser ? '#0FD4CB' : '#2D65C2'} />
      { (accountSection === 0 || !isSelf) ?
        <>
          {recommendedUser ? (
            <RecommendedProfile
              isSelf={isSelf}
              locked={locked}
              spin={spin}
              onDeckUpdated={onDeckUpdated}
              onAddFriendClicked={onAddFriendClicked}
              onUnfriendClicked={onUnfriendClicked}
              onImageChange={onImageChange}
              onImageClick={onImageClick}
              avatarFileObject={avatarFileObject}
              friendsCount={friendsCount}
              decks={decks}
              getUser={getUser}
              userId={userId}
              subMenu={subMenu}
              friendStatus={friendStatus}
              fullName={fullName}
              inputFile={inputFile}
              systemLanguage={currentUser?.systemLanguage}
              recommendedUser={recommendedUser}
            />
          ) : (
            <Profile
              isSelf={isSelf}
              locked={locked}
              spin={spin}
              onDeckUpdated={onDeckUpdated}
              onAddFriendClicked={onAddFriendClicked}
              onUnfriendClicked={onUnfriendClicked}
              onImageChange={onImageChange}
              onImageClick={onImageClick}
              avatarFileObject={avatarFileObject}
              acquiredWords={acquiredWords}
              decksCreated={decksCreated}
              friendsCount={friendsCount}
              learningLanguages={learningLanguages}
              decks={decks}
              getUser={getUser}
              userId={userId}
              subMenu={subMenu}
              friendStatus={friendStatus}
              fullName={fullName}
              inputFile={inputFile}
              systemLanguage={currentUser?.systemLanguage}
            />
          )}
          { !isSelf && (
            <UnfriendDialog
              isOpen={showUnfriendDialog}
              fullName={fullName}
              userId={userId}
              onClose={onUnfriendSuccess}
            />
          )}
        </>
      :
        <Settings subMenu={subMenu} />
      }
    </div>
  );
};

export default Account;
