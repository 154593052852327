import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, SlideRenderProps, bindKeyboard } from 'react-swipeable-views-utils';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';
import TopBar from '../../components/TopBar';
import { Card, Deck } from '../../types';
import CardView from '../../components/CardView';
import styles from './DeckCardSwiper.module.scss';
import cardBackIcon from '../../images/card-back.svg';
import cardNextIcon from '../../images/card-next.svg';
import { AppContext } from '../../AppContext';
import getMeaning from '../../utils/getMeaning';
import editIcon from '../../images/edit-card.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import variables from '../../variables.module.scss';
import getImageUrl from '../../utils/getFileUrl';
import getDeckName from '../../utils/getDeckName';

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const addNewButtonStyle = {
  width: '368px',
  fontFamily: variables.titleFontFamily,
  fontSize: '18px',
  background: '#2D65C2',
  borderRadius: '31px',
  color: 'white',
  height: '53px',
  _hover: {
    background: '#1051aE',
  },
  marginTop: '20px',
};

const DeckCardSwiper = () => {
  const { t } = useTranslation('deck_card_swiper');
  const [deckId] = useQueryParam('id', StringParam);
  const [cardId, setCardId] = useQueryParam('cardId', StringParam);
  const [count, setCount] = useState<number>();
  const [deck, setDeck] = useState<Deck>();

  const [initialized, setInitialized] = useState(false);
  const [rightCursor, setRightCursor] = useState<string | null | undefined>(cardId);
  const [leftCursor, setLeftCursor] = useState<string | null | undefined>(cardId);
  const [cards, setCards] = useState<Map<number, Card>>(new Map());
  const [slideIndex, setSlideIndex] = useState(0);
  const [spin, setSpin] = useState<boolean>(true);

  const context = useContext(AppContext);

  useEffect(
    () => {
      context.setCurrentTab('deck');
    },
    [context],
  );

  useEffect(
    () => {
      context.setShowTabBar(false);
      return () => {
        context.setShowTabBar(true);
      };
    },
    [], // eslint-disable-line
  );

  const getRightCards = useCallback(
    () => {
      return async (isInitial = false) => {
        setRightCursor(null);
        setSpin(true);
        const response = await axios.get(
          `/decks/${deckId}/cards`,
          { params: {
            includeCursor: isInitial,
            cursor: rightCursor,
            descending: true,
          } },
        );

        setRightCursor(response.data.data.cursor);

        setCards((old) => {
          const newCardsMap = new Map(old);
          for (const card of response.data.data.cards) {
            newCardsMap.set(response.data.data.count - card.position - 1, card);
          }
          return newCardsMap;
        });

        if (isInitial) {
          setCount(response.data.data.count);
          setDeck(response.data.data.deck);
          if (cardId) {
            const card = response.data.data.cards.find((c: any) => c.nid === cardId);
            if (card) {
              setSlideIndex(response.data.data.count - card.position - 1);
            }
          }
        }
        setSpin(false);
      };
    },
    [deckId, cardId, rightCursor, setRightCursor, setCards, setCount],
  );

  const getLeftCards = useCallback(
    async () => {
      setLeftCursor(null);
      setSpin(true);
      const response = await axios.get(
        `/decks/${deckId}/cards`,
        { params: { cursor: leftCursor } },
      );

      setLeftCursor(response.data.data.cursor);

      setCards((old) => {
        const newCardsMap = new Map(old);
        for (const card of response.data.data.cards) {
          newCardsMap.set(response.data.data.count - card.position - 1, card);
        }
        return newCardsMap;
      });
      setSpin(false);
    },
    [deckId, leftCursor, setLeftCursor, setCards],
  );

  const currentCard = useMemo(() => cards.get(slideIndex), [cards, slideIndex]);

  const isSentencesEmpty = useMemo(
    () => currentCard?.sentences === undefined ||
    currentCard?.sentences.every(entry => entry.length === 0),
    [currentCard?.sentences],
  );

  const isRemarkEmpty = useMemo(
    () => currentCard?.remark === undefined ||
    currentCard?.remark.length === 0,
    [currentCard?.remark],
  );

  useEffect(
    () => {
      if (!initialized) {
        const initialize = async () => {
          setInitialized(true);
          if (cardId) {
            getLeftCards();
          }
          await getRightCards()(true);
        };
        initialize();
      }
    },
    [initialized, getRightCards, getLeftCards, cardId],
  );

  const slideRenderer = ({ key, index }: SlideRenderProps) => {
    const card = cards.get(index);
    if (card) {
      return (<div key={key} className={styles.cardPreviewWrapper}>
        <CardView
          imageUrl={getImageUrl(card.imageFile)}
          audio={card.audioFile}
          word={card.title}
          meaning={getMeaning(card.meanings, context.currentUser?.systemLanguage, true)}
          pronunciation={card.pronunciation}
          score={card.score}
          language={deck?.language}
        />
      </div>);
    }
    return <div key={key} />;
  };

  const onChangeIndex = (index: number) => {
    if (count !== undefined) {
      const i = Math.min(Math.max(index, 0), count - 1);
      setSlideIndex(i);
    }
  };

  const onNextClick = () => {
    onChangeIndex(slideIndex + 1);
  };

  const onPreviousClick = () => {
    onChangeIndex(slideIndex - 1);
  };

  useEffect(
    () => {
      const card = cards.get(slideIndex);
      if (card) {
        setCardId(card.nid, 'replaceIn');
      }
    },
    [slideIndex, setCardId, cards],
  );

  useEffect(
    () => {
      const fetchCards = async () => {
        if (cards.size > 0) {
          const min = Math.min(...Array.from(cards.keys()));
          const max = Math.max(...Array.from(cards.keys()));
          if (slideIndex - min < 5 && leftCursor) {
            getLeftCards();
          } else if (max - slideIndex < 5 && rightCursor) {
            getRightCards()();
          }
        }
      };
      fetchCards();
    },
    [cards, cardId, leftCursor, rightCursor, slideIndex, getLeftCards, getRightCards],
  );

  return <div className={styles.outer}>
    <TopBar showBackButton={true} />
    <div className={styles.deckCardShow}>
      <div className={styles.top}>
        <Link className={styles.deckName} to={`/deck?id=${deckId}`}>
          {deck && getDeckName(deck, context.currentUser)}
        </Link>

        { currentCard && currentCard?.createdBy === context.currentUser?.id && (
          <div className={styles.editOuter}>
            <Button
              _hover={{ filter: 'brightness(90%)' }}
              aria-label="edit"
              as={Link}
              to={`/card/edit?id=${cardId}`}
              background={`url(${editIcon})`}
              backgroundRepeat="round"
              backgroundSize="cover"
              color="#F79411"
              width="100%"
              height="100%"
              textTransform="none"
              paddingLeft={10}
              paddingBottom={1}
              fontFamily={variables.normalFontFamily}
              fontSize="16px"
            >{t('edit').toUpperCase()}</Button>
          </div>
        )}
      </div>
      <LoadingSpinner spin={spin}>
      { count !== 0 ? (
        <VirtualizeSwipeableViews
          slideRenderer={slideRenderer}
          index={slideIndex}
          onChangeIndex={onChangeIndex}
          slideCount={count}
          hysteresis={0.3}
          slideClassName={styles.slideWrapper}
          resistance
          className={styles.swipeableView}
        />
        ) : (
          <div>
            <div className={styles.emptylist}>{t('empty_list')}</div>
            <div className={styles.noCard}>
              <Button
                as={Link}
                to={{ pathname: '/card/new/', state: { deckId } }}
                textTransform="uppercase"
                {...addNewButtonStyle}
              >
                {t('add_new')}
              </Button>
            </div>
          </div>
        )
      }
      </LoadingSpinner>
      <div className={styles.navigator}>
        <Button
          className={styles.controlButton}
          leftIcon={<img src={cardBackIcon} alt="back" />}
          onClick={onPreviousClick}
          variant="unstyled"
          disabled={!count || slideIndex === 0}
        >
        </Button>
        <div>
          <div className={styles.count}>{t('x_of_y', { x: slideIndex + 1, y: count })}</div>
        </div>
        <Button
          className={styles.controlButton}
          rightIcon={<img src={cardNextIcon} alt="next" />}
          onClick={onNextClick}
          disabled={!count || slideIndex === count - 1}
          variant="unstyled">
        </Button>
      </div>
      { (!isSentencesEmpty || !isRemarkEmpty) &&
        <div className={styles.informationWrapper}>
          <div className={styles.separator}/>
          { !isSentencesEmpty && <div className={styles.information}>
            <div className={styles.title}>{t('sentences')}</div>
            { currentCard?.sentences.map(item =>
               <div className={styles.content} key={item}>{item}</div>)
            }
          </div> }
          { !isRemarkEmpty && <div className={styles.information}>
            <div className={styles.title}>{t('remarks')}</div>
            <pre className={styles.content}>{currentCard?.remark}</pre>
          </div> }
        </div>
      }
    </div>
  </div>;
};

export default DeckCardSwiper;
