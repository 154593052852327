import { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Button, NumberInput, NumberInputField,
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TopBar from '../../components/TopBar';
import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import useInput from '../../hooks/useInput';
import { Deck } from '../../types';
import styles from './PlayDeckSelect.module.scss';
import analytics, { gaTrack } from '../../services/analytics';
import variables from '../../variables.module.scss';
import getDeckName from '../../utils/getDeckName';

const okButtonStyle = {
  width: '266px',
  height: '47px',
  margin: '0 auto',
};

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#2D65C2',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
  background: 'white',
  marginBottom: '70px',
};

const inputStyle = {
  borderColor: '#97ACD9',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  padding: '20px',
  height: '50px',
  borderRadius: '4px',
  background: 'white',
  color: '#2D65C2',
};

const PlayDeckSelect = () => {
  const context = useContext(AppContext);
  const [limit, onLimitChange] = useInput(20, (value: any) => value);
  const [decks, setDecks] = useState<Deck[]>([]);
  const [deckId, onDeckIdChange, setDeckId] = useInput('');
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation('play_deck_select');
  const history = useHistory();

  const getDecks = useCallback(
    async () => {
      const response = await axios.get('/decks', { params: { type: 'all' } });
      setDecks(response.data.data.decks);
      setDeckId(response.data.data.decks[0].id);
    },
    [setDeckId, setDecks],
  );

  useEffect(
    () => {
      analytics.page();
    },
    [],
  );

  useEffect(
    () => {
      if (!initialized) {
        getDecks();
        context.setCurrentTab('play');
        setInitialized(true);
      }
    },
    [context, initialized, getDecks, setInitialized],
  );

  const onConfirm = useCallback(
    () => {
      gaTrack('Play', 'Game mode manual');
      history.push(`/play/${deckId}?limit=${limit}`);
    },
    [history, deckId, limit],
  );

  useEffect(
    () => {
      const eventListener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onConfirm();
        }
      };

      window.addEventListener('keydown', eventListener);
      return () => {
        window.removeEventListener('keydown', eventListener);
      };
    },
    [onConfirm],
  );

  return (
    <div className={styles.playIndexOuter}>
      <TopBar />
        <div className={styles.playIndex}>
          <div className={styles.autoModeWrapper}>
            <div className={styles.whichDeck}>{t('which_deck')}</div>
            <Select
              value={deckId}
              onChange={onDeckIdChange}
              textAlign="center"
              {...selectStyle}
            >
              {decks.map(deck => (
                <option key={deck.id} value={deck.id}>
                  {getDeckName(deck, context.currentUser)}
                </option>
              ))}
            </Select>
          </div>
          <div className={styles.autoModeWrapper}>
            <div className={styles.whichDeck}>{t('how_many_cards')}</div>
            <NumberInput
              type="number"
              step={5}
              min={5}
              value={limit}
              onChange={onLimitChange}
              marginBottom="70px"
            >
              <NumberInputField
                {...inputStyle}
                textAlign="center"
              />
              <NumberInputStepper>
                <NumberIncrementStepper color="#2D65C2" />
                <NumberDecrementStepper color="#2D65C2" />
              </NumberInputStepper>
            </NumberInput>
          </div>

          <Button
            onClick={onConfirm}
            textTransform="uppercase"
            display="block"
            {...okButtonStyle}
          >
            {t('get_started')}
          </Button>
        </div>
    </div>
  );
};

export default PlayDeckSelect;
