import { useMemo, useState, useEffect } from 'react';

import axios from '../../utils/axios';
import { gaTrack } from '../../services/analytics';

const useStockImages = (
  word: string, currentSearch: string,
  imageUrl: string | undefined, setImageUrl: (url: string | undefined) => void,
  setLoading: (value: boolean) => void) => {

  const [photoCursor, setPhotoCursor] = useState(0);
  const [isFetchingImage, setIsFetchingImage] = useState(false);
  const [imageUrlListEnded, setImageUrlListEnded] = useState(false);
  const [imageUrlList, setImageUrlList] = useState<string[]>([]);

  const fetchStockImages = useMemo(
    () => async (keyword: string, clear: boolean = false) => {
      if (!keyword || isFetchingImage || imageUrlListEnded) {
        return;
      }
      setIsFetchingImage(true);

      const urlList = clear ? [] : imageUrlList;
      const resImgUrls = await axios.get(
        '/imageSearch/getUrl',
        { params : { word: keyword, offset: urlList.length, size: 50 } });

      if (resImgUrls.data.data.urls.length === 0) {
        setImageUrlListEnded(true);
      }

      setImageUrlList([...urlList, ...resImgUrls.data.data.urls]);
      setIsFetchingImage(false);
    },
    [imageUrlList, isFetchingImage, imageUrlListEnded],
  );

  const onNextPhotoClick = async () => {
    gaTrack('Create Card', 'Search next photo');
    setPhotoCursor(offset => (offset + 1));

    if (!imageUrl || photoCursor > imageUrlList.length - 10) {
      let search = currentSearch;
      if (!search) {
        const resDict: any = await axios.get('/dict', { params: { word } });
        search = resDict.data.data.en || word;
      }
      await fetchStockImages(search);
    }
  };

  const resetStockImageUrlList = () => {
    setImageUrlList([]);
    setImageUrlListEnded(false);
  };

  useEffect(
    () => {
      setPhotoCursor(0);
      resetStockImageUrlList();
    },
    [setPhotoCursor, setImageUrlList, currentSearch],
  );

  useEffect(
    () => {
      if (imageUrlList.length > 0 && photoCursor < imageUrlList.length) {
        setImageUrl(imageUrlList[photoCursor]);
      }
    },
    [photoCursor, imageUrlList, setImageUrl],
  );

  const onImageCheckboxClicked = async () => {
    if (imageUrl) {
      setImageUrl(undefined);
    } else {
      setPhotoCursor(0);
      setLoading(true);

      if (!imageUrl || photoCursor > imageUrlList.length - 10) {
        let search = currentSearch;
        if (!search) {
          const resDict: any = await axios.get('/dict', { params: { word } });
          search = resDict.data.data.en || word;
        }
        await fetchStockImages(search);
      }
      setLoading(false);
    }
  };

  return { fetchStockImages, onNextPhotoClick,
    onImageCheckboxClicked, resetStockImageUrlList };
};

export default useStockImages;
