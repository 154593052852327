import { ChangeEvent, ReactElement } from 'react';
import { Image, IconButton, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import getFileUrl from '../../utils/getFileUrl';
import avatarFallbackImage from '../../images/avatar-fallback.svg';
import cameraIcon from '../../images/camera.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import AccountDeckList from './AccountDeckList';
import crownOnAvatarImage from '../../images/crown-on-avatar.svg';
import removeFriendImage from '../../images/recommended-remove-friend.svg';
import addFriendImage from '../../images/recommended-add-friend.svg';
import variables from  '../../variables.module.scss';

import websiteImage from '../../images/website.svg';
import crownImage from '../../images/crown.svg';
import styles from './RecommendedProfile.module.scss';
import CountryFlag from '../../components/CountryFlag';
import { Deck, File as FileType, RecommendedUser, FriendStatus } from '../../types';

const avatarStyle = {
  color: '#67D29E',
  border: '2px',
  boxSize: '110px',
};

const avatarFallbackStyle = {
  padding: '30px',
  color: '#67D29E',
  border: '2px',
  boxSize: '110px',
  background: '#ffffff',
};

const avatarCamStyle = {
  bottom: '0px',
  right: '-10px',
  color: '#F79411',
  borderColor: '#F79411',
  width: '35px',
  height: '35px',
  background: 'white',
  boxShadow: '2px 2px 4px #808080',
  zIndex: '1',
};

const buttonStyle = {
  _hover: { filter: 'brightness(90%)' },
  textTransform: 'none',
  height: '47px',
  fontFamily: variables.normalFontFamily,
  paddingLeft: '21px',
  paddingRight: '21px',
  boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
  flexShrink: '0',
  marginLeft: '10px',
};

interface RecommendedProfileInterface {
  isSelf: boolean;
  getUser: () => void;
  userId: string;
  subMenu: ReactElement;
  friendStatus: FriendStatus;
  fullName: string;
  spin: boolean;
  locked: boolean;
  decks: Deck[];
  friendsCount: number;
  onUnfriendClicked: () => void;
  inputFile: React.Ref<HTMLInputElement>;
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onImageClick: () => void;
  avatarFileObject: FileType | null;
  onDeckUpdated: () => void;
  onAddFriendClicked: () => void;
  systemLanguage: string | undefined;
  recommendedUser: RecommendedUser;
}

const RecommendedProfile: React.FC<RecommendedProfileInterface> = ({
  isSelf, subMenu, fullName, spin, recommendedUser,
  locked, decks, onImageClick, friendsCount,
  inputFile, onImageChange,
  avatarFileObject, onDeckUpdated, systemLanguage, onUnfriendClicked,
  friendStatus, onAddFriendClicked,
}) => {

  const { t } = useTranslation('account');

  return (
    <div className={classnames({
      [styles.zh]: systemLanguage === 'zh',
      [styles.self]: isSelf,
    })}>
      <div
        className={styles.avatarSection}
      >
        {subMenu}
        <div className={styles.avatarWrapper}>
          <div className={styles.middle}>
            <div className={styles.avatarBox}>
              <input
                onChange={onImageChange}
                type="file"
                accept="image/*"
                ref={inputFile}
                className={styles.imageInput}
              />
              { isSelf && (
                <IconButton
                  {...avatarCamStyle}
                  onClick={onImageClick}
                  pos="absolute"
                  aria-label="cover"
                  variant="outline"
                  isRound={true}
                  icon={<img alt="cover" width="70%" src={cameraIcon} />}
                />
              )}
              <Image
                src={crownOnAvatarImage}
                position="absolute"
                top="-5px"
                left="-25px"
                width="40px"
              />
              <Image
                {...avatarStyle}
                src={getFileUrl(avatarFileObject)}
                objectFit="cover"
                borderRadius="full"
                alt={fullName}
                fallback={
                  <Image
                    {...avatarFallbackStyle}
                    src={avatarFallbackImage}
                    borderRadius="full"
                    alt={fullName} />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.userName}>{fullName}</div>
      <div className={styles.bio}>
        <div className={styles.recommendedCreator}>
          <Image src={crownImage} />
          <h4>{t('recommended_creator')}</h4>
        </div>
        {recommendedUser.introduction && (
          <pre className={styles.introduction}>{recommendedUser.introduction}</pre>
        )}
        {recommendedUser.website && (
          <a className={styles.website} href={recommendedUser.website} target="_blank" rel="noreferrer">
            <img src={websiteImage} alt="website" />
          </a>
        )}
        <div className={styles.friendsWrapper}>
          <h4 className={styles.friendsTitle}>{t('friends')}</h4>
          <h4 className={styles.friendsCount}>{friendsCount}</h4>
        </div>
        { !isSelf && (
        <div className={styles.buttons}>
          <div className={styles.userLanguages}>
            {recommendedUser.languages.map(lang => (
              <div key={lang}>
                <CountryFlag
                  countryCode={lang}
                  style={{
                    width: '36px',
                    height: '27px',
                    fontSize: '12px',
                    marginRight: '6px',
                    marginBottom: '4px',
                  }}
                />
              </div>
            ))}
          </div>
          {friendStatus === 'isFriend' ? (
            <Button
              aria-label="unfriend"
              border="1px solid #3CC884"
              onClick={onUnfriendClicked}
              color="#3CC884"
              background="white"
              {...buttonStyle as any}
            >
              <Image src={removeFriendImage} marginRight="15px" />
              {t('friend')}
            </Button>
          ) : (
            <Button
              aria-label="add friend"
              onClick={onAddFriendClicked}
              background="#F79411"
              color="white"
              {...buttonStyle as any}
            >
              <Image src={addFriendImage} marginRight="15px" />
              {t('add_as_friend')}
            </Button>
          )}
        </div>
        )}
        <div>
          <h4 className={styles.title}>{t('expert_in')}</h4>
          <div className={styles.keywords}>
            {recommendedUser.keywords.map(keyword =>
            <div className={styles.keyword} key={keyword}>{keyword}</div>)}
          </div>
        </div>
      </div>
      <div className={styles.deckListWrapper}>
        <LoadingSpinner spin={spin}>
          { decks.length > 0 && (
            <div className={styles.deckWidthWrapper}>
              <div className={styles.title}>
                { t('account_decks', { number: decks.length }) }
                { locked && <span className={styles.locked}>{t('will_be_unlocked')}</span>}
              </div>
              <AccountDeckList
                locked={locked}
                decks={decks}
                onDeckUpdated={onDeckUpdated}
              />
            </div>
          )}
        </LoadingSpinner>
      </div>
    </div>
  );
};

export default RecommendedProfile;
