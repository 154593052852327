import { useState, useEffect, useContext } from 'react';

import { Card } from '../../types';
import styles from './MeaningToWordMC.module.scss';
import { useTranslation } from 'react-i18next';
import generateRandomWords from '../../utils/generateRandomWords';
import { AppContext } from '../../AppContext';
import getMeaning from '../../utils/getMeaning';
import axios from '../../utils/axios';
import LoadingSpinner from '../../components/LoadingSpinner';

import MCButton from './MCButton';

interface ShuffledWordInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const MeaningToWordMC: React.FC<ShuffledWordInterface> = ({ card, setResult, isShowingAnswer }) => {

  const context = useContext(AppContext);

  const [answer, setAnswer] = useState('');
  const [choices, setChoices] = useState<string[]>([]);
  const { t } = useTranslation('play');
  const currentUser = context.currentUser;
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(
    () => {
      const getChoices = async () => {
        setSpin(true);
        let fetchedChoices = [];
        try {
          const response = await axios.get('/revision/choices', { params: { cardId: card.id } });
          fetchedChoices = response.data.data.cards.map((c: any) => c.title);
          fetchedChoices = [...(new Set(fetchedChoices.filter((c: any) => c)).values())];
        } catch {
          // do nothing
        }

        setChoices(
          generateRandomWords(
            card.deck.language, 3 - fetchedChoices.length, /[A-Z]/.test(card.title[0]))
            .concat([card.title]).concat(fetchedChoices).sort(() => 0.5 - Math.random()),
        );
        setSpin(false);
      };

      getChoices();
    },
    [card, setChoices, currentUser?.systemLanguage],
  );

  useEffect(
    () => {
      setResult(answer === card.title);
    },
    [answer, setResult, card],
  );

  useEffect(
    () => {
      setAnswer('');
    },
    [setAnswer, card],
  );

  const onButtonClick = (value: string) => {
    setAnswer(value);
    setResult(value === card.title);
  };

  return <div className={styles.meaningToWordMC}>
    <div className={styles.questionBox}>
      {getMeaning(card.meanings, currentUser?.systemLanguage)}
    </div>
    <div className={styles.instruction}>{t('choose_the_correct_word')}</div>
    <LoadingSpinner spin={spin}>
      <div className={styles.choices}>
        {choices.map(word => (
          <MCButton isShowingAnswer={isShowingAnswer}
            isAnswer={card.title === word}
            value={word}
            key={word}
            checked={answer === word}
            onClick={onButtonClick} />
        ))}
      </div>
    </LoadingSpinner>
  </div>;
};

export default MeaningToWordMC;
