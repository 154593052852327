import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import variables from './variables.module.scss';

const theme = extendTheme({
  fonts: {
    body: variables.normalFontFamily,
    heading: variables.titleFontFamily,
    mono: variables.normalFontFamily,
  },
  breakpoints: createBreakpoints(
    {
      sm: variables.smMaxWidth,
      md: '10000px',
      lg: '10000px',
      xl: '10000px',
    },
  ),
  colors: {
    switchBlue: {
      500: '#2D65C2',
      200: '#2D65C2',
    },
  },
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          background: '#2D65C2',
        },
      },
    },
    CloseButton: {
      baseStyle: {
        _hover: {
          bg: 'none',
        },
        _active: {
          bg: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          bg: 'white',
          fontFamily: variables.normalFontFamily,
        },
      },
    },
    Button: {
      sizes: {
        md: {
          fontSize: '18px',
        },
      },
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
        _hover: {
          filter: 'brightness(90%)',
        },
        background: 'none',
      },
      defaultProps: {
        size: 'md',
        variant: 'blue',
      },
      variants: {
        blue: {
          fontFamily: variables.titleFontFamily,
          background: '#2D65C2',
          borderRadius: '31px',
          color: 'white',
          textTransform: 'uppercase',
          _hover: {
            filter: 'brightness(90%)',
            _disabled: { background: '#2D65C290' },
          },
        },
        red: {
          fontFamily: variables.titleFontFamily,
          borderRadius: '31px',
          background: 'white',
          border: '1px solid #F94141',
          textTransform: 'uppercase',
          color: '#F94141',
          _hover: {
            filter: 'brightness(90%)',
            _disabled: { background: '#F9414190' },
          },
        },
      },
    },
  },
});

export default theme;
