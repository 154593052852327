import { useRef, useEffect } from 'react';
import { getDataTypeFromUrl } from '../../utils/textUtils';

interface VideoProps {
  imageUrl: string | undefined;
  className?: string;
}

const Video: React.FC<VideoProps> = ({ imageUrl, children, className }) => {
  const isVideo = imageUrl && getDataTypeFromUrl(imageUrl) === 'video';
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(
    () => {
      if (videoRef.current !== null) {
        videoRef.current.volume = 0;
      }
    },
    [videoRef],
  );

  if (isVideo) {
    return <video
      style={{ objectFit: 'cover' }}
      className={className}
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
    >
      <source src={imageUrl} />
    </video>;
  }

  return <>{children}</>;
};

export default Video;
