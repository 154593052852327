import { useRef, ChangeEvent, useState,
  useEffect, Dispatch, SetStateAction } from 'react';
import { IconButton, Progress } from '@chakra-ui/react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { AiTwotoneFolderOpen } from 'react-icons/ai';
import { IoIosMic } from 'react-icons/io';

import styles from './AudioInput.module.scss';
import axios from '../../utils/axios';
import { File as FileType } from '../../types';
import { start as startRecording, stop as stopRecording } from '../../utils/recorder';
import getFileUrl from '../../utils/getFileUrl';
import { gaTrack } from '../../services/analytics';

interface AudioInputInterface {
  audio: FileType | undefined;
  setAudio: Dispatch<SetStateAction<FileType | undefined>>;
}

const AudioInput: React.FC<AudioInputInterface> = ({ audio, setAudio }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState(0);
  const [recording, setRecording] = useState(false);
  const [playing, setPlaying] = useState(false);

  const onStopRecording = async () => {
    const file = stopRecording();
    setRecording(false);
    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      const response = await axios.post('/files', formData);
      setAudio(response.data.data.file);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
      }
    }
  };

  const onRecorderClicked = () => {
    gaTrack('Create Card', 'Record pronunciation');
    if (!recording) {
      setRecording(true);
      startRecording();
    } else {
      onStopRecording();
    }
  };

  const onPlayClicked = () => {
    gaTrack('Create Card', 'Playback pronunciation');
    if (audioRef.current && audio) {
      if (!playing) {
        setPlaying(true);
        audioRef.current.play();
      } else {
        setPlaying(false);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  const onUploadClicked = () => {
    gaTrack('Create Card', 'Upload pronunciation');
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  useEffect(
    () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
      }
    },
    [audioRef, audio],
  );

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const file = event.target.files[0];

      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        const response = await axios.post('/files', formData);
        setAudio(response.data.data.file);
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current) {
          const p = audioRef.current.currentTime / audioRef.current.duration * 100;
          setProgress(p);
          if (p === 100) {
            setPlaying(false);
          }
        }
      });
    }
  });

  return (
    <div className={styles.inputGroup}>
      <div className={styles.inputTitle} />
      <div className={styles.audioControl}>
        <IconButton
          onClick={onRecorderClicked}
          isDisabled={playing}
          aria-label="record"
          variant="outline"
          isRound={true}
          icon={recording ? <FaPause/> : <IoIosMic size={20} />}
          color="#F79411"
          borderColor="#F79411"
        />
        <IconButton
          onClick={onUploadClicked}
          isDisabled={recording || playing}
          aria-label="play"
          variant="outline"
          isRound={true}
          icon={<AiTwotoneFolderOpen size={16} />}
          color="#F79411"
          borderColor="#F79411"
          marginLeft="8px"
        />
        <div className={styles.audioControlInner}>
          <IconButton
            onClick={onPlayClicked}
            isDisabled={recording || !audio}
            aria-label="play"
            variant="outline"
            isRound={true}
            icon={playing ? <FaPause size={12} /> : <FaPlay size={12} />}
            color="#2D65C2"
            borderColor="#2D65C2"
            width="28px"
            height="28px"
            minWidth="auto"
          />
          <Progress
            value={progress}
            height="2px"
            borderRadius="1px"
            className={styles.progress}
          />
        </div>
      </div>

      <input
        onChange={onFileChange}
        type="file"
        accept="audio/*"
        ref={inputRef}
        style={{ display: 'none' }}
      />

      {audio && (
        <audio ref={audioRef}>
          <source src={getFileUrl(audio)} type="audio/mpeg" />
        </audio>
      )}
    </div>
  );
};

export default AudioInput;
