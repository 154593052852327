import correctSfx from './correct.mp3';
import createSfx from './create.mp3';
import welldoneSfx from './welldone.mp3';
import goodSfx from './good.mp3';
import flipSfx from './flip.mp3';
import wrongSfx from './wrong.mp3';

const createAudio = (file: string, volume: number) => {
  return new Promise((resolve) => {
    const audioElement = new Audio(file);
    audioElement.load();
    audioElement.addEventListener('loadeddata', () => {
      audioElement.volume = volume;
      resolve(audioElement);
    });
  });
};

export const createPlay = (file: string, volume: number) => {
  const audio = createAudio(file, volume);

  return {
    play: async () => ((await audio) as any).play(),
  };
};

export const create = createPlay(createSfx, 0.2);
export const flip = createPlay(flipSfx, 0.05);
export const correct = createPlay(correctSfx, 0.1);
export const wrong = createPlay(wrongSfx, 0.1);
export const welldone = createPlay(welldoneSfx, 0.2);
export const good = createPlay(goodSfx, 0.2);
