import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import styles from './OcrResultPicker.module.scss';
import { OcrWordResult, File as FileType } from '../../types';
import getFileUrl from '../../utils/getFileUrl';

interface OcrResultPickerInterface {
  image: FileType | undefined;
  words: OcrWordResult[];
  onSelect: (text: string) => void;
}

const buttonStyle = {
  width: '100%',
  height: '3rem',
  letterSpacing: '0.01em',
  margin: '0 10px 10px',
  fontWeight: '500',
  _focus: { outline: 0 },
};

const OcrResultPicker: React.FC<OcrResultPickerInterface> = ({ image, words, onSelect }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageDataURL, setImageDataURL] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<Set<number>>(new Set());

  useEffect(
    () => {
      if (image && words.length > 0 && canvasRef.current) {
        const img = new Image();
        img.crossOrigin = 'anonymous';

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d')!;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        img.onload = () => {
          ctx.canvas.width = img.naturalWidth;
          ctx.canvas.height = img.naturalHeight;
          ctx.drawImage(img, 0, 0);

          ctx.fillStyle = '#00000050';
          ctx.fillRect(0, 0, img.naturalWidth, img.naturalHeight);
          ctx.lineWidth = 4;

          for (const [i, word] of words.entries()) {
            if (selectedIndex.has(i)) {
              ctx.strokeStyle = '#0FD4CB';
              ctx.strokeRect(word.x1, word.y1, word.width, word.height);
            } else {
              ctx.strokeStyle = '#ffffff';
              ctx.strokeRect(word.x1, word.y1, word.width, word.height);
            }
          }
          setImageDataURL(canvas.toDataURL());
        };

        img.src = getFileUrl(image)!;
      }
    },
    [image, words, selectedIndex],
  );

  useEffect(
    () => {
      setSelectedIndex(new Set());
    },
    [words],
  );

  const onClose = () => {
    onSelect('');
  };

  const onImageClick = useMemo(
    () => (e: React.MouseEvent<HTMLImageElement>) => {
      const img = e.currentTarget;
      const bounds = img.getBoundingClientRect();
      const left = bounds.left;
      const top = bounds.top;
      const x = e.pageX - left;
      const y = e.pageY - top;
      const cw = img.clientWidth;
      const ch = img.clientHeight;
      const iw = img.naturalWidth;
      const ih = img.naturalHeight;
      const px = x / cw * iw;
      const py = y / ch * ih;

      for (const [i, word] of words.entries()) {
        if (word.x1 <= px && word.x2 >= px && word.y1 <= py && word.y2 >= py) {
          if (selectedIndex.has(i)) {
            selectedIndex.delete(i);
          } else {
            selectedIndex.add(i);
          }
          setSelectedIndex(new Set(selectedIndex));
        }
      }
    },
    [words, selectedIndex],
  );

  const onConfirm = () => {
    onSelect([...selectedIndex.values()].map((i: number) => words[i].text).join(' '));
  };

  const { t } = useTranslation('create_card');

  return <>
    <Modal isOpen={!!(image && words.length > 0)} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className={styles.content}>
              <img src={imageDataURL} alt="img" onClick={onImageClick}/>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onConfirm} {...buttonStyle}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <canvas ref={canvasRef} style={{ display: 'none' }} />
  </>;
};

export default OcrResultPicker;
