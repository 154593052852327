import lamejs from 'lamejs';
import Recorder from 'js-audio-recorder';

const config = {
  sampleBits: 16,
  sampleRate: 44100,
  numChannels: 1,
};

let recording = false;

const recorder = new Recorder(config);

export const start = () => {
  if (!recording) {
    recording = true;
    recorder.start();
  }
};

export const stop = (fileType: 'mp3' | 'wav' = 'mp3'): File | undefined => {
  if (recording) {
    recording = false;
    if (fileType === 'wav') {
      const blobWav = recorder.getWAVBlob();
      recorder.destroy();
      return new File([blobWav], 'audio.wav');
    }

    // else the default type is 'mp3'
    const blobMp3 = getMp3();
    recorder.destroy();
    return new File([blobMp3], 'audio.mp3');
  }
};

export const getMp3 = () => {
  const mp3enc = new lamejs.Mp3Encoder(config.numChannels, config.sampleRate, 128);
  const result = (recorder as any).getChannelData();

  const buffer = [];

  const leftData = result.left &&
    new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
  const rightData = result.right &&
    new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
  const remaining = leftData.length + (rightData ? rightData.length : 0);

  const maxSamples = 1152;
  for (let i = 0; i < remaining; i += maxSamples) {
    const left = leftData.subarray(i, i + maxSamples);
    let right = null;
    let mp3buf = null;

    if (config.numChannels === 2) {
      right = rightData.subarray(i, i + maxSamples);
      mp3buf = mp3enc.encodeBuffer(left, right);
    } else {
      mp3buf = mp3enc.encodeBuffer(left);
    }

    if (mp3buf.length > 0) {
      buffer.push(mp3buf);
    }
  }

  const enc = mp3enc.flush();

  if (enc.length > 0) {
    buffer.push(enc);
  }

  return new Blob(buffer, { type: 'audio/mp3' });
};
