import { useState, useEffect } from 'react';
import { IconButton, Image } from '@chakra-ui/react';
import shuffle from 'knuth-shuffle-seeded';
import classnames from 'classnames';

import getFileUrl from '../../utils/getFileUrl';
import { Card } from '../../types';
import styles from './WordToImageMC.module.scss';
import { useTranslation } from 'react-i18next';
import playAudioIcon from '../../images/play-audio.svg';
import generateRandomWords from '../../utils/generateRandomWords';
import axios from '../../utils/axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import Video from '../../components/Video';

import tickIcon from '../../images/tick.svg';
import crossIcon from '../../images/cross.svg';

interface ShuffledWordInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const WordToImageMC: React.FC<ShuffledWordInterface> = ({ card, setResult, isShowingAnswer }) => {
  const [answer, setAnswer] = useState('');
  const [choices, setChoices] = useState<string[]>([]);
  const [playing, setPlaying] = useState(false);
  const [spin, setSpin] = useState<boolean>(true);
  const { t } = useTranslation('play');

  useEffect(
    () => {
      if (card) {
        setSpin(true);
        const getImages = async () => {
          const fetchChoices = async () => {
            try {
              const response = await axios.get(
                '/revision/choices', { params: { cardId: card.id } });
              let fetchedChoices = response.data.data.cards.map((c: any) => c.imageFile);
              fetchedChoices = fetchedChoices.map((c: any) => getFileUrl(c));
              return fetchedChoices;
            } catch (e) {
              return [];
            }
          };

          const imageChoices = await fetchChoices();

          const promises = [...Array(3 - imageChoices.length)].map(async () => {
            for (const timeout of [1000, 3000, 5000]) {
              const word = generateRandomWords('en', 1, false)[0];
              try {
                const response = await axios.get(
                  '/imageSearch/getUrl',
                  { timeout, params: { word } },
                );
                const url = response.data.data.urls[0];
                if (url) {
                  return response.data.data.urls[0];
                }
              } catch (e) {
                // empty
              }
            }
          });

          const images = (await Promise.all<string>(promises))
            .concat(imageChoices).filter(im => im);

          setSpin(false);
          images.push(getFileUrl(card.imageFile)!);
          shuffle(images); setChoices(images);
        };
        getImages();
      }
    },
    [card, setChoices],
  );

  useEffect(
    () => {
      setAnswer('');
      setChoices([]);
    },
    [setAnswer, card],
  );

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const onButtonClick = (value: string) => {
    setAnswer(value);
    setResult(value === getFileUrl(card.imageFile));
  };

  return <div className={styles.wordToImageMC}>
    <div className={styles.questionBox}>
      {card.title}
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        height="40px"
        width="40px"
        onClick={onPlayClicked}
        disabled={!card.audioFile || playing}
        icon={<img
          alt="play"
          src={playAudioIcon}
        />}
        className={styles.audio}
      />
    </div>

    <div className={styles.instruction}>{t('what_does_it_mean')}</div>
    <LoadingSpinner spin={spin}>
      <div className={styles.choices}>
        {choices.map(image => (
          <div className={classnames(styles.imageWrapper, {
            [styles.selected]: !isShowingAnswer && image === answer,
            [styles.correct]: isShowingAnswer && image === getFileUrl(card.imageFile),
            [styles.wrong]: isShowingAnswer && image === answer,
            [styles.isShowingAnswer]: isShowingAnswer,
          })} key={image} onClick={() => onButtonClick(image)}>
            <Video imageUrl={image} className={styles.image}>
              <Image
                src={image}
                objectFit="cover"
                className={styles.image}
              />
            </Video>
            { isShowingAnswer && (image === getFileUrl(card.imageFile) ?
              <img className={styles.iconStyle} src={tickIcon} alt="tick" /> :
              (image === answer) &&
              <img className={styles.iconStyle} src={crossIcon} alt="cross" />)}
          </div>
        ))}
      </div>
    </LoadingSpinner>
  </div>;
};

export default WordToImageMC;
