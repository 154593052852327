import { useState, useContext, useCallback, FormEvent } from 'react';
import {
  Input, ModalContent, ModalCloseButton, ModalBody, Button,
  Modal, ModalOverlay } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';
import { AppContext } from '../../AppContext';
import useInput from '../../hooks/useInput';
import useToast from '../../hooks/useToast';
import styles from './DictModal.module.scss';
import { Language } from '../../types';

export type Meanings = Record<Language, string>;

interface DictModalInterface {
  isOpen: boolean;
  onClose: (text: string, meanings?: Meanings) => void;
}

const DictModal: React.FC<DictModalInterface> = ({ isOpen, onClose }) => {
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [text, onTextChange] = useInput('');
  const { t } = useTranslation('create_card');
  const { t: langT } = useTranslation('language');
  const toast = useToast();

  const onSearch = useCallback(
    async () => {
      if (isOpen && text) {
        setLoading(true);

        try {
          const response = await axios.get(
            '/dict/reverse',
            {
              params: { word: text },
            },
          );

          const result = response.data.data.result;
          if (result) {
            onClose(result, response.data.data.meanings);
          } else {
            toast({
              title: t('no_words'),
              description: t('try_again'),
              status: 'info',
            });
          }
        } catch {
          toast({
            title: t('error'),
            description: t('try_again'),
            status: 'error',
          });
        }

        setLoading(false);
      }
    },
    [t, onClose, text, toast, isOpen],
  );

  const systemLanguage = langT(context.currentUser?.systemLanguage || '');
  const targetLanguage = langT(context.currentUser?.targetLanguage || '');

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };

  return <Modal isOpen={isOpen} onClose={() => onClose('', undefined)} size="md" isCentered>
    <ModalOverlay />
    <ModalContent margin="20px">
      <ModalCloseButton />
      <ModalBody>
        <form className={styles.content} onSubmit={onFormSubmit}>
          <div className={styles.title}>
            {t('translate_to', { from: systemLanguage, to: targetLanguage })}
          </div>
          <Input
            variant="flushed"
            autoFocus
            onChange={onTextChange}
            value={text}
            placeholder={t('dict_placeholder', { lang: systemLanguage })}
            textAlign="center"
            fontSize="24px"
            _placeholder={{
              fontWeight: 200,
              letterSpacing: '2px',
              fontSize: '18px',
              textTransform: 'uppercase',
            }}
            height="50px"
            fontWeight={600}
            borderColor="#97ACD9"
            maxLength={50}
          />
          <div className={styles.buttonWrapper}>
            <Button
              type="submit"
              isLoading={loading}
              fontWeight="300"
              width="266px"
              height="47px"
              isDisabled={!text}
            >
              {t('search')}
            </Button>
          </div>
        </form>
      </ModalBody>
    </ModalContent>
  </Modal>;
};

export default DictModal;
