import { useState, useMemo, useCallback } from 'react';

import { compressImageToB64 } from '../../utils/compressImage';
import { File as FileRecord } from '../../types';
import getFileUrl from '../../utils/getFileUrl';
import { SUPPORTED_SPECIAL_IMAGE_TYPES } from '../../constants';

interface ImageData {
  id?: string;
  url?: string;
  fileType?: 'image' | 'video';
  fileExt?: string;
  fileSize?: number;
  canvasFile?: File;
}

const useImageData = () => {
  const [imageData, setImageData] = useState<ImageData>({});
  const setImageUrl = useMemo(
    () => {
      return (url: string | undefined) => setImageData({ url });
    },
    [setImageData],
  );

  const getImageBody = useCallback(
    async () => {
      if (imageData.id) {
        return {
          imageId: imageData.id,
        };
      }

      if (imageData.fileExt &&
        SUPPORTED_SPECIAL_IMAGE_TYPES.indexOf(imageData.fileExt) >= 0) {
        return {
          imageExt: imageData.fileExt,
          imageData: imageData.url,
        };
      }

      if (imageData.canvasFile) {
        return {
          imageData: await compressImageToB64(imageData.canvasFile),
          imageExt: '.jpg',
        };
      }
    },
    [imageData],
  );

  const setImageDataByFileRecord = useMemo(
    () => (f: FileRecord | undefined) => {
      setImageData({
        id: f?.id,
        url: getFileUrl(f),
      });
    },
    [setImageData],
  );

  const setImageDataByCanvasFile = (canvasFile: File) => {
    setImageData(d => ({ ...d, canvasFile }));
  };

  const setImageDataByDataUrl = (
    url: string, fileType: 'image' | 'video', fileExt: string, fileSize: number) => {
    setImageData({ url, fileType, fileExt, fileSize });
  };

  return {
    setImageUrl, getImageBody, setImageDataByFileRecord,
    setImageDataByCanvasFile, setImageDataByDataUrl, imageUrl: imageData.url,
  };

};

export default useImageData;
