import { useRef } from 'react';
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader,
  AlertDialogBody, AlertDialogFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';
import useToast from '../../hooks/useToast';

interface DeleteDialogInterface {
  isOpen: boolean;
  onClose: (deleted: boolean) => void;
  cardId: string;
}

const DeleteDialog: React.FC<DeleteDialogInterface> = ({ isOpen, onClose, cardId }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('card_form');
  const toast = useToast();

  const onDelete = async () => {
    if (cardId !== 'new') {
      await axios.delete(`/cards/${cardId}`);

      toast({
        title: t('card_deleted'),
        description: t('card_deleted_desc'),
        status: 'success',
      });
      onClose(true);

    } else {
      onClose(true);
    }
  };

  return <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={() => onClose(false)}
    size="xl"
    isCentered
  >
    <AlertDialogOverlay>
      <AlertDialogContent margin="20px">
        <AlertDialogHeader fontSize="xl" fontWeight="bold">
          {t('delete_title')}
        </AlertDialogHeader>
        <AlertDialogBody fontSize="lg">
          {t('are_you_sure')}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} fontWeight="200" onClick={() => onClose(false)}>
            {t('cancel')}
          </Button>
          <Button variant="red" fontWeight="200" onClick={onDelete} ml={3}>
            {t('delete')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>;
};

export default DeleteDialog;
