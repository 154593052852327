import { useState, useEffect, ChangeEvent, useMemo, useCallback, useContext, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  IconButton, InputRightElement,
  Input, InputGroup, InputLeftElement, Button, Switch,
} from '@chakra-ui/react';
import classnames from 'classnames';

import LoadingSpinner from '../../components/LoadingSpinner';
import { SystemLanguage, SystemLanguageList, Language, GameMode, GameModes } from '../../types';
import axios from '../../utils/axios';
import TopBar from '../../components/TopBar';
import styles from './DeckForm.module.scss';
import removeButtonIcon from '../../images/remove-button.svg';
import addButtonIcon from '../../images/add-button.svg';
import CountryFlag from '../../components/CountryFlag';
import variables from '../../variables.module.scss';
import SelectLanguageModal from '../../components/SelectLanguageModal';
import useToast from '../../hooks/useToast';
import Checkbox from '../../components/Checkbox';
import { AppContext } from '../../AppContext';
import { HOME_SECTION_TO_NAME_MAPPING } from '../../constants';

const inputStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 500,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
};

const inputGroupStyle = {
  marginBottom: '24px',
  width: '543px',
  maxWidth: 'calc(100% - 32px)',
};

const saveButtonStyle = {
  width: '266px',
  height: '47px',
  marginTop: '50px',
};

interface GameModeRowInterface {
  name: GameMode;
  index: number;
  checked: boolean;
  onClick: (name: GameMode) => void;
}

const GameModeRow: React.FC<GameModeRowInterface> = ({ name, index, checked, onClick }) => {
  const { t } = useTranslation('game_mode');
  return <div className={styles.gameModeRow}>
    <p className={styles.gameModeName}>
      <span className={styles.index}>{`${index + 1}.`}</span>{t(name)}
    </p>
    <Checkbox
      onClick={() => onClick(name)}
      checked={checked}
    />
  </div>;
};

interface CategoryRowInterface {
  homeSection: number;
  checked: boolean;
  onClick: (homeSection: number) => void;
}

const CategoryRow: React.FC<CategoryRowInterface> = ({ homeSection, checked, onClick }) => {
  const { t } = useTranslation('home_section');
  return <div className={styles.gameModeRow}>
    <p className={styles.gameModeName}>
      <span className={styles.index}>{`${homeSection + 1}.`}</span>
      {t(HOME_SECTION_TO_NAME_MAPPING[homeSection])}
    </p>
    <Checkbox
      onClick={() => onClick(homeSection)}
      checked={checked}
    />
  </div>;
};

const DeckForm = () => {
  const { t } = useTranslation('deck_form');
  const [names, setNames] = useState<Record<SystemLanguage, string>>(
    {} as Record<SystemLanguage, string>);
  const [excludedGameModes, setExcludedGameModes] = useState<GameMode[]>([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [homeSection, setHomeSection] = useState<number | null>(null);
  const [deckId] = useQueryParam('id', StringParam);

  const [isSelectLanguageModalOpened, setIsSelectLanguageModalOpened] = useState(false);
  const toast = useToast();
  const context = useContext(AppContext);
  const currentUser = context.currentUser;

  useEffect(
    () => {
      const fetchDeck = async () => {
        const response = await axios.get(`/decks/${deckId}`);
        setNames(response.data.data.deck.names);
        setExcludedGameModes(response.data.data.deck.excludedGameModes);
        setIsPrivate(response.data.data.deck.isPrivate);
        setHomeSection(response.data.data.deck.homeSection);
        setLoading(false);
      };
      fetchDeck();
    },
    [deckId],
  );

  const onNameChange = (event: ChangeEvent<HTMLInputElement>, lang: SystemLanguage) => {
    setNames({ ...names!, [lang]: event.currentTarget.value });
  };

  const availableLanguages = useMemo<SystemLanguage[]>(
    () => {
      if (names) {
        const nameLangs: SystemLanguage[] = Object.keys(names) as SystemLanguage[];
        return SystemLanguageList.filter(l => nameLangs.indexOf(l) < 0);
      }
      return [];
    },
    [names],
  );

  const onAddNameClicked = () => {
    setIsSelectLanguageModalOpened(true);
  };

  const onSelectLanguageModalClose = () => {
    setIsSelectLanguageModalOpened(false);
  };

  const onSelectLanguageModalConfirm = (l: Language) => {
    setIsSelectLanguageModalOpened(false);
    setNames({ ...names!, [l]: '' });
  };

  const onRemoveNameClicked = (lang: SystemLanguage) => {
    delete names![lang];
    setNames({ ...names! });
  };

  const invalid = Object.values(names).findIndex(x => !x) >= 0;

  const onSave = useCallback(
    async () => {
      setSaving(true);

      try {
        await axios.put(
          `/decks/${deckId}`,
          { names, excludedGameModes, isPrivate, homeSection },
        );

        toast({
          title: t('deck_updated'),
          description: t('deck_updated_desc'),
          status: 'success',
        });

        setSaving(false);
        context.goBack();

      } catch {
        setSaving(false);
        toast({
          title: t('error'),
          description: t('try_again'),
          status: 'error',
        });
      }
    },
    [t, names, deckId, context, toast, excludedGameModes, isPrivate, homeSection],
  );

  const onGameModeClicked = (name: GameMode) => {
    let result = [...excludedGameModes];
    if (excludedGameModes.indexOf(name) < 0) {
      result.push(name);
    } else {
      result = result.filter(x => x !== name);
    }
    if (result.length < GameModes.length) {
      setExcludedGameModes(result);
    }
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  const onSwitchChange = () => {
    setIsPrivate(!isPrivate);
  };

  const onCategoryRowClicked = (k: number) => {
    if (homeSection === k) {
      setHomeSection(null);
    } else {
      setHomeSection(k);
    }
  };

  return <div className={styles.deckEdit}>
    <TopBar showBackButton={true} />
    <LoadingSpinner spin={loading}>
      <form className={styles.inner} onSubmit={onFormSubmit}>
        <h4 className={styles.title}>
          {t('name_of_deck')}
        </h4>
        <p className={styles.desc}>
          {t('name_of_deck_desc')}
        </p>
        {Object.entries(names!).map(entry => (
          <InputGroup key={entry[0]} {...inputGroupStyle} >
            <InputLeftElement height="50px" width="60px">
              <CountryFlag
                countryCode={entry[0] as SystemLanguage}
                />
            </InputLeftElement>
            <Input
              {...inputStyle}
              paddingLeft="65px"
              value={entry[1]}
              onChange={event => onNameChange(event, entry[0] as SystemLanguage) }
              type="text"
              autoCapitalize="none"
              maxLength={120}
            />
            <InputRightElement height="50px" width="50px">
              <IconButton
                _hover={{ filter: 'brightness(90%)' }}
                aria-label="remove"
                background="none"
                width="max-content"
                onClick={() => onRemoveNameClicked(entry[0] as SystemLanguage)}
                icon={<img alt="remove" src={removeButtonIcon} />}
                disabled={Object.keys(names!).length <= 1}
              />
            </InputRightElement>
          </InputGroup>
        ))}
        { availableLanguages.length > 0 && (
          <div className={styles.addWrapper}>
            <IconButton
              _hover={{ filter: 'brightness(90%)' }}
              aria-label="add"
              background="none"
              width="max-content"
              marginTop="2px"
              marginRight="6px"
              onClick={onAddNameClicked}
              icon={<img alt="add" src={addButtonIcon} />}
            />
          </div>
        )}

        <div className={styles.privacyWrapper}>
          <h4 className={styles.title}>{t('privacy_setting')}</h4>
          <div className={styles.switchWrapper}>
            <div className={classnames(styles.text, { [styles.active]: !isPrivate })}>
              <h3>{t('public')}</h3>
              <h4>{t('friends_can_view')}</h4>
            </div>
            <Switch
              onChange={onSwitchChange}
              isChecked={!isPrivate}
              size="lg"
              colorScheme="switchBlue"
              transform="scaleX(-1)"
            />
            <div className={classnames(styles.text, { [styles.active]: isPrivate })}>
              <h3>{t('private')}</h3>
              <h4>{t('only_you_can_view')}</h4>
            </div>
          </div>
        </div>

        <div className={styles.gameModeWrapper}>
          <h4 className={styles.title}>{t('about_play')}</h4>
          <p className={styles.desc}>{t('game_mode_desc')}</p>
          {GameModes.map((name, index) => (
            <GameModeRow
              key={name}
              name={name}
              index={index}
              checked={excludedGameModes.indexOf(name) < 0}
              onClick={onGameModeClicked}
            />
          ))}
        </div>

        {currentUser?.isAdmin && (
          <div className={styles.categoryWrapper}>
            <h4 className={styles.title}>{t('category')}</h4>
            {Object.keys(HOME_SECTION_TO_NAME_MAPPING).map(k => parseInt(k, 10)).map(k => (
              <CategoryRow
                key={k}
                homeSection={k}
                checked={homeSection === k}
                onClick={onCategoryRowClicked}
              />
            ))}
          </div>
        )}
        <Button
          {...saveButtonStyle}
          className={styles.save}
          isLoading={saving}
          disabled={invalid}
          type="submit"
        >
          {t('save')}
        </Button>
      </form>
      <SelectLanguageModal
        isOpen={isSelectLanguageModalOpened}
        options={availableLanguages}
        onClose={onSelectLanguageModalClose}
        onConfirm={onSelectLanguageModalConfirm}
      />
    </LoadingSpinner>
  </div>;
};

export default DeckForm;
