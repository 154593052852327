import SPL from 'spl.js';
import JSZip from 'jszip';
import striptags from 'striptags';
import { decode } from 'html-entities';

export const getMediaMap = async (f: JSZip) => {
  const mediaFile = f.file('media');
  const text = await mediaFile!.async('string');
  const parsed = JSON.parse(text);
  return Object.fromEntries(
    Object.entries(parsed).map(x => [x[1], x[0]]),
  );
};

export const getDB = async (f: JSZip) => {
  const dbfile = f.file('collection.anki2')!;
  const buffer = await dbfile.async('arraybuffer');
  const spl = await SPL();
  const db = await spl.db(buffer);
  return db;
};

export type IDBSync = ReturnType<typeof SPL.prototype.db>;

export const getModels = async (db: IDBSync) => {
  const cols = await db.exec('select * from col').get.objs;

  const result: Record<string, any> = {};
  for (const col of cols) {
    for (const mid of Object.keys(col.models)) {
      const count = (
        await db.exec(`select count(*) as c from notes where mid == '${mid}'`).get.objs)[0].c;

      if (count > 0) {
        const model = col.models[mid];
        result[mid] = {
          count,
          name: model.name,
          flds: model.flds.map((x: any) => x.name),
        };
      }
    }
  }

  return result;
};

export const getName = async (db: IDBSync) => {
  const cols = await db.exec('select * from col').get.objs;

  for (const col of cols) {
    for (const mid of Object.keys(col.decks)) {
      if (mid !== '1') {
        return col.decks[mid].name;
      }
    }
  }
};

const SOUND_REGEX = /\s?\[sound:([^\]]+)\]/;
const IMAGE_REGEX = /<img src="([^"]+)"\s?\/?>/;

const CARD_FIELD_TO_ANKI_NAME_MAPPING = {
  title: ['word', 'expression', '单词', 'front'],
  image: ['picture'],
  audio: ['audio'],
  pronunciation: ['phonetic symbol', 'reading', '音标', 'pronunciation (ipa)', 'pinyin', 'ipa', 'pronunciation'],
  meanings: ['definition', 'meaning', '解释', '释义', 'back', 'translation'],
  sentences: ['sentence', 'example'],
  remark: [],
};

export const CARD_FIELDS = Object.keys(CARD_FIELD_TO_ANKI_NAME_MAPPING);

const ANKI_NAME_TO_CARD_FIELD_MAPPING: Record<string, string> = {};

for (const item of Object.entries(CARD_FIELD_TO_ANKI_NAME_MAPPING)) {
  const k: string = item[0];
  const values: string[] = item[1];
  for (const value of values) {
    ANKI_NAME_TO_CARD_FIELD_MAPPING[value] = k;
  }
}

export const getCardFieldMapping = async (db: IDBSync, model: any, mid: string) => {
  const notes = await db.exec(`select * from notes where mid == '${mid}' limit 10`).get.objs;

  const results = [];
  const mapping: Record<string, string[]> = {};

  for (const note of notes) {
    const record: Record<string, string> = {};
    const keys = model.flds;
    note.flds.split('').forEach((v: string, i: number) => {
      const key = keys[i];
      if (v.search(IMAGE_REGEX) >= 0) {
        mapping[key] = ['image'];
      }
      if (v.search(SOUND_REGEX) >= 0) {
        mapping[key] = ['audio'];
      }
      record[key] = v;
    });
    results.push(record);
  }

  for (const k of model.flds) {
    if (!(k in mapping)) {
      const key = ANKI_NAME_TO_CARD_FIELD_MAPPING[k.toLowerCase()];
      mapping[k] = key ? [key] : [];
    }
  }

  const result = Object.entries(mapping).sort((a, b) => {
    const keys = Object.keys(CARD_FIELD_TO_ANKI_NAME_MAPPING).reverse();
    const aIndex = keys.indexOf(a[0][1]);
    const bIndex = keys.indexOf(b[0][1]);
    return bIndex - aIndex;
  });

  let sample;
  let featureCount = -1;

  for (const note of notes) {
    const currentCount = Object.values(note).filter(x => x).length;
    if (currentCount > featureCount) {
      sample = note;
      featureCount = currentCount;
    }
  }

  return {
    sample,
    mapping: result,
  };
};

export const parseNote = (
  note: any, model: any, mappingList: [string, string[]][], mediaMap: Record<string, string>) => {

  const mapping = Object.fromEntries(mappingList);

  const record: Record<string, any> = {
    title: [],
    image: null,
    audio: null,
    pronunciation: [],
    meanings: [],
    sentences: [],
    remark: [],
  };

  const keys = model.flds;
  note.flds.split('').forEach((v: string, i: number) => {
    const mkeys = mapping[keys[i]];

    if (mkeys === undefined) {
      return;
    }

    let value = v;

    for (const key of mkeys) {
      if (key === 'image') {
        const matched = value.match(IMAGE_REGEX);
        if (matched) {
          record.image = {
            name: matched[1],
            media: mediaMap[matched[1]],
          };
          value = value.replace(matched[0], '');
        }

      } else if (key === 'audio') {
        const matched = v.match(SOUND_REGEX);
        if (matched) {
          record.audio = {
            name: matched[1],
            media: mediaMap[matched[1]],
          };
          value = value.replace(matched[0], '');
        }
      } else {
        const stripped = decode(striptags(value)).trim();
        if (stripped) {
          if (key === 'remark') {
            record.remark.push(`${keys[i]}: ${stripped}`);
          } else if (key in record) {
            record[key].push(stripped);
          }
        }
      }
    }
  });

  for (const k of ['title', 'pronunciation', 'meanings']) {
    record[k] = record[k].filter((x: string) => x).join('; ');
  }

  record.remark = record.remark.filter((x: string) => x).join('\n\n');
  record.meanings = [['en', record.meanings]];
  return record;
};

export const getNotes = async (
  mediaMap: Record<string, string>, db: IDBSync, mid: string, model: any,
  mapping: [string, string[]][], limit: number = 10, offset: number = 0) => {

  const results = [];

  const notes = await db.exec(
    `select * from notes where mid == '${mid}' limit ${limit} offset ${offset}`,
  ).get.objs;

  for (const note of notes) {
    const record = parseNote(note, model, mapping, mediaMap);
    results.push(record);
  }

  return results;
};
