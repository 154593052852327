import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@chakra-ui/react';

import { Card } from '../../types';
import styles from './Revision.module.scss';
import getFileUrl from '../../utils/getFileUrl';
import playAudioIcon from '../../images/play-audio-blue.svg';
import playIcon from '../../images/play.svg';
import { AppContext } from '../../AppContext';
import getMeaning from '../../utils/getMeaning';
import ScoreIcon from '../../components/ScoreIcon';
import { truncateText } from '../../utils/textUtils';

interface RevisionRowInterface {
  card: Card;
}

interface RevisionInterface {
  cards: Card[];
  listRef?: React.RefObject<HTMLDivElement>;
}

const RevisionRow: React.FC<RevisionRowInterface> = ({ card }) => {
  const [playing, setPlaying] = useState(false);
  const { currentUser } = useContext(AppContext);

  const onPlayAudioClicked = () => {
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const meaning = getMeaning(card.meanings, currentUser?.systemLanguage, true) || '-';
  return (
    <div key={card.id} className={styles.row} >
      <div className={styles.statusCol}>
        <ScoreIcon score={card.score}/>
      </div>
      <Link
        className={classnames(styles.word, styles.wordCol)}
        to={`/deck?id=${card.deck?.nid || card.deckId}&cardId=${card.nid}`}
      >
        <div className={styles.wordTitle}>{truncateText(card.title)}</div>
        <div className={styles.wordMeaning}>
          { truncateText(meaning) }
        </div>
      </Link>
      <div className={classnames(styles.meaning, styles.meaningCol)}>
        <div className={styles.meaningInner}>
          {meaning}
        </div>
      </div>
      <div className={styles.buttonCol}>
        <IconButton
        className={styles.playAudio}
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play audio"
        background="none"
        height="42px"
        width="42px"
        onClick={onPlayAudioClicked}
        disabled={!card.audioFile || playing}
        icon={<img alt="play" src={playAudioIcon} />}
        />
        <IconButton
          className={styles.play}
          _hover={{ filter: 'brightness(90%)' }}
          aria-label="play"
          background="none"
          height="42px"
          width="42px"
          marginLeft="46px"
          to="/play/auto?limit=20"
          as={Link}
          icon={<img alt="play" src={playIcon} />}
        />
      </div>
    </div>
  );
};

const Revision: React.FC<RevisionInterface> = ({ cards, listRef }) => {
  const { t } = useTranslation('revision');
  const context = useContext(AppContext);
  return <div className={classnames(
      styles.revision, { [styles.zh]: context.currentUser?.systemLanguage === 'zh' })}
        ref={listRef}>
    <div className={classnames(styles.row, styles.header)}>
      <div className={styles.statusCol}>{t('status')}</div>
      <div className={styles.wordCol}>{t('word')}</div>
      <div className={styles.meaningCol}>{t('meaning')}</div>
      <div className={styles.buttonCol}></div>
    </div>
    { cards.map(card => (
      <RevisionRow key={card.id} card={card}/>
    ))}
  </div>;
};

export default Revision;
