import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@chakra-ui/react';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import TopBar from '../../components/TopBar';
import styles from './DeckList.module.scss';
import plusImage from '../../images/plus.svg';
import plusImageOrange from '../../images/plus-orange.svg';
import { Deck } from '../../types';
import DeckBoxV2 from '../../components/DeckBoxV2';
import analytics from '../../services/analytics';
import CreateDeckFormModal from '../../components/CreateDeckForm';
import LoadingSpinner from '../../components/LoadingSpinner';
import SubMenu from '../../components/SubMenu';

const createDeckButtonStyle = {
  width: '148px',
  background: 'white',
  border: '1px solid #F79411',
  color: '#F79411',
  height: '42px',
  fontWeight: 'normal',
  textTransform: 'none',
} as ButtonProps;

interface HeaderInterface {
  onCreateDeck: () => void;
}

const Header: React.FC<HeaderInterface> = ({ onCreateDeck }) => {
  const { t, i18n } = useTranslation('deck_list');
  const [deckType] = useQueryParam('type', StringParam);
  createDeckButtonStyle.fontWeight = i18n.language === 'zh' ? 'bold' : 'normal';

  return <div className={styles.header}>
    <Link
      to="/decks"
      className={classnames(
        styles.headerLink, deckType ? styles.inactive : styles.active)}
    >
      {t('my_decks')}
    </Link>
    <Link
      to="/decks?type=official"
      className={classnames(
        styles.headerLink, deckType === 'official' ? styles.active : styles.inactive)}
    >
      {t('teachers_decks')}
    </Link>
    <Link
      to="/decks?type=users"
      className={classnames(
        styles.headerLink, deckType === 'users' ? styles.active : styles.inactive)}
    >
      {t('users_decks')}
    </Link>
    <div className={styles.spacer} />
    <Button {...createDeckButtonStyle} onClick={onCreateDeck }>
      <img src={plusImageOrange} className={styles.plus} alt="create deck" />
      {t('create_deck')}
    </Button>
  </div>;
};

interface CreateDeckTopBarLinkInterface {
  onClick: () => void;
}

const CreateDeckTopBarLink: React.FC<CreateDeckTopBarLinkInterface> = ({ onClick }) => {
  const { t } = useTranslation('deck_list');
  return <Link to="#" onClick={onClick} className={styles.createDeckLink}>
    <img src={plusImage} className={styles.plus} alt="create deck" />
    { t('create_deck') }
  </Link>;
};

const DeckList = () => {
  const { t } = useTranslation('deck_list');
  const [decks, setDecks] = useState<Deck[]>([]);
  const history = useHistory();
  const context = useContext(AppContext);
  const [deckType] = useQueryParam('type', StringParam);
  const [isOpenDeckForm, setIsOpenDeckForm] = useState(false);
  const [spin, setSpin] = useState(true);

  useEffect(
    () => {
      analytics.page();
    },
    [],
  );

  useEffect(
    () => {
      context.setFooterColor('#6DD8E0');
      return () => {
        context.setFooterColor('');
      };
    },
    [], // eslint-disable-line
  );

  useEffect(
    () => {
      const onDeckTypeChange = async () => {
        setSpin(true);
        await getDecks();
        setSpin(false);
      };
      onDeckTypeChange();
    },
    [deckType, context.currentUser?.targetLanguage], // eslint-disable-line
  );

  useEffect(
    () => {
      context.setCurrentTab('deck');
    },
    [context],
  );

  const getDecks = useCallback(
    async () => {
      const response = await axios.get('/decks', { params: { type: deckType || 'created' } });
      setDecks(response.data.data.decks);
      setSpin(false);
    },
    [deckType],
  );

  const onSelectChange = (page: number) => {
    if (page === 0) {
      history.replace('/decks');
    } else if (page === 1) {
      history.replace('/decks?type=official');
    } else if (page === 2) {
      history.replace('/decks?type=users');
    }
  };

  const onDeckUpdated = () => {
    getDecks();
  };

  const onCloseDeckForm = (updated: boolean, deckId?: string) => {
    setIsOpenDeckForm(false);
    if (updated && deckId) {
      history.push(`/deck?id=${deckId}`);
    }
  };

  const onCreateDeckClicked = () => setIsOpenDeckForm(true);

  const currentIndex = useMemo(
    () => {
      if (deckType === 'official') {
        return 1;
      }
      if (deckType === 'users') {
        return 2;
      }
      return 0;
    },
    [deckType],
  );

  return <div className={styles.outer}>
    <TopBar RightButton={() => (<CreateDeckTopBarLink onClick={onCreateDeckClicked} />)} />
    <div className={styles.deckIndex}>
      <Header onCreateDeck={onCreateDeckClicked} />
      <div className={styles.selectHeader}>
        <SubMenu
          onPageClick={onSelectChange}
          menu={[t('my_decks'), t('teachers'), t('users')]}
          currentIndex={currentIndex}
          arrowColor="blue"
        />
      </div>

      <LoadingSpinner spin={spin}>
        { (decks.length === 0) ? (
          <div className={styles.emptylist}>{t('empty_list')}</div>
        ) : (
          <div className={classnames(styles.decks)}>
            {decks.map(deck => <DeckBoxV2
              key={deck.id}
              deck={deck}
              onDeckUpdated={onDeckUpdated}
            />)}
          </div>
        ) }
      </LoadingSpinner>
    <CreateDeckFormModal isOpen={isOpenDeckForm} onClose={onCloseDeckForm} />
    </div>
  </div>;
};

export default DeckList;
