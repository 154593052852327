import { Button } from '@chakra-ui/react';
import tickIcon from '../../images/tick.svg';
import crossIcon from '../../images/cross.svg';
import variables from '../../variables.module.scss';

interface MCButtonInterface {
  checked: boolean;
  onClick: (value: string) => void;
  value: string;
  isAnswer: boolean;
  isShowingAnswer: boolean;
}

const MCButton: React.FC<MCButtonInterface> =
({ value, onClick, checked, isAnswer, isShowingAnswer }) => {
  return (
    <Button
      onClick={() => onClick(value)}
      variant="outline"
      width="347px"
      height="51px"
      background="white"
      borderRadius="10px"
      borderColor={ isShowingAnswer ? (isAnswer ? '#67D29E' :
        checked ? '#FF6A91' : '#97ACD9') : '#97ACD9' }
      marginBottom="12px"
      borderWidth={ checked || isShowingAnswer ? '3px' : '1px' }
      fontFamily={variables.normalFontFamily}
      fontSize="16px"
      fontWeight="600"
      color="#2D65C2"
      colorScheme="green"
      display="flex"
      flexFlow="row"
      justifyContent="space-between"
      pointerEvents={ isShowingAnswer ? 'none' : undefined }
    >
      { value }
      { isShowingAnswer && (isAnswer ? <img width="26px" src={tickIcon} alt="tick" /> :
        checked && <img width="26px" src={crossIcon} alt="cross" />)}
    </Button>
  );
};

export default MCButton;
