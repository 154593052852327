import seedRandom from 'seed-random';

const shuffleList = <T>(list: T[], seed: string): T[] => {
  const random = seedRandom(seed);
  const result = [...list];

  for (let i = 0; i < list.length - 1; i += 1) {
    if (random() > 0.5) {
      const tmp = result[i];
      result[i] = result[i + 1];
      result[i + 1] = tmp;
    }
  }

  return result;
};

export default shuffleList;
