import enAudio from './en.mp3';
import esAudio from './es.mp3';
import frAudio from './fr.mp3';
import jpAudio from './jp.mp3';
import zhAudio from './zh.mp3';
import yueAudio from './yue.mp3';

import { createPlay } from '../';

export const en = createPlay(enAudio, 1);
export const es = createPlay(esAudio, 1);
export const fr = createPlay(frAudio, 1);
export const jp = createPlay(jpAudio, 1);
export const zh = createPlay(zhAudio, 1);
export const yue = createPlay(yueAudio, 1);
