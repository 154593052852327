import { ChangeEvent, useState, useEffect } from 'react';
import { Button, Select, Modal, ModalOverlay, ModalContent, ModalFooter,
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { Language } from '../types';
import getLabelAndCountry from '../utils/getLabelAndCountry';
import variables from '../variables.module.scss';

interface SelectLanguageModalInterface {
  options: Language[];
  onConfirm: (language: Language) => void;
  onClose: () => void;
  isOpen: boolean;
  initialValue?: Language;
}

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
};

const SelectLanguageModal: React.FC<SelectLanguageModalInterface> = (
  { options, onClose, onConfirm, isOpen, initialValue }) => {
  const { t } = useTranslation('card_form');
  const [value, setValue] = useState<Language>();
  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setValue(event.currentTarget.value as Language);
  };

  useEffect(
    () => {
      if (initialValue) {
        setValue(initialValue);
      } else if (options.length > 0) {
        setValue(options[0]);
      }
    },
    [options, initialValue, isOpen],
  );

  return (
    <Modal isOpen={options.length > 0 && isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding="40px" textAlign="center">
        <Select
          value={value}
          onChange={onChange}
          {...selectStyle}
          icon={<TriangleDownIcon />}
          textAlign="center"
        >
          {options.map(option => (
            <option key={option} value={option}>{getLabelAndCountry(option).label}</option>
          ))}
        </Select>
        <ModalFooter>
          <Button
            width="266px"
            height="47px"
            margin="16px auto 0"
            fontWeight="400"
            onClick={() => { onConfirm(value!); }}
          >
            {t('confirm')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectLanguageModal;
