import { useState, useEffect } from 'react';

import { IconButton, Input, InputGroup } from '@chakra-ui/react';
import getFileUrl from '../../utils/getFileUrl';
import { Card } from '../../types';
import styles from './ImageToSpelling.module.scss';
import { useTranslation } from 'react-i18next';
import playAudioIcon from '../../images/play-audio.svg';
import useInput from '../../hooks/useInput';
import { compareStr } from '../../utils/textUtils';
import crossIcon from '../../images/cross.svg';
import tickIcon from '../../images/tick.svg';
import generateRandomWords from '../../utils/generateRandomWords';
import axios from '../../utils/axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import Keyboard from '../../components/Keyboard';
import Video from '../../components/Video';

const inputGroupStyle = {
  display: 'flex',
  justifyContent: 'center',
  alighContent: 'center',
};

interface ImageToSpellingInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const ImageToSpelling: React.FC<ImageToSpellingInterface > =
({ card, setResult, isShowingAnswer }) => {
  const [answer, onAnswerChange, setAnswer] = useInput('');
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation('play');
  const [spin, setSpin] = useState(false);
  const [keyboardChars, setKeyboardChars] = useState<string[]>([]);

  useEffect(
    () => {
      setAnswer('');
    },
    [setAnswer, card],
  );

  useEffect(
    () => {
      const getChoices = async () => {
        setSpin(true);
        let fetchedChoices = [];
        try {
          const response = await axios.get('/revision/choices', { params: { cardId: card.id } });
          fetchedChoices = response.data.data.cards.map((c: any) => c.title);
          fetchedChoices = [...(new Set(fetchedChoices.filter((c: any) => c)).values())];
        } catch {
          // do nothing
        }

        const choices = generateRandomWords(
            card.deck.language, 3 - fetchedChoices.length, /[A-Z]/.test(card.title[0]))
            .concat([card.title]).concat(fetchedChoices).map(c => c.split(''));

        const chars = [...new Set(
          ([] as string[]).concat(...choices).sort(() => 0.5 - Math.random()))];

        setKeyboardChars(chars);
        setSpin(false);
      };
      getChoices();

    },
    [card],
  );

  useEffect(
    () => {
      setResult(compareStr(answer, card.title));
    },
    [answer, setResult, card],
  );

  const onCharClicked = (char: string) => {
    setAnswer(x => x + char);
  };

  const onBackspace = () => {
    setAnswer(x => x.substring(0, x.length - 1));
  };

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const imageUrl = getFileUrl(card.imageFile);

  return <div className={styles.imageToSpelling}>
    <div className={styles.imageWrapper}>
      <Video imageUrl={imageUrl} className={styles.image}>
        <div className={styles.image}
          style={{ backgroundImage: `url(${imageUrl}` }} />
      </Video>
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        height={card.imageFile ? '40px' : '60px'}
        width={card.imageFile ? '40px' : '60px'}
        onClick={onPlayClicked}
        disabled={!card.audioFile || playing}
        icon={<img
          alt="play"
          className={!card.imageFile ? styles.largeAudio : undefined}
          src={playAudioIcon}
        />}
        className={card.imageFile ? styles.audio : undefined}
      />
    </div>
    <div className={styles.instruction}>{t('spell_the_word')}</div>
    { isShowingAnswer && (!compareStr(answer, card.title) &&
        <div className={styles.correctAnsWrapper}>
          <div className={styles.correctAnsTitle}>{t('answer')}</div>:&nbsp;
          <div className={styles.correctAns}>{card.title.toLowerCase().trim()}</div>
        </div>
      )
    }
    <InputGroup {...inputGroupStyle}>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          color={isShowingAnswer ? (compareStr(answer, card.title) ? '#389F6D' : '#FF6A91') : '#2D65C2'}
          pointerEvents={isShowingAnswer ? 'none' : 'auto'}
          onChange={onAnswerChange}
          value={answer}
          autoFocus
          placeholder={t('answer_placeholder')}
          isReadOnly={isShowingAnswer}
        />
          { isShowingAnswer && (compareStr(answer, card.title) ?
            <img className={styles.tickIconStyle} src={tickIcon} alt="tick" /> :
            <img className={styles.crossIconStyle} src={crossIcon} alt="cross" />
            )
          }
      </div>
    </InputGroup>
    <LoadingSpinner spin={spin}>
      <Keyboard
        onCharClicked={onCharClicked}
        onBackspace={onBackspace}
        chars={keyboardChars}
      />
    </LoadingSpinner>
  </div>;
};

export default ImageToSpelling;
