import { useRef } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader,
  AlertDialogBody, AlertDialogFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface NavPromptInterface {
  enabled: boolean;
}

const NavPrompt: React.FC<NavPromptInterface> = ({ enabled }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation('card_form');

  return (
    <NavigationPrompt
      when={enabled}
    >
      {({ onConfirm, onCancel }) => (
        <AlertDialog
          isOpen={true}
          leastDestructiveRef={cancelRef}
          onClose={onCancel}
          size="xl"
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent margin="20px">
              <AlertDialogHeader fontSize="xl" fontWeight="bold" />
              <AlertDialogBody fontSize="lg">
                {t('are_you_sure_to_leave')}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} fontWeight="200" onClick={onConfirm}>
                  {t('leave')}
                </Button>
                <Button variant="red" fontWeight="200" onClick={onCancel} ml={3}>
                  {t('cancel')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </NavigationPrompt>
  );
};

export default NavPrompt;
