import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styles from './Anki.module.scss';
import variables from '../../variables.module.scss';

interface DeckSelectProps {
  models: Record<string, any>;
  onChange: (mid: string) => void;
  mid: string;
}

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
};

const DeckSelect: React.FC<DeckSelectProps> = ({ models, onChange, mid }) => {
  const { t } = useTranslation('create_deck_form');

  return <div className={styles.formControl}>
    <h4 className={styles.label}>{t('select_a_deck_to_import')}</h4>
    <Select
      value={mid}
      onChange={e => onChange(e.target.value)}
      {...selectStyle}
    >
      {Object.keys(models).map(i => (
        <option value={i} key={i}>{models[i].name}</option>
      ))}
    </Select>
  </div>;
};

export default DeckSelect;
