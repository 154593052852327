import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import FriendsTab from './FriendsTab';
import PendingTab from './PendingTab';
import TopBar from '../../components/TopBar';
import SubMenu from '../../components/SubMenu';
import styles from './Friends.module.scss';
import { AppContext } from '../../AppContext';
import { FriendRequestNotificationCount } from '../../components/NotificationCount';
import analytics from '../../services/analytics';

interface FriendsParams {
  submenu: string;
}

const Friends = () => {
  const { t } = useTranslation('friends');
  const { submenu: friendsParams } = useParams<FriendsParams>();
  const [friendsSection, setFriendsSection] = useState(!friendsParams ? 0 :
    friendsParams === 'pending' ? 1 : 0);
  const history = useHistory();

  const context = useContext(AppContext);
  useEffect(
    () => {
      analytics.page();
      context.setCurrentTab('friend');
    },
    [context],
  );

  const pendingTab = <>{t('pending')}
    <FriendRequestNotificationCount className={styles.notificationCount} />
  </>;

  const onFriendsSubMenuChanged = (page: number) => {
    setFriendsSection(page);
    if (page === 0) {
      history.replace({
        pathname: '/friends',
      });
    } else if (page === 1) {
      history.replace({
        pathname: '/friends/pending',
      });
    }
  };

  return <div className={styles.outer}>
    <TopBar />
    <div className={styles.friends}>
      <div className={styles.subMenu}>
        <SubMenu
          onPageClick={onFriendsSubMenuChanged}
          menu={[t('friends'), pendingTab]}
          currentIndex={friendsSection}
          arrowColor="blue"
        />
      </div>
      { (friendsSection === 0) && <FriendsTab onAddFriend={() => onFriendsSubMenuChanged(1)} />}
      { (friendsSection === 1) && <PendingTab />}
    </div>
  </div>;
};

export default Friends;
