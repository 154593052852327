import { useState, useMemo, ChangeEvent } from 'react';
import { IconButton, InputRightElement,
  Input, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TARGET_LANGUAGES } from '../../constants';
import CountryFlag from '../../components/CountryFlag';
import styles from './CardForm.module.scss';
import { Language } from '../../types';
import addButtonIcon from '../../images/add-button.svg';
import variables from '../../variables.module.scss';
import removeButtonIcon from '../../images/remove-button.svg';
import SelectLanguageModal from '../../components/SelectLanguageModal';

const inputStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 500,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
};

const useMeanings = (systemLanguage: Language) => {
  const [meanings, setMeanings] = useState<[Language, string][]>([[systemLanguage, '']]);

  const availableLanguages = useMemo<Language[]>(
    () => {
      const meaningLanguages: Language[] = meanings.map(m => m[0]);
      return (TARGET_LANGUAGES as Language[]).filter(l => meaningLanguages.indexOf(l) < 0);
    },
    [meanings],
  );

  const { t } = useTranslation('card_form');

  const [isSelectLanguageModalOpened, setIsSelectLanguageModalOpened] = useState(false);

  const onMeaningChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setMeanings(meanings.map((m, i) => i === index ? [m[0], event.currentTarget.value] : m));
  };

  const onAddMeaningClicked = () => {
    setIsSelectLanguageModalOpened(true);
  };

  const onRemoveMeaningClicked = (index: number) => {
    setMeanings(meanings.filter((_, i) => i !== index));
  };

  const onSelectLanguageModalClose = () => {
    setIsSelectLanguageModalOpened(false);
  };

  const onSelectLanguageModalConfirm = (l: Language) => {
    setIsSelectLanguageModalOpened(false);
    setMeanings(m => [...m, [l, '']]);
  };

  const component = <div className={styles.inputGroup}>
    <div className={styles.inputTitle}>{t('meaning')}</div>
    {meanings.map((meaning, index) => (
      <InputGroup key={index} marginBottom="9px">
        <InputLeftElement height="50px" width="60px">
          <CountryFlag
            countryCode={meaning[0]}
            />
        </InputLeftElement>
        <Input
          {...inputStyle}
          paddingLeft="65px"
          value={meaning[1]}
          onChange={event => onMeaningChange(event, index) }
          type="text"
          autoCapitalize="none"
          maxLength={120}
        />
        <InputRightElement height="50px" width="50px">
          <IconButton
            _hover={{ filter: 'brightness(90%)' }}
            aria-label="remove"
            background="none"
            width="max-content"
            onClick={() => onRemoveMeaningClicked(index)}
            icon={<img alt="remove" src={removeButtonIcon} />}
            disabled={meanings.length <= 1}
          />
        </InputRightElement>
      </InputGroup>
    ))}
    { availableLanguages.length > 0 && (
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="add"
        background="none"
        width="max-content"
        alignSelf="end"
        marginTop="2px"
        marginRight="6px"
        onClick={onAddMeaningClicked}
        icon={<img alt="add" src={addButtonIcon} />}
      />
    )}
    <SelectLanguageModal
      isOpen={isSelectLanguageModalOpened}
      options={availableLanguages}
      onClose={onSelectLanguageModalClose}
      onConfirm={onSelectLanguageModalConfirm}
    />
  </div>;

  return {
    meanings, setMeanings, component,
  };
};

export default useMeanings;
