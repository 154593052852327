import { ChangeEvent, useState } from 'react';
import { IconButton, InputRightElement, Input, InputGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import styles from './CardForm.module.scss';
import removeButtonIcon from '../../images/remove-button.svg';
import addButtonIcon from '../../images/add-button.svg';
import variables from '../../variables.module.scss';

const inputStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 500,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
};

const useSentences = () => {
  const { t } = useTranslation('card_form');

  const [sentences, setSentences] = useState<string[]>(['']);

  const onSentenceChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setSentences(sentences.map((s, i) => i === index ? event.currentTarget.value : s));
  };

  const onAddSentenceClicked = () => {
    if (sentences.length < 3) {
      setSentences([...sentences, '']);
    }
  };

  const onRemoveSentenceClicked = (index: number) => {
    setSentences(sentences.filter((_, i) => i !== index));
  };

  const component = <div className={styles.inputGroup}>
    <div className={styles.inputTitle}>{t('sentences')}</div>
    {sentences.map((sentence, index) => (
      <InputGroup key={index} marginBottom="9px">
        <Input
          {...inputStyle}
          value={sentence}
          onChange={event => onSentenceChange(event, index) }
          type="text"
          autoCapitalize="sentences"
          maxLength={120}
        />
        <InputRightElement height="50px" width="50px">
          <IconButton
            _hover={{ filter: 'brightness(90%)' }}
            aria-label="remove"
            background="none"
            width="max-content"
            onClick={() => onRemoveSentenceClicked(index)}
            icon={<img alt="remove" src={removeButtonIcon} />}
          />
        </InputRightElement>
      </InputGroup>
    ))}
    { sentences.length < 3 && (
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="add"
        background="none"
        width="max-content"
        alignSelf="end"
        marginTop="2px"
        marginRight="6px"
        onClick={onAddSentenceClicked}
        icon={<img alt="add" src={addButtonIcon} />}
      />
    )}
  </div>;

  return { sentences, setSentences, component };
};

export default useSentences;
