import { Language } from '../types';

const getMeaning = (
  meanings: [Language, string][],
  currentLanguage?: Language,
  fallback: boolean | undefined = undefined,
): string => {
  const meaning = meanings
    .filter(m => m[0] === currentLanguage).map(m => m[1]).find(m => m);

  if (meaning) {
    return meaning;
  }

  if (fallback) {
    const fallbackMeaning = meanings.map(m => m[1]).find(m => m);
    if (fallbackMeaning) {
      return fallbackMeaning;
    }
  }

  return '';
};

export default getMeaning;
