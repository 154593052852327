import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Image } from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';

import styles from './TopBar.module.scss';
import iconImage from '../../images/icon.svg';
import avatarImage from '../../images/avatar.svg';
import linkActiveImage from '../../images/link-active.svg';
import { AppContext, TabType } from '../../AppContext';
import getLabelAndCountry from '../../utils/getLabelAndCountry';
import { Language } from '../../types';
import SelectLanguageModel from '../SelectLanguageModal';
import { TARGET_LANGUAGES } from '../../constants';
import axios from '../../utils/axios';
import variables from '../../variables.module.scss';
import getFileUrl from '../../utils/getFileUrl';
import { FriendRequestNotificationCount } from '../../components/NotificationCount';
import { getName } from '../../utils/textUtils';

interface TopBarLinkInterface {
  route: string;
  label: string;
  tab: TabType;
}

const TopBarLink: React.FC<TopBarLinkInterface> = ({ route, label, tab }) => {
  const context = useContext(AppContext);

  return <Link to={route} className={ classnames(
    styles.link,
    { [styles.zh]: context.currentUser?.systemLanguage === 'zh' })}>
    { label }
    { tab === 'friend' && <FriendRequestNotificationCount className={styles.notificationCount} />}
    { (context.currentTab === tab) &&
      <img className={styles.linkActive} src={linkActiveImage} alt={'active'} />
    }
  </Link>;
};

const BackButton = () => {
  const context = useContext(AppContext);

  const { t } = useTranslation('topbar');
  return <Button
    variant="unstyled"
    onClick={() => context.goBack()}
    color="#FEDF00"
    textTransform="uppercase"
    fontFamily={variables.titleFontFamily}
    fontSize="18px"
    fontWeight="normal"
  >
    {t('back')}
  </Button>;
};

interface TopBarInterface {
  RightButton?: React.FC<any>;
  showBackButton?: boolean;
  backgroundColor?: string;
}

const TopBar: React.FC<TopBarInterface> = ({ RightButton: CustomRightButton,
  showBackButton, backgroundColor = '#16B5DF' }) => {
  const { t } = useTranslation('topbar');
  const context = useContext(AppContext);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);

  const linkData: TopBarLinkInterface[] = [
    { route: '/', label: t('home'), tab: 'home' },
    { route: '/card/new', label: t('new_card'), tab: 'card' },
    { route: '/decks', label: t('deck'), tab: 'deck' },
    { route: '/friends', label: t('friend'), tab: 'friend' },
    { route: '/play', label: t('play'), tab: 'play' },
  ];
  const currentUser = context.currentUser;

  const { label } = getLabelAndCountry(
    (currentUser?.targetLanguage || 'en') as Language,
  );
  const name = currentUser && currentUser.fullName;
  const avatarFile = (currentUser && currentUser.avatarFile
    && getFileUrl(currentUser.avatarFile)) || '';
  const RightButton = showBackButton ? BackButton : CustomRightButton;

  const onLanguageChange = async (targetLanguage: Language) => {
    setShowLanguageSelect(false);
    await axios.put('/users/me', {
      ...currentUser,
      targetLanguage,
    });
    context.getCurrentUser();
  };

  return <>
    <div
      style={{ backgroundColor }}
      className={styles.topBar}
    >
      <div className={styles.inner}>
        <Link to="/" className={styles.iconWrapper}>
          <img className={styles.icon} src={iconImage} alt="logo"/>
          <span>{t('home')}</span>
        </Link>
        { RightButton && <div className={styles.rightButton}><RightButton /></div> }

        {linkData.map(link => (
          <TopBarLink {...link} key={link.tab}/>
        ))}

        <Link
          to="/shop"
          className={classnames([styles.link, styles.disabled])}
          style={{ display: 'none' }}
        >
          {t('shop')}
        </Link>

        <div className={styles.searchWrapper} />

        { currentUser && (
          <Button
            className={classnames(styles.language, { [styles.hiddenInMobile]: RightButton })}
            variant="unstyled"
            onClick={() => setShowLanguageSelect(true)}
          >
            <TriangleDownIcon
              className={styles.triangle}
              color="white"
              w={3}
            />
              <span className={styles.countryLabel}>{label}</span>
          </Button>
        )}
        <Link
          to={currentUser ? `/user/${currentUser.username}` : '/login'}
          className={styles.login}
        >
          <Image className={styles.avatarImageStyle}
            src={avatarFile}
            alt={currentUser ? currentUser.fullName : ''}
            fallback={
              <Image
                className={styles.avatarImageStyle}
                src={avatarImage}
                borderRadius="full"
                alt={'avatar'} />
            }
          />
          {getName(name) || t('login_or_register')}
        </Link>
      </div>
    </div>
    <div
      className={styles.topBarPlaceholder}
      style={{ backgroundColor }}
    />
    <SelectLanguageModel
      isOpen={showLanguageSelect}
      options={TARGET_LANGUAGES}
      onConfirm={onLanguageChange}
      onClose={() => setShowLanguageSelect(false)}
      initialValue={currentUser?.targetLanguage}
    />
  </>;
};

export default TopBar;
