import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

export type Category = 'Create Card' | 'Preview Card' | 'Home' | 'Deck' | 'Login' | 'Signup' |
  'Account' | 'Teachers Decks' | 'Discover' | 'Play' | 'Friend' | 'Email';
export type Action = 'Upload image' | 'Playback audio' | 'Flip card' |
  'Save new deck' | 'Submit login' | 'Submit google login' | 'Click for signup page' | 'Submit signup' | 'Submit google signup' | 'Update account' | 'Upload avatar' |
  'Record pronunciation' | 'Upload pronunciation' | 'Playback pronunciation' | 'Search next photo' |
  'Save new card' | 'Next button clicked' | 'Use image recognition' |
  'Use speech recognition' | 'Update deck cover' | 'Playback audio' |
  'View card' | 'Download card' | 'Follow deck' | 'New card button clicked' |
  'Game mode auto' | 'Game mode manual' | 'Completed game' | 'Answer question' | 'Use object detection' | 'Visit blog' |
  'Search friend' | 'Add friend' | 'Referred by weekly update';

/* Initialize analytics */
const analytics = Analytics({
  app: 'cardoze',
  debug: process.env.NODE_ENV === 'development',
  plugins: [
    googleAnalytics({
      trackingId: process.env.REACT_APP_GA_TRACK_ID,
    }),
  ],
});

export const gaTrack = (category: Category, action: Action) => {
  analytics.track(action, { category, label: window.location.hostname });
};

export default analytics;
