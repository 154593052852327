import { Language } from './types';
export const SYSTEM_LANGUAGES: Language[] = ['en', 'zh'];
export const TARGET_LANGUAGES: Language[] = ['en', 'fr', 'zh', 'yue', 'es', 'ja'];
export const SUPPORTED_SPECIAL_IMAGE_TYPES = ['.gif', '.mp4', '.svg', '.ogg', '.webm'];

export const HOME_SECTION_TO_NAME_MAPPING = {
  0: 'recently_added',
  1: 'flashcard_with_motions',
  2: 'for_examination',
  3: 'general',
  4: 'Discover',
} as Record<number, string>;
