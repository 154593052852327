export type Language = 'en' | 'fr' | 'es' | 'zh' | 'yue' | 'ja';
export type SystemLanguage = 'en' | 'zh';
export const SystemLanguageList: SystemLanguage[] = ['en', 'zh'];

export type VocabularyType = 'new' | 'studying' | 'mastered';

export interface StateType {
  referrer: string;
  deckId: string;
  word: string;
  ocrImage: File;
  meanings: Record<Language, string>;
  homeSection: number;
}

export interface ErrorType {
  value: string;
  msg: string;
  param: string;
}

export type GameMode =
  'word_to_meaning' |
  'image_to_word' |
  'word_to_image' |
  'meaning_to_word' |
  'image_to_spelling' |
  'shuffled_letter';

export const GameModes: GameMode[] = [
  'word_to_meaning',
  'image_to_word',
  'word_to_image',
  'meaning_to_word',
  'image_to_spelling',
  'shuffled_letter',
];

export interface Deck {
  id: string;
  nid: string;
  names: Record<SystemLanguage, string>;
  coverImageFile: File | null;
  language: Language;
  cardsCount: number;
  following: boolean;
  createdBy: string;
  isTeacherDeck: boolean | undefined;
  createdByUser: User;
  followerCount: number;
  excludedGameModes: GameMode[];
  isDeleted: boolean;
  userDeckScore: number;
}

export interface File {
  id: string;
  name: string;
}

export interface Card {
  id: string;
  nid: string;
  deckId: string;
  deck: Deck;
  title: string;
  pronunciation: string;
  sentences: string[];
  remark: string;
  imageFile: File | null;
  audioFile: File | null;
  meanings: [Language, string][];
  position: number;
  score: number;
  createdBy: string;
  isDeleted: boolean;
}

export interface RecommendedUser {
  introduction: string;
  website: string;
  keywords: string[];
  languages: Language[];
  title: Record<string, string>;
}

export type FriendStatus = 'isFriend' | 'requestReceived' | 'requestSent' | 'none';

export interface User {
  id: string;
  fullName: string;
  username: string;
  avatarFile: File | null;
  recommendedUser: RecommendedUser | undefined;
  decksCreated?: number;
  cardsCreated?: number;
  friendStatus?: FriendStatus;
  isAdmin: boolean;
}

export interface CurrentUser extends User {
  firstName: string;
  lastName: string;
  targetLanguage: Language;
  email: string;
  membership: string;
  dailyGoal: number;
  systemLanguage: SystemLanguage;
  isNotificationEnabled: boolean;
  learningLanguages: Language[];
}

export interface OcrWordResult {
  text: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  width: number;
  height: number;
}

export interface BlogPost {
  id: string;
  title: string;
  link: string;
  date: string;
  thumbnailLarge: string;
  thumbnailMedium: string;
}
