import { useMemo, useState, useEffect, useContext } from 'react';
import {  CircularProgress, Button } from '@chakra-ui/react';
import JSZip from 'jszip';
import { useLocation,  useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { compressImageToB64 } from '../../utils/compressImage';
import { getCardFieldMapping, getNotes, getMediaMap,
  getDB, getName, getModels, parseNote } from '../../utils/anki';
import styles from './Anki.module.scss';
import axios from '../../utils/axios';
import MappingConfig from './MappingConfig';
import DeckSelect from './DeckSelect';
import { AnkiLoadedData } from './types';
import LoadingSpinner from '../../components/LoadingSpinner';
import TopBar from '../../components/TopBar';
import { Deck } from '../../types';
import { AppContext } from '../../AppContext';
import CardView from '../../components/CardView';
import useToast from '../../hooks/useToast';

const getImageData = async (note: any, f: JSZip) => {
  if (note.image) {
    const imageFile = f.file(note.image.media);
    if (imageFile) {
      const blob = await imageFile.async('blob');
      const tempFile = new File(
        [blob], note.image.name, { type: 'image/*' });
      return await compressImageToB64(tempFile, 0.05);
    }
  }
};

const getAudioFileId = async (note: any, f: JSZip) => {
  if (note.audio) {
    const audioFile = f.file(note.audio.media);
    if (audioFile) {
      const blob = await audioFile.async('blob');
      const tempFile = new File([blob], note.audio.name);
      const formData = new FormData();
      formData.append('files', tempFile);
      const response = await axios.post('/files', formData);
      return response.data.data.file.id;
    }
  }
};

const uploadNoteOnce = async (note: any, f: JSZip, deckId: string) => {
  try {
    const imageData = await getImageData(note, f);
    const audioFileId = await getAudioFileId(note, f);

    await axios.post('/cards', {
      imageData,
      deckId,
      audioFileId,
      sentences: note.sentences,
      pronunciation: note.pronunciation,
      meanings: note.meanings,
      remark: note.remark,
      title: note.title,
      confirmed: true,
    });
  } catch {
    // nothing
  }
};

const saveButtonStyle = {
  width: '266px',
  height: '47px',
  marginLeft: '20px',
};

const backButtonStyle = {
  width: '266px',
  height: '47px',
};

export const Anki: React.FC = () => {
  const [mid, setMid] = useState<string>();
  const [mapping, setMapping] = useState<[string, string[]][] | undefined>();
  const [progress, setProgress] = useState<[number, number]>();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<AnkiLoadedData>();
  const [deck, setDeck] = useState<Deck>();
  const [sampleCard, setSampleCard] = useState<any>();
  const [deckName, setDeckName] = useState('');
  const context = useContext(AppContext);
  const { t } = useTranslation('create_deck_form');
  const [sampleNote, setSampleNote] = useState<any>();
  const toast = useToast();

  useEffect(
    () => {
      const loadData = async () => {
        try {
          const file = (location.state as any).f as File;
          const zip = new JSZip();
          const f = await zip.loadAsync(file);
          const db = await getDB(f);
          const d = await getName(db);
          const models = await getModels(db);
          const mediaMap = await getMediaMap(f);
          setData({
            f, mediaMap, db, models, deckName: d,
          });
          const mids = Object.keys(models);
          if (mids.length >= 1) {
            setMid(mids[0]);
          }
        } catch {
          toast({
            title: t('error'),
            description: t('invalid_anki'),
            status: 'error',
          });
          history.replace('/decks');
        }
      };
      loadData();
    },
    [location.state], // eslint-disable-line
  );

  const gatherSampleCardData = async (card: any, f: JSZip) => {
    if (card.image) {
      const imageFile = f.file(card.image.media);
      if (imageFile) {
        const blob = await imageFile.async('base64');
        card.imageUrl = `data:image/*;base64, ${blob}`;
      }
    }
    if (card.audio) {
      const audioFile = f.file(card.audio.media);
      if (audioFile) {
        const blob = await audioFile.async('base64');
        card.audioUrl = `data:audio/*;base64, ${blob}`;
      }
    }
  };

  useEffect(
    () => {
      if (mid && data) {
        const getMapping = async () => {
          const result = await getCardFieldMapping(data.db, data.models[mid], mid);
          setMapping(result.mapping);
          setSampleNote(result.sample);
        };
        getMapping();
      }
    },
    [mid, data],
  );

  useEffect(
    () => {
      if (mid && mapping && data && sampleNote) {
        const updateCard = async () => {
          const card = parseNote(sampleNote, data.models[mid], mapping, data.mediaMap);
          await gatherSampleCardData(card, data.f);
          setSampleCard(card);
        };
        updateCard();
      }
    },
    [mapping, sampleNote], // eslint-disable-line
  );

  useEffect(
    () => {
      if (data && mid && progress && mapping && deck) {
        const saveNotes = async () => {
          const notes = await getNotes(
            data.mediaMap, data.db, mid, data.models[mid],
            mapping, Math.floor(Math.random() * 4) + 8, progress[0]);
          await Promise.all(notes.map(n => uploadNoteOnce(n, data.f, deck.id)));
          if (progress[0] + notes.length >= progress[1] || notes.length === 0) {
            history.replace(`/deck?id=${deck.nid}`);
            toast({
              title: t('success'),
              description: t('upload_anki_done'),
              status: 'success',
            });
          } else {
            setProgress([progress[0] + notes.length, progress[1]]);
          }
        };
        saveNotes();
      }
    },
    [progress, data, mid, mapping, deck, history, t, toast],
  );

  const onSave = async () => {
    if (mid && data) {
      const response = await axios.post('/decks', { name: deckName });
      setDeck(response.data.data.deck);
      setProgress([0, Math.min(30000, data.models[mid].count)]);
    }
  };

  useEffect(
    () => {
      if (data && mid) {
        if (Object.keys(data.models).length > 1) {
          setDeckName(`${data.deckName} - ${data.models[mid].name}`);
        } else {
          setDeckName(data!.deckName);
        }
      }
    },
    [data, mid],
  );

  const errorMsg = useMemo(
    () => {
      if (!deckName) {
        return t('deck_name_cannot_be_empty');
      }

      if (!mapping) {
        return;
      }
      const titleCount = mapping.filter(x => x[1].indexOf('title') >= 0).length;
      if (titleCount === 0) {
        return t('title_cannot_be_empty');
      }
      const imageCount = mapping.filter(x => x[1].indexOf('image') >= 0).length;
      if (imageCount > 1) {
        return t('image_cannot_more_than_one');
      }
      const audioCount = mapping.filter(x => x[1].indexOf('audio') >= 0).length;
      if (audioCount > 1) {
        return t('audio_cannot_more_than_one');
      }
    },
    [deckName, t, mapping],
  );

  return <div className={styles.outer}>
    <TopBar showBackButton={true} />
      <div className={styles.ankiConfig}>
        <LoadingSpinner spin={!data || !mid || !mapping}>
          { data && mid && mapping && (
            progress ? (<div className={styles.progress}>
              <CircularProgress size="100px" value={progress[0] / progress[1] * 100} />
              <div className={styles.text}>
                {t('uploading')} {progress[0]} / {progress[1]} ...
              </div>
            </div>) : (<div>
              <DeckSelect models={data!.models} onChange={x => setMid(x)} mid={mid} />
              { sampleCard && (
                <div className={styles.card}>
                  <CardView
                    word={sampleCard.title}
                    pronunciation={sampleCard.pronunciation}
                    meaning={sampleCard.meanings[0][1]}
                    language="en"
                    audio={sampleCard.audioUrl}
                    imageUrl={sampleCard.imageUrl}
                  />
                </div>
              )}
              <MappingConfig
                mapping={mapping!}
                count={data!.models[mid!].count}
                deckName={deckName}
                onDeckNameChange={x => setDeckName(x)}
                onMappingChange={m => setMapping(m)}
              />
              <h4 className={styles.errorMsg}>{errorMsg}</h4>
              <div className={styles.buttons}>
                <Button
                  onClick={() => context.goBack()}
                  {...backButtonStyle}
                  variant="red">{t('back')}</Button>
                <Button
                  {...saveButtonStyle}
                  onClick={onSave}
                  disabled={!!errorMsg}>{t('save')}</Button>
              </div>
            </div>)
          )}
        </LoadingSpinner>
      </div>
  </div>;
};

export default Anki;
