import { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Card } from '../../types';
import styles from './VocabularyList.module.scss';
import { AppContext } from '../../AppContext';
import CardDisplayRow from './CardDisplayRow';

interface VocabularyListInterface {
  cards: Card[];
  listRef?: React.RefObject<HTMLDivElement>;
}

const VocabularyList: React.FC<VocabularyListInterface> = ({ cards, listRef }) => {
  const { t } = useTranslation('revision');
  const context = useContext(AppContext);
  return <div className={classnames(
      styles.revision, { [styles.zh]: context.currentUser?.systemLanguage === 'zh' })}
        ref={listRef}>
    <div className={classnames(styles.row, styles.header)}>
      <div className={styles.statusCol}>{t('status')}</div>
      <div className={styles.wordCol}>{t('word')}</div>
      <div className={styles.meaningCol}>{t('meaning')}</div>
      <div className={styles.buttonCol}></div>
    </div>
    { cards.map(card => (
      <CardDisplayRow key={card.id} card={card} disable={ card.isDeleted || card.deck.isDeleted }/>
    ))}
  </div>;
};

export default VocabularyList;
