import { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import {
  Link, Switch, Route, useHistory, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '@chakra-ui/react';
import shortNumber from 'short-number';
import classnames from 'classnames';
import { decodeHTML } from 'entities';

import TopBar from '../../components/TopBar';
import styles from './Home.module.scss';
import { AppContext } from '../../AppContext';
import { BlogPost, Card, Deck } from '../../types';
import Discover from './Discover';
import TeachersDecks from './TeachersDecks';
import TeachersDecksV2 from './TeachersDecksV2';
import Revision from './Revision';
import Welcome from './Welcome';
import analytics, { gaTrack } from '../../services/analytics';
import Tutorial from './Tutorial';
import axios from '../../utils/axios';
import SubMenu from '../../components/SubMenu';
import variables from '../../variables.module.scss';
import createFlashcardIcon from '../../images/create-flashcard-lightblue.svg';
import useToast from '../../hooks/useToast';
import Blog from './Blog';
import teacherDeckIcon from '../../images/teacher-decks-flag.svg';

const createNewCardButtonStyles = {
  background: '#0FD4CB',
  fontFamily: variables.normalFontFamily,
  boxShadow: '3px 3px 3px 0px #d3d3d3',
};

interface HomeParams {
  submenu: string;
}

const Home = () => {
  const context = useContext(AppContext);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation('home');
  const { t: teacherDeckT } = useTranslation('home_section');

  const [discoverCards, setDiscoverCards] = useState<Card[]>([]);

  const [recentDecks, setRecentDecks] = useState<Deck[]>([]);
  const [motionDecks, setMotionDecks] = useState<Deck[]>([]);
  const [examDecks, setExamDecks] = useState<Deck[]>([]);
  const [generalDecks, setGeneralDecks] = useState<Deck[]>([]);

  const [revisionCards, setRevisionCards] = useState<Card[]>([]);
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const history = useHistory();
  const { submenu: homeParams } = useParams<HomeParams>();
  const [homeSection, setHomeSection] = useState(homeParams === 'revision' ? 1
    : homeParams === 'blog' ? 2 : 0);
  const toast = useToast();
  const [progressCounts, setProgressCounts] = useState({
    newWord: 0,
    studying: 0,
    acquired: 0,
  });

  const showWelcome = useMemo(
    () => !context.currentUser,
    [context.currentUser],
  );

  const getHomeData = useCallback(
    async () => {
      const { data: { data: homeData } } = await axios.get('/home');
      setProgressCounts(homeData.progressCounts);
      setDiscoverCards(homeData.discoverCards);
      setRecentDecks(homeData.officialDecks.filter((x: any) => x.homeSection === 0));
      setMotionDecks(homeData.officialDecks.filter((x: any) => x.homeSection === 1));
      setExamDecks(homeData.officialDecks.filter((x: any) => x.homeSection === 2));
      setGeneralDecks(homeData.officialDecks.filter((x: any) => x.homeSection === 3));
    },
    [],
  );

  const getRevisionCards = useCallback(
    async () => {
      if (context.hasToken) {
        const response = await axios.get('/revision/cards', { params: {
          limit: 5,
        } });

        const { data: { data } } = response;
        setRevisionCards(data.cards);
      }
    },
    [context.hasToken],
  );

  const getBlogPosts = useCallback(
    async () => {
      const options = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      const allPosts: BlogPost[] = [];
      const postMax = 3;

      try {
        const posts = await axios.get(
          '/posts',
          {
            baseURL: 'https://blog.cardozeapp.com/wp-json/wp/v2',
            params: { per_page: 10, _embed: true, _field: ['id', 'link', 'title', 'date', '_links', 'status', 'content'] },
          },
        );

        for (const singlePost of posts.data) {
          if (allPosts.length < postMax) {
            if (singlePost.status === 'publish' && singlePost.content.protected !== true) {
              allPosts.push({
                id: singlePost.id,
                title: decodeHTML(singlePost.title.rendered),
                link: singlePost.link,
                date: options.format(new Date(singlePost.date)),
                thumbnailLarge: singlePost._embedded['wp:featuredmedia'][0]
                  .media_details.sizes.large.source_url,
                thumbnailMedium: singlePost._embedded['wp:featuredmedia'][0]
                  .media_details.sizes.medium_large.source_url,
              });
            }
          }
        }

        setBlogPosts(allPosts);
      } catch {
        // do nothing
      }
    },
    [],
  );

  useEffect(
    () => {
      analytics.page();
    },
    [],
  );

  useEffect(
    () => {
      context.setCurrentTab('home');
      const fetchData = async () => {
        await Promise.all([
          getHomeData(),
          getRevisionCards(),
          getBlogPosts(),
        ]);

        setInitialized(true);
      };
      fetchData();
    },
    [context.currentUser?.targetLanguage], // eslint-disable-line
  );

  const onTeacherDeckFollow = () => {
    getHomeData();
  };

  const onCloseTutorial = async () => {
    history.replace({
      pathname: '/',
    });

    getHomeData();
  };

  const onListCheck = (listSize: number) => {
    if (listSize === 0) {
      toast({
        title: t('vocabulary'),
        description: t('empty_list'),
        status: 'info',
      });
    }
  };

  const onHomeSubMenuClicked = (page: number) => {
    if (!showWelcome) {
      if (page === 0) {
        history.replace({
          pathname: '/home/learning',
        });
        setHomeSection(0);
      } else if (page === 1) {
        history.replace({
          pathname: '/home/revision',
        });
        setHomeSection(1);
      } else if (page === 2) {
        history.replace({
          pathname: '/home/blog',
        });
        setHomeSection(2);
      }
    }
  };

  const subMenu = !showWelcome ? <div className={styles.subMenuWrapper}>
    <SubMenu
      onPageClick={onHomeSubMenuClicked}
      menu={[t('learn'), t('revision'), t('blog')]}
      currentIndex={homeSection}
      arrowColor="blue"
    />
    </div> : <></>;

  const createFlashcardImage = <>
      <img className={styles.flashcardImage} src={createFlashcardIcon} alt="create flashcard"></img>
    </>;

  return (
    <div className={classnames(styles.outer, { [styles.zh]: context.currentUser?.systemLanguage === 'zh' })}>
      <TopBar />
      <div>
        { initialized ? (
        <div className={styles.inner}>
          { showWelcome && (
            <>
              <Welcome />
              <Button
                as={Link}
                to="/signup"
                onClick={ () => gaTrack('Signup', 'Click for signup page') }
                width="228px"
                maxWidth="100%"
                height="47px"
                display="flex"
                margin="46px auto 120px"
              >
                {t('signup')}
              </Button>
            </>
          )}
          { !showWelcome && (
            <>
              {subMenu}
              { homeSection === 1 &&
                <div className={styles.widthWrapper}>
                  <div className={styles.title}>{t('vocabulary')}</div>
                  <div className={styles.progressCounts}>
                    <div className={styles.progressCount}>
                      <Link onClick={() => onListCheck(progressCounts.newWord)}
                        to={progressCounts.newWord !== 0 ? '/vocabulary/new' : '#'}>
                        <div className={styles.progressCountNewWord}>
                          {shortNumber(progressCounts.newWord)}
                        </div>
                      </Link>
                      <div className={styles.progressCountTitle}>{t('new')}</div>
                    </div>
                    <div className={styles.progressCount}>
                      <Link onClick={() => onListCheck(progressCounts.studying)}
                        to={progressCounts.studying !== 0 ? '/vocabulary/studying' : '#'}>
                        <div className={styles.progressCountStudying}>
                          {shortNumber(progressCounts.studying)}
                        </div>
                      </Link>
                      <div className={styles.progressCountTitle}>{t('studying')}</div>
                    </div>
                    <div className={styles.progressCount}>
                      <Link onClick={() => onListCheck(progressCounts.acquired)}
                        to={progressCounts.acquired !== 0 ? '/vocabulary/mastered' : '#'}>
                        <div className={styles.progressCountAcquired}>
                          {shortNumber(progressCounts.acquired)}
                        </div>
                      </Link>
                      <div className={styles.progressCountTitle}>{t('acquired')}</div>
                    </div>
                  </div>
                  <div className={styles.createFlashcardButtonWrapper}>
                    <Button
                      {...createNewCardButtonStyles}
                      leftIcon={createFlashcardImage}
                      as={Link}
                      to="/card/new"
                      width="215px"
                      maxWidth="100%"
                      height="43px"
                      display="flex"
                      onClick={ () => {
                        gaTrack('Home', 'New card button clicked');
                      }}
                    >
                      {t('create_new_card')}
                    </Button>
                  </div>
                  { revisionCards.length > 0 && (
                    <>
                      <div className={styles.seperator} />
                      <div className={classnames(styles.title, styles.titleRight)}>
                        {t('study_more')}</div>
                      <Revision cards={revisionCards} />
                    </>
                  )}
                </div>
              }
              { homeSection === 2 &&
                <div className={styles.widthWrapper}>
                  <div className={styles.title}>{t('blog')}
                    <a href="https://blog.cardozeapp.com" rel="noreferrer" target="_blank"
                      className={styles.more}>{t('more')}</a>
                  </div>
                  <Blog posts={blogPosts} />
                </div>
              }
            </>
          )}
          { homeSection === 0 &&
            <div className={styles.widthWrapper}>
              {discoverCards.length > 0 && (
                <>
                  <div className={styles.title}>{t('discover')}</div>
                  <Discover cards={discoverCards} />
                  <div className={styles.seperator} />
                </>
              )}
              { (recentDecks.length > 0 || motionDecks.length > 0 ||
                examDecks.length > 0 || generalDecks.length > 0) && (
                <>
                  <div className={styles.title}>{t('teacher_decks')}</div>
                  { recentDecks.length > 0 && (
                    <>
                      <div className={styles.teacherDecksTitle}>
                        <img src={teacherDeckIcon} alt="" />
                        {teacherDeckT('recently_added')}</div>
                      <TeachersDecksV2 decks={recentDecks} onFollow={onTeacherDeckFollow} />
                    </>
                  )}
                  { motionDecks.length > 0 && (
                    <>
                      <div className={styles.teacherDecksTitle}>
                        <img src={teacherDeckIcon} alt="" />
                        {teacherDeckT('flashcard_with_motions')}</div>
                      <TeachersDecks decks={motionDecks} onFollow={onTeacherDeckFollow} />
                    </>
                  )}
                  { examDecks.length > 0 && (
                    <>
                      <div className={styles.teacherDecksTitle}>
                        <img src={teacherDeckIcon} alt="" />
                        {teacherDeckT('for_examination')}</div>
                      <TeachersDecksV2 decks={examDecks} onFollow={onTeacherDeckFollow} />
                    </>
                  )}
                  { generalDecks.length > 0 && (
                    <>
                      <div className={styles.teacherDecksTitle}>
                        <img src={teacherDeckIcon} alt="" />
                        {teacherDeckT('general')}</div>
                      <TeachersDecks decks={generalDecks} onFollow={onTeacherDeckFollow} />
                    </>
                  )}
                </>
              )}
            </div>
          }
          <Switch>
            <Route path="/tutorial">
              <Tutorial onClose={onCloseTutorial} />
            </Route>
          </Switch>
        </div>
        ) : (
          <div className={styles.inner}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              margin="0 auto"
              display="block"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
