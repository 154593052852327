import { useEffect, useState, useRef, useCallback, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, IconButton } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import { User, Deck } from '../../types';
import styles from './FriendsTab.module.scss';
import useToast from '../../hooks/useToast';
import useThrottle from '../../hooks/useThrottle';
import FriendsTabRow from './FriendsTabRow';
import addIcon from '../../images/friends/plus.svg';
import crossIcon from '../../images/friends/cross.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import { gaTrack } from '../../services/analytics';
import RecommendedUsersList from './RecommendedUsersList';
import DeckBox from '../../components/DeckBox';

const actionButtonStyle = {
  width: '34px',
  height: '34px',
  _hover: {
    filter: 'brightness(90%)',
  },
};

interface FriendsInterface {
  friends: User[];
  loading: boolean;
}

const searchStyle = {
  width: {
    base: '100%',
    sm: '308px',
  },
  height: '49px',
  background: '#FFFFFF',
  border: '1px solid #F79411 !important',
  borderRadius: '4px',
  _focus: {
    border: '1px solid #F79411 !important',
  },
  color: '#F79411',
  fontSize: '16px',
  fontWeight: 600,
  _placeholder: {
    color: '#FFD59E',
    fontWeight: 'normal',
  },
};

const Friends: React.FC<FriendsInterface> = ({ friends, loading }) => {
  const { t } = useTranslation('friends');

  return <div>
    <div className={styles.title}>
      <h4>{ t('added_n', { n: friends.length })}</h4>
    </div>
    <LoadingSpinner spin={loading}>
      {friends.map(user => (
        <FriendsTabRow key={user.id} user={user} />
      ))}
      {(friends.length === 0) && <p className={styles.empty}>{t('no_friend')}</p>}
    </LoadingSpinner>
  </div>;
};

interface SearchResultRowInterface {
  user: User;
  onUpdate: () => void;
}

const SearchResultRow: React.FC<SearchResultRowInterface> = ({ user, onUpdate }) => {
  const toast = useToast();
  const { t } = useTranslation('friends');

  const onAddFriend = async () => {
    try {
      await axios.post(
        '/friendRequests/', { userId: user.id });
    } catch (e) {
      toast({
        title: t('error'),
        description: e.response ?  e.response.data.data.message : t('error_desc'),
        status: 'error',
      });
    }
    onUpdate();
  };

  return <FriendsTabRow
    user={user}
    actionButtons={<>
      <IconButton
        {...actionButtonStyle}
        onClick={onAddFriend}
        aria-label="cover"
        isRound={true}
        variant="none"
        icon={<img alt="add friend" src={addIcon} />}
      />
    </>}
  />;
};

interface SearchResultInterface {
  users: User[];
  onUpdate: () => void;
  loading: boolean;
}

const SearchResult: React.FC<SearchResultInterface> = ({ users, onUpdate, loading }) => {
  const { t } = useTranslation('friends');

  return <div>
    <h4 className={styles.title}>
      { t('results_n', { n: users.length })}
    </h4>
    <LoadingSpinner spin={loading}>
      {users.map(user => (
        <SearchResultRow key={user.id} user={user} onUpdate={onUpdate} />
      ))}
      {(users.length === 0) && <p className={styles.empty}>{t('no_search_result')}</p>}
    </LoadingSpinner>
  </div>;
};

interface FriendsTabInterface {
  onAddFriend: () => void;
}

const FriendsTab: React.FC<FriendsTabInterface> = ({ onAddFriend }) => {
  const { t } = useTranslation('friends');
  const context = useContext(AppContext);

  const [friends, setFriends] = useState<User[]>([]);
  const [recommendedUsers, setRecommendedUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [friendDecks, setFriendDecks] = useState<Deck[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const onSearchChangeThrottled = useThrottle(async (q: string) => {
    if (q.length >= 2) {
      const response = await axios.get('/users/search', { params: { q } });
      setIsSearchLoading(false);
      setSearchResult(response.data.data.users);
    } else {
      setIsSearchLoading(false);
      setSearchResult([]);
    }
  });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsSearchLoading(true);
    onSearchChangeThrottled(e.target.value);
  };

  const getFriends = async () => {
    const response = await axios.get('/friends');
    setFriends(response.data.data.users);
    setLoading(false);
  };

  const getRecommendedUsers = async () => {
    const response = await axios.get('/users/recommended');
    setRecommendedUsers(response.data.data.users);
  };

  const getFriendDecks = async () => {
    const response = await axios.get('/decks', { params: { type: 'friends' } });
    setFriendDecks(response.data.data.decks);
  };

  const onUpdate = useCallback(
    () => {
      getFriends();
      getRecommendedUsers();
      getFriendDecks();
    },
    [],
  );

  useEffect(
    () => {
      onUpdate();
    },
    [onUpdate, context.currentUser?.targetLanguage],
  );

  const onSearchButtonClick = () => {
    setSearchQuery('');
    setSearchResult([]);
    setShowSearch(v => !v);
    gaTrack('Friend', 'Search friend');
  };

  const onSearchAddFriend = () => {
    setShowSearch(false);
    setSearchQuery('');
    onUpdate();
    onAddFriend();
    gaTrack('Friend', 'Add friend');
  };

  useEffect(
    () => {
      if (showSearch) {
        searchInputRef.current?.focus();
      }
    },
    [showSearch],
  );

  const onAddUser = (userId: string) => {
    setRecommendedUsers(users => users.map(u =>
      u.id === userId ? {
        ...u, friendStatus: 'isFriend',
      } : u,
    ));
    getFriends();
    getFriendDecks();
  };

  const onDeckFollowed = () => {
    getFriendDecks();
  };

  return <div className={styles.friendsTab}>
    <div className={styles.searchWrapper}>
      <Input
        {...searchStyle}
        hidden={!showSearch}
        value={searchQuery}
        ref={searchInputRef}
        placeholder={t('enter_name')}
        onChange={onSearchChange}
      />
      <Button
        variant="none"
        onClick={onSearchButtonClick}
      >
        <img alt="add" src={showSearch ? crossIcon : addIcon} />
        {!showSearch && <span className={styles.showSearchButton}>{t('add_a_friend')}</span>}
      </Button>
    </div>
    { !showSearch && (
      <>
        { recommendedUsers.length > 0 && (
          <>
            <div className={styles.title}>
              <h4>{ t('recommended_n', { n: recommendedUsers.length })}</h4>
            </div>
            <RecommendedUsersList
              users={recommendedUsers}
              onAddUser={onAddUser}
            />
          </>
        )}

        { friendDecks.length > 0 && (
          <>
            <div className={styles.title}>
              <h4>{ t('friends_decks_n', { n: friendDecks.length })}</h4>
            </div>
            <Swiper
              slidesPerView="auto"
              spaceBetween={30}
              className={styles.decks}
            >
              {friendDecks.map(deck => (
                <SwiperSlide className={styles.slide} key={deck.id}>
                  <DeckBox
                    deck={deck}
                    key={deck.id}
                    onDeckUpdated={onDeckFollowed}
                    type="home"
                    showAvatar={true}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}

        <Friends
          friends={friends}
          loading={loading}
        />
      </>
    )}
    { showSearch && (
      <SearchResult
        users={searchResult}
        onUpdate={onSearchAddFriend}
        loading={isSearchLoading}
      />
    )}
  </div>
  ;
};

export default FriendsTab;
