import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../AppContext';

interface PublicRouteInterface {
  path: string | string[];
  exact?: boolean;
}

const PublicRoute: React.FC<PublicRouteInterface> = ({ children, ...props }) => {
  const context = useContext(AppContext);

  if (!context.hasToken) {
    return (
      <Route {...props} >
        {children}
      </Route>
    );
  }

  return <Redirect to="/" />;
};

export default PublicRoute;
