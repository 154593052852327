import { useState, useContext, useMemo,
  useCallback, useEffect, FormEvent } from 'react';
import { Button, Input, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocation } from 'react-router-dom';

import styles from './CardForm.module.scss';
import TopBar from '../../components/TopBar';
import axios from '../../utils/axios';
import { File as FileType, StateType, Language } from '../../types';
import useInput from '../../hooks/useInput';
import CardView from '../../components/CardView';
import { AppContext } from '../../AppContext';
import getMeaning from '../../utils/getMeaning';
import { gaTrack } from '../../services/analytics';
import AudioInput from './AudioInput';
import DeleteDialog from './DeleteDialog';
import variables from '../../variables.module.scss';
import useToast from '../../hooks/useToast';
import getDeckName from '../../utils/getDeckName';
import ConfirmSaveDuplicateDialog from './ConfirmSaveDuplicateDialog';
import Checkbox from '../../components/Checkbox';
import NavPrompt from '../../components/NavPrompt';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import useMeanings from './useMeanings';
import useDecks from './useDecks';
import useSentences from './useSentences';
import useStockImages from './useStockImages';
import useImageInput from './useImageInput';
import useStore from '../../hooks/useStore';
import useImageData from './useImageData';
import useThrottle from '../../hooks/useThrottle';

const pixabayDomain = 'https://pixabay.com';

const inputStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 500,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
};

const saveButtonStyle = {
  width: '266px',
  height: '47px',
  marginRight: '20px',
};

const deleteButtonStyle = {
  width: '266px',
  height: '47px',
};

const CardForm = () => {
  const context = useContext(AppContext);
  const location = useLocation<StateType>();
  const toast = useToast();
  const { t } = useTranslation('card_form');
  const { setStores } = useStore();

  // immutable states
  const ocrImage: FileType | undefined = location.state?.ocrImage;
  const [fromCardId] = useQueryParam('fromCardId', StringParam);
  const systemLanguage = useMemo(
    () => context.currentUser?.systemLanguage || 'en',
    [context.currentUser?.systemLanguage],
  );
  const [cardId] = useQueryParam('id', StringParam);

  // card attributes
  const { setDeckId, deckId, component: decksComponent, currentDeck } = useDecks();
  const [word, onWordChange, setWord] = useInput(location.state?.word || '');
  const [pronunciation, onPronunciationChange, setPronunciation] = useInput('');
  const [remark, onRemarksChange, setRemark] = useInput('');
  const { meanings, setMeanings, component: meaningsComponent } = useMeanings(systemLanguage);
  const { sentences, setSentences, component: sentencesComponent } = useSentences();
  const [audio, setAudio] = useState<FileType>();

  // UI states
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [shouldConfirmLeave, setShouldConfirmLeave] = useStateWithCallback<boolean>(true);
  const [duplicatedCardId, setDuplicatedCardId] = useState<string>();

  // computed states
  const currentSearch = useMemo(
    () => {
      return getMeaning(meanings, 'en') || word;
    },
    [meanings, word],
  );
  const language = useMemo(
    () => {
      return currentDeck?.language || context.currentUser?.targetLanguage || 'en';
    },
    [currentDeck, context.currentUser],
  );

  // images
  const {
    setImageUrl, getImageBody, setImageDataByFileRecord,
    setImageDataByCanvasFile, setImageDataByDataUrl, imageUrl,
  } = useImageData();
  const { fetchStockImages, onNextPhotoClick,
    onImageCheckboxClicked, resetStockImageUrlList } = useStockImages(
      word, currentSearch, imageUrl, setImageUrl, setLoading);

  useEffect(
    () => {
      context.setShowTabBar(false);
      return () => {
        context.setShowTabBar(true);
      };
    },
    [], // eslint-disable-line
  );

  const onImageLoaded = (canvasFile: File) => {
    setImageDataByCanvasFile(canvasFile);
  };

  const fetchPronunciation = useCallback(
    async () => {
      const resIpa = await axios.get('/ipa', { params: { word } });
      if (resIpa.data.data.ipa) {
        setPronunciation(resIpa.data.data.ipa);
      }
    },
    [word, setPronunciation],
  );

  const fetchAudio = useCallback(
    async () => {
      try {
        const resAudio = await axios.post('/textToSpeech', { word });
        if (resAudio.data.data.file) {
          setAudio(resAudio.data.data.file);
        }
      } catch {
        // do nothing
      }
    },
    [word],
  );

  const fetchTranslation = useMemo(
    () => async (
      predefined: any | undefined = undefined,
      shouldFetchStockImages: boolean = true,
    ) => {
      let translated = predefined;
      if (!translated) {
        translated = (await axios.get(
          '/dict',
          { params: { word } },
        )).data.data;
      }

      if (shouldFetchStockImages) {
        fetchStockImages(translated.en || word, true);
      }
      setMeanings(Object.entries(translated) as [Language, string][]);
    },
    [fetchStockImages, setMeanings, word],
  );

  const getCard = useCallback(
    async () => {
      setLoading(true);
      if (cardId !== 'new' || fromCardId) {
        const response = await axios.get(`/cards/${cardId !== 'new' ? cardId : fromCardId}`);
        const card = response.data.data.card;
        setWord(card.title);
        if (cardId !== 'new') {
          setDeckId(card.deckId);
        }
        setRemark(card.remark);
        setSentences(card.sentences);
        setMeanings(card.meanings);
        setImageDataByFileRecord(card.imageFile);
        setAudio(card.audioFile);
        setPronunciation(card.pronunciation);

      } else if (word) {
        fetchPronunciation();

        if (ocrImage !== undefined) {
          setImageDataByFileRecord(ocrImage);
        }

        fetchTranslation(location.state?.meanings, ocrImage === undefined);
        fetchAudio();
      }
      setLoading(false);
    },
    [cardId, setWord, setDeckId, setRemark, setPronunciation, fromCardId, setImageDataByFileRecord,
      setSentences, setMeanings, setAudio, word, ocrImage, location.state,
      fetchPronunciation, fetchAudio, fetchTranslation],
  );

  const generateCardDataThrottled = useThrottle(
    () => {
      if (word) {
        setImageUrl(undefined);
        resetStockImageUrlList();
        fetchPronunciation();
        fetchAudio();
        fetchTranslation();
      }
    },
    2000,
  );

  const onImageFileSelected = (
    url: string, fileType: 'image' | 'video', fileExt: string, fileSize: number) => {
    resetStockImageUrlList();
    setImageDataByDataUrl(url, fileType, fileExt, fileSize);
  };

  const { clickImageInput, component: imageInputComponent } = useImageInput(onImageFileSelected);

  useEffect(
    () => {
      if (!initialized) {
        setInitialized(true);
        getCard();
      }
    },
    [getCard, initialized, setInitialized],
  );

  const onSave = useCallback(
    async () => {
      setSaving(true);
      setDuplicatedCardId(undefined);

      const imageBody = await getImageBody();

      try {
        if (cardId !== 'new') {
          await axios.put(`/cards/${cardId}`, {
            ...imageBody,
            sentences,
            pronunciation,
            meanings,
            deckId,
            remark,
            audioFileId: audio ? audio.id : null,
            title: word,
          });
        } else {
          await axios.post('/cards', {
            ...imageBody,
            sentences,
            pronunciation,
            meanings,
            deckId,
            remark,
            audioFileId: audio ? audio.id : null,
            title: word,
            confirmed: !!duplicatedCardId,
          });
        }

        toast({
          title: t('card_saved'),
          description: t('card_saved_desc'),
          status: 'success',
        });

        setStores({ createCardLastDeckId: deckId });

        setSaving(false);
        gaTrack('Create Card', 'Save new card');

        setShouldConfirmLeave(false, () => context.goBack());

      } catch (err) {
        setSaving(false);
        const errors = err.response?.data?.data?.errors;
        if (errors && errors.length > 0 && errors[0].msg === 'duplicated') {
          setDuplicatedCardId(errors[0].value);
        } else {
          toast({
            title: t('error'),
            description: t('try_again'),
            status: 'error',
          });
        }
      }
    },
    [sentences, pronunciation, t, toast, cardId, context,
      meanings, deckId, remark, audio, word, duplicatedCardId,
      setShouldConfirmLeave, setStores, getImageBody,
    ],
  );

  const onDelete = () => {
    setIsDeleteDialogOpened(true);
  };

  const onDeleteDialogClose = (deleted: boolean) => {
    setIsDeleteDialogOpened(false);
    if (deleted) {
      setShouldConfirmLeave(false, () => context.goBack());
    }
  };

  const onCloseConfirmSaveDuplicateDialog = (confirmed: boolean) => {
    if (confirmed) {
      onSave();
    }

    setDuplicatedCardId(undefined);
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <div className={styles.outer}>
      <TopBar showBackButton={true} />
      <form className={styles.cardForm} onSubmit={onFormSubmit}>
        <div className={styles.cardPreview}>
          <CardView
            imageUrl={imageUrl}
            onUploadClicked={clickImageInput}
            onImageLoaded={onImageLoaded}
            word={word}
            pronunciation={pronunciation}
            audio={audio}
            meaning={getMeaning(meanings, systemLanguage, true)}
            onNextPhotoClick={onNextPhotoClick}
            spin={loading}
            setSpin={setLoading}
            language={language}
          />
          <div className={styles.imageCheckbox}>
            <span>{t('use_image')}</span>
            <Checkbox
              checked={!!imageUrl}
              onClick={onImageCheckboxClicked}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          {decksComponent}
          <div className={styles.inputRowSpacer} />
          <div className={styles.inputGroup}>
            <div className={styles.inputTitle}>{t('word')}</div>
            <Input
              value={word}
              onChange={(e) => {
                onWordChange(e);
                generateCardDataThrottled();
              }}
              maxLength={60}
              type="text"
              autoCapitalize="none"
              {...inputStyle}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          {meaningsComponent}
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <div className={styles.inputTitle}>{t('pronunciation')}</div>
            <Input
              {...inputStyle}
              value={pronunciation}
              onChange={onPronunciationChange}
              autoCapitalize="none"
              type="text"
              maxLength={50}
            />
          </div>
          <div className={styles.inputRowSpacer} />
          <AudioInput audio={audio} setAudio={setAudio} />
        </div>
        <div className={styles.inputRow}>
          {sentencesComponent}
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <div className={styles.inputTitle}>{t('remarks')}</div>
            <Textarea
              type="text"
              autoCapitalize="sentences"
              value={remark}
              onChange={onRemarksChange}
              {...inputStyle}
              height="133px"
            />
          </div>
        </div>
        { imageUrl?.startsWith(pixabayDomain) &&
          <div className={styles.credit}>
            <a href={pixabayDomain} target="_blank" rel="noreferrer">{t('image_source')}</a>
          </div>
        }
        <div className={styles.buttons}>
          <Button
            {...saveButtonStyle}
            type="submit"
            className={styles.save}
            isLoading={saving}
          >
            {t('save')}
          </Button>
          <Button {...deleteButtonStyle} variant="red" onClick={onDelete}>
            {t('delete')}
          </Button>
        </div>
      </form>
      { cardId && (
        <DeleteDialog
          isOpen={isDeleteDialogOpened}
          onClose={onDeleteDialogClose}
          cardId={cardId}
        />
      )}
      <ConfirmSaveDuplicateDialog
        onClose={onCloseConfirmSaveDuplicateDialog}
        cardId={duplicatedCardId}
        deckName={currentDeck ? getDeckName(currentDeck, context.currentUser) : ''}
        deckId={deckId}
        word={word}
      />
      <NavPrompt enabled={shouldConfirmLeave} />
      {imageInputComponent}
    </div>
  );
};

export default CardForm;
