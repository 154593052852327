import { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Button, NumberInput, NumberInputField,
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import TopBar from '../../components/TopBar';
import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import useInput from '../../hooks/useInput';
import { Deck } from '../../types';
import styles from './PlayIndex.module.scss';
import playIndexImage from '../../images/play-index-image.svg';
import autoIcon from '../../images/auto-icon.svg';
import manualIcon from '../../images/manual-icon.svg';
import analytics, { gaTrack } from '../../services/analytics';
import variables from '../../variables.module.scss';
import getDeckName from '../../utils/getDeckName';

const okButtonStyle = {
  width: '266px',
  height: '47px',
  margin: '40px auto',
};

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#2D65C2',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
  background: 'white',
};

const inputStyle = {
  borderColor: '#67D29E90',
  _hover: {
    borderColor: '#67D29E',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#67D29E',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#F79411',
  padding: '20px',
  height: '50px',
  borderRadius: '4px',
  background: 'white',
};

const PlayIndex = () => {
  const context = useContext(AppContext);
  const [limit, onLimitChange] = useInput(20, (value: any) => value);
  const [decks, setDecks] = useState<Deck[]>([]);
  const [deckId, onDeckIdChange, setDeckId] = useInput('');
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation('play_index');
  const [mode, setMode] = useState<'auto' | 'manual' | ''>('');
  const history = useHistory();

  const getDecks = useCallback(
    async () => {
      const response = await axios.get('/decks', { params: { type: 'all' } });
      setDecks(response.data.data.decks);
      setDeckId(response.data.data.decks[0].id);
    },
    [setDeckId, setDecks],
  );

  useEffect(
    () => {
      analytics.page();
    },
    [],
  );

  useEffect(
    () => {
      if (!initialized) {
        getDecks();
        context.setCurrentTab('play');
        setInitialized(true);
      }
    },
    [context, initialized, getDecks, setInitialized],
  );

  useEffect(
    () => {
      context.setFooterColor('#D7EFEE');
      return () => {
        context.setFooterColor('');
      };
    },
    [], // eslint-disable-line
  );

  const onConfirm = useCallback(
    () => {
      if (mode === 'manual') {
        gaTrack('Play', 'Game mode manual');
        history.push(`/play/${deckId}?limit=20`);
      } else if (mode === 'auto') {
        gaTrack('Play', 'Game mode auto');
        history.push(`/play/auto?limit=${limit}`);
      }
    },
    [history, limit, mode, deckId],
  );

  useEffect(
    () => {
      const eventListener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onConfirm();
        }
      };

      window.addEventListener('keydown', eventListener);
      return () => {
        window.removeEventListener('keydown', eventListener);
      };
    },
    [onConfirm],
  );

  return (
    <div className={styles.playIndexOuter}>
      <TopBar />
        <div className={styles.playIndex}>
          <div className={styles.title}>{t('select_mode')}</div>
          <img className={styles.image} src={playIndexImage} alt="title" />
          <button
            className={classnames(
              styles.button, styles.auto,
              { [styles.active]: mode === 'auto', [styles.inactive]: mode === 'manual' },
            )}
            onClick={() => setMode('auto')}
          >
            <img src={autoIcon} className={styles.icon} alt="auto" />
            <div className={styles.nameMode}>
              <span className={styles.name}>{t('auto')}</span>
              <span className={styles.mode}>{t('mode')}</span>
            </div>
            <div className={styles.desc}>{t('auto_desc')}</div>
          </button >
          <button
            className={classnames(
              styles.button, styles.manual,
              { [styles.active]: mode === 'manual', [styles.inactive]: mode === 'auto' },
            )}
            onClick={() => setMode('manual')}
          >
            <img src={manualIcon} className={styles.icon} alt="manual" />
            <div className={styles.nameMode}>
              <span className={styles.name}>{t('manual')}</span>
              <span className={styles.mode}>{t('mode')}</span>
            </div>
            <div className={styles.desc}>{t('manual_desc')}</div>
          </button>
          { mode === 'manual' && (
            <div className={styles.autoModeWrapper}>
              <div className={styles.autoMode}>{t('manual_mode')}</div>
              <div className={styles.whichDeck}>{t('which_deck')}</div>
              <Select
                value={deckId}
                onChange={onDeckIdChange}
                textAlign="center"
                {...selectStyle}
              >
                {decks.map(deck => (
                  <option key={deck.id} value={deck.id}>
                    {getDeckName(deck, context.currentUser)}
                  </option>
                ))}
              </Select>
            </div>
          )}
          { mode === 'auto' && (
            <div className={styles.autoModeWrapper}>
              <div className={styles.autoMode}>{t('auto_mode')}</div>
              <div className={styles.whichDeck}>{t('how_many_cards')}</div>
              <NumberInput
                type="number"
                step={5}
                min={5}
                value={limit}
                onChange={onLimitChange}
              >
                <NumberInputField {...inputStyle} />
                <NumberInputStepper>
                  <NumberIncrementStepper color="#67D29E" />
                  <NumberDecrementStepper color="#67D29E" />
                </NumberInputStepper>
              </NumberInput>
            </div>
          )}

          <Button
            onClick={onConfirm}
            disabled={mode === ''}
            textTransform="uppercase"
            display="block"
            {...okButtonStyle}
          >
            {t('get_started')}
          </Button>
        </div>
    </div>
  );
};

export default PlayIndex;
