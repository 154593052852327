import { CSSProperties } from 'react';
import styles from './CountryFlag.module.scss';
import { Language } from '../../types';

interface CountryFlagInterface {
  countryCode: Language;
  style?: CSSProperties;
}

const names: Record<string, string> = {
  en: 'EN',
  fr: 'FR',
  es: 'ES',
  zh: '中',
  yue: '粵',
  ja: '日',
};

const colors: Record<string, string> = {
  en: '#3884CF',
  fr: '#853A95',
  es: '#FBD836',
  zh: '#42A147',
  yue: '#F8981D',
  ja: '#EF5122',
};

const CountryFlag: React.FC<CountryFlagInterface> = ({ countryCode, style }) => {
  const combinedStyle = {
    backgroundColor: colors[countryCode],
    ...(style || {}),
  };
  return <div className={styles.flag} style={combinedStyle}>
    {names[countryCode]}
  </div>;
};

export default CountryFlag;
