import { useState, useEffect, useMemo } from 'react';
import shuffle from 'knuth-shuffle-seeded';

import { IconButton, Input } from '@chakra-ui/react';
import getFileUrl from '../../utils/getFileUrl';
import { Card } from '../../types';
import styles from './ShuffledLetter.module.scss';
import { useTranslation } from 'react-i18next';
import playAudioIcon from '../../images/play-audio.svg';
import useInput from '../../hooks/useInput';
import { compareStr } from '../../utils/textUtils';
import tickIcon from '../../images/tick.svg';
import crossIcon from '../../images/cross.svg';
import variables from '../../variables.module.scss';
import axios from '../../utils/axios';
import generateRandomWords from '../../utils/generateRandomWords';
import LoadingSpinner from '../../components/LoadingSpinner';
import Keyboard from '../../components/Keyboard';

interface ShuffledWordInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const ShuffledLetter: React.FC<ShuffledWordInterface> = ({ card, setResult, isShowingAnswer }) => {
  const [answer, onAnswerChange, setAnswer] = useInput('');
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation('play');
  const [spin, setSpin] = useState(false);
  const [keyboardChars, setKeyboardChars] = useState<string[]>([]);

  const shuffled = useMemo(
    () => {
      if (card.title.length > 2) {
        return shuffle(card.title.split('')).join('');
      }
      return card.title.split('').reverse().join('');
    },
    [card.title],
  );

  useEffect(
    () => {
      const getChoices = async () => {
        setSpin(true);
        let fetchedChoices = [];
        try {
          const response = await axios.get('/revision/choices', { params: { cardId: card.id } });
          fetchedChoices = response.data.data.cards.map((c: any) => c.title);
          fetchedChoices = [...(new Set(fetchedChoices.filter((c: any) => c)).values())];
        } catch {
          // do nothing
        }

        const choices = generateRandomWords(
            card.deck.language, 3 - fetchedChoices.length, /[A-Z]/.test(card.title[0]))
            .concat([card.title]).concat(fetchedChoices).map(c => c.split(''));

        const chars = [...new Set(
          ([] as string[]).concat(...choices).sort(() => 0.5 - Math.random()))];

        setKeyboardChars(chars);
        setSpin(false);
      };
      getChoices();

    },
    [card],
  );

  useEffect(
    () => {
      setResult(compareStr(answer, card.title));
    },
    [answer, setResult, card],
  );

  useEffect(
    () => {
      setAnswer('');
    },
    [setAnswer, card],
  );

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const onCharClicked = (char: string) => {
    setAnswer(x => x + char);
  };

  const onBackspace = () => {
    setAnswer(x => x.substring(0, x.length - 1));
  };

  return <div className={styles.shuffledLetter}>
    <div className={styles.questionBox}>
      {shuffled}
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        height="40px"
        width="40px"
        onClick={onPlayClicked}
        disabled={!card.audioFile || playing}
        icon={<img
          alt="play"
          src={playAudioIcon}
        />}
        className={styles.audio}
      />
    </div>

    <div className={styles.instruction}>{t('rearrange_the_letters')}</div>
    { isShowingAnswer && (!compareStr(answer, card.title) &&
        <div className={styles.correctAnsWrapper}>
          <div className={styles.correctAnsTitle}>{t('answer')}</div>:&nbsp;
          <div className={styles.correctAns}>{card.title.toLowerCase().trim()}</div>
        </div>
      )
    }
    <div className={styles.inputWrapper}>
      <Input
        onChange={onAnswerChange}
        value={answer}
        id="new_word"
        variant="flushed"
        placeholder={t('answer_placeholder')}
        textAlign="center"
        height="60px"
        fontSize="24px"
        fontFamily={variables.normalFontFamily}
        _placeholder={{
          fontWeight: 200,
          letterSpacing: '2px',
        }}
        paddingLeft="40px"
        paddingRight="40px"
        borderColor="#97ACD9"
        maxLength={50}
        autoFocus
        color={isShowingAnswer ?
          (compareStr(answer, card.title) ? '#389F6D' : '#FF6A91') : '#2D65C2'}
        fontWeight="600"
        isReadOnly={isShowingAnswer}
        pointerEvents={isShowingAnswer ? 'none' : 'auto'}
      />
      { isShowingAnswer && (compareStr(answer, card.title) ?
        <img className={styles.tickIconStyle} src={tickIcon} alt="tick" /> :
        <img className={styles.crossIconStyle} src={crossIcon} alt="cross" />
        )
      }
    </div>

    <LoadingSpinner spin={spin}>
      <Keyboard
        onCharClicked={onCharClicked}
        onBackspace={onBackspace}
        chars={keyboardChars}
      />
    </LoadingSpinner>
  </div>;
};

export default ShuffledLetter;
