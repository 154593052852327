import imageCompression from 'browser-image-compression';

const compressImage = async (
    inputFile: File | undefined,
    maxSizeMB: number = 0.1,
): Promise<File | undefined> => {
  if (inputFile) {
    let file = await imageCompression(inputFile, {
      maxSizeMB,
      initialQuality: 0.8,
      maxWidthOrHeight: 1200,
      fileType: 'image/jpeg',
      useWebWorker: true,
    });

    file = new File([file], 'temp.jpeg');
    return file;
  }
};

export const compressImageToB64 = async (
  inputFile: File | undefined, maxSizeMB: number = 0.1): Promise<string | undefined> => {
  const file = await compressImage(inputFile, maxSizeMB);
  if (file) {
    return imageCompression.getDataUrlFromFile(file);
  }
};

export default compressImage;
