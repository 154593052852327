import { useToast as chakraUseToast } from '@chakra-ui/react';

type StatusType = 'info' | 'error' | 'warning' | 'success';

const useToast = () => {
  const toast = chakraUseToast();
  return (data: { title?: string, description: string, status: StatusType}) => {
    toast({
      duration: 4000,
      isClosable: true,
      position: 'top-right',
      ...data,
    });
  };
};

export default useToast;
