const CHAR_MAP = {
  '’' : '\'',
  '´' : '\'',
  '‘' : '\'',
  '`' : '\'',
};

export const compareStr = (str1: string, str2: string) => {
  return normalizeStr(str1) === normalizeStr(str2);
};

const normalizeStr = (word: string) => {
  let result = word.toLowerCase().trim();

  for (const [key, value] of Object.entries(CHAR_MAP)){
    result = result.replace(new RegExp(key, 'g'), value);
  }

  return result;
};

export const filteredJoin = (names: string[]) => {
  const filteredName = names.map(item => item.trim()).filter(item => item);
  return filteredName.join(' ');
};

export const truncateText = (text: string | undefined, limit = 30) => {
  if (!text) {
    return undefined;
  }

  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }
  return text;
};

const NAME_MAX_LENGTH = 16;

export const getName = (name: string | undefined) => {
  if (!name) {
    return undefined;
  }

  if (name.length > NAME_MAX_LENGTH) {
    return name.split(' ').map((x, index) => (
      index === 0 ? x : `${x[0]}.`
    )).join(' ');
  }
  return truncateText(name);
};

export const getDataTypeFromBase64 = (text: string) => {
  const regex = /^data:([a-z]+)\/[a-z+\-\d]+;base64,/;
  const matched = text.match(regex);
  if (matched) {
    return matched[1];
  }
};

const VIDEO_EXTS = ['mp4', 'ogg', 'webm'];
const IMAGE_EXTS = ['jpg', 'jpeg', 'png', 'bmp', 'svg'];

export const getDataTypeFromUrl = (url: string) => {
  const base64Type = getDataTypeFromBase64(url);
  if (base64Type) {
    return base64Type;
  }

  const fileExt = url.split('.').pop();
  if (fileExt) {
    if (VIDEO_EXTS.indexOf(fileExt) >= 0) {
      return 'video';
    }
    if (IMAGE_EXTS.indexOf(fileExt) >= 0) {
      return 'image';
    }
  }
};
