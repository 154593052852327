import { useContext, useRef, ChangeEvent, useState } from 'react';
import { MenuButton, MenuList, MenuItem, Menu,
  AlertDialog, AlertDialogOverlay, AlertDialogHeader, AlertDialogBody,
  AlertDialogFooter, AlertDialogContent, Button,
} from '@chakra-ui/react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import axios from '../../utils/axios';
import { AppContext } from '../../AppContext';
import moreActionImage from '../../images/more_action_button.svg';
import { Deck } from '../../types';
import styles from './DeckMenu.module.scss';
import { gaTrack } from '../../services/analytics';
import compressImage from '../../utils/compressImage';
import useToast from '../../hooks/useToast';
import getDeckName from '../../utils/getDeckName';

interface DeckMenuInterface {
  deck: Deck;
  onDeckUpdated: (deleted: boolean) => void;
}

interface DeleteDialogInterface {
  deck: Deck;
  onClose: (isDeleted: boolean) => void;
  isOpen: boolean;
}

const DeleteDialog: React.FC<DeleteDialogInterface> = ({ deck, onClose, isOpen }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('deck_list');
  const { t: translateBackend } = useTranslation('backend');
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AppContext);
  const createdByCurrentUser = deck.createdBy === context.currentUser?.id;

  const deckName = getDeckName(deck, context.currentUser);

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`/decks/${deck?.id}`);
      if (response.data.status === 'fail') {
        toast({
          title: t('deck_delete_failed'),
          description: translateBackend(response.data.data.message),
          status: 'error',
        });
      } else {
        toast({
          title: t('deck_deleted'),
          description: t('deck_deleted_desc', { name: deckName }),
          status: 'success',
        });
      }

    } catch {
      toast({
        title: t('deck_delete_failed'),
        description: t('deck_delete_failed_desc'),
        status: 'error',
      });
    }
    setIsLoading(false);
    onClose(true);
  };

  const onUnfollow = async () => {
    setIsLoading(true);
    try {
      await axios.delete(`/decks/${deck?.id}/follow`);
      toast({
        title: t('deck_unfollowed'),
        description: t('deck_unfollowed_desc', { name: deckName }),
        status: 'success',
      });

    } catch {
      toast({
        title: t('deck_unfollow_failed'),
        description: t('deck_unfollow_failed_desc'),
        status: 'error',
      });
    }
    setIsLoading(false);
    onClose(false);
  };

  return <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={() => onClose(false)}
    size="xl"
    isCentered
  >
    <AlertDialogOverlay>
      <AlertDialogContent margin="20px">
        <AlertDialogHeader fontSize="xl" fontWeight="bold">
          {createdByCurrentUser ? t('delete_title') : t('unfollow_title')}
        </AlertDialogHeader>
        <AlertDialogBody fontSize="lg">
          { createdByCurrentUser ? (
            <>
              {t('are_you_sure_1')}
              <span className={styles.deleteDeck}>"{deckName}"</span>
              {t('are_you_sure_2')}
            </>
          ) : (
            <Trans t={t} i18nKey="are_you_sure_unfollow" name={deckName}>
              Are you sure to unfollow
              <span className={styles.deleteDeck}>{{ name: deckName }}</span>?
            </Trans>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            fontSize="16px"
            ref={cancelRef}
            variant="solid"
            onClick={() => onClose(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            variant="solid"
            fontSize="16px"
            onClick={createdByCurrentUser ? onDelete : onUnfollow}
            ml={3}
          >
            {createdByCurrentUser ? t('delete') : t('unfollow')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>;
};

const DeckMenu: React.FC<DeckMenuInterface> = ({ deck, onDeckUpdated }) => {
  const { t } = useTranslation('deck_list');
  const context = useContext(AppContext);
  const toast = useToast();
  const currentUserId = context.currentUser?.id;
  const inputFile = useRef<HTMLInputElement>(null);
  const [deleting, setDeleting] = useState(false);
  const deckName = getDeckName(deck, context.currentUser);

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList !== null && fileList.length > 0) {
      let file = fileList[0];
      file = (await compressImage(file))!;
      const formData = new FormData();
      formData.append('files', file);
      const response = await axios.post('/files', formData);

      await axios.put(`/decks/${deck.id}/cover`, {
        coverImageFileId: response.data.data.file.id,
      });

      toast({
        title: t('success'),
        description: t('update_cover_photo_success'),
        status: 'success',
      });

      gaTrack('Deck', 'Update deck cover');

      onDeckUpdated(false);
    }
  };

  const onUpdateCoverPhotoClicked = () => {
    inputFile.current?.click();
  };

  const onDeleteClicked = () => {
    setDeleting(true);
  };

  const onCloseDeleteDialog = (isDeleted: boolean) => {
    setDeleting(false);
    onDeckUpdated(isDeleted);
  };

  const onFollowClicked = async () => {
    await axios.post(`/decks/${deck?.id}/follow`);
    toast({
      title: t('deck_followed'),
      description: t('deck_followed_desc', { name: deckName }),
      status: 'success',
    });

    onDeckUpdated(false);
  };

  const onCopy = () => {
    toast({
      description: t('copy_link_to_share_success'),
      status: 'success',
    });
  };

  return (
    <>
      <Menu>
        <MenuButton className={styles.moreActionButton}>
          <img src={moreActionImage} alt="more" />
        </MenuButton>
        <MenuList className={styles.menu} zIndex={1000}>
          { currentUserId === deck.createdBy && (
            <>
              <MenuItem
                as={Link}
                to={{ pathname: '/card/new/', state: { deckId: deck.id } }}
              >
                <div className={styles.menuItem}>{t('add_new_cards')}</div>
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/deck/edit?id=${deck.id}`}
              ><div className={styles.menuItem}>{t('rename')}</div></MenuItem>
              <MenuItem
                onClick={ () => onUpdateCoverPhotoClicked() }
              ><div className={styles.menuItem}>{t('update_cover')}</div></MenuItem>
              <input
                onChange={onImageChange}
                type="file"
                accept="image/*"
                ref={inputFile}
                className={styles.imageInput}
              />
              <MenuItem>
                <CopyToClipboard
                  text={`${window.location.origin}/deck?id=${deck.nid}`}
                  onCopy={onCopy}
                >
                  <div className={classnames(styles.menuItem)}>
                    {t('copy_link_to_share')}
                  </div>
                </CopyToClipboard>
              </MenuItem>
              <MenuItem onClick={onDeleteClicked}>
                <div className={classnames(styles.menuItem, styles.delete)}>
                  {t('delete')}
                </div>
              </MenuItem>
            </>
            )}

            { currentUserId !== deck.createdBy && (
              <>
                { deck.following ? (
                  <MenuItem onClick={onDeleteClicked}>
                    <div className={classnames(styles.menuItem, styles.delete)}>
                      {t('unfollow')}
                    </div>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={onFollowClicked}>
                    <div className={classnames(styles.menuItem)}>
                      {t('follow')}
                    </div>
                  </MenuItem>
                )
                }
              </>
            )}
          </MenuList>
      </Menu>

      <DeleteDialog isOpen={deleting} deck={deck} onClose={onCloseDeleteDialog}/>
    </>
  );
};

export default DeckMenu;
