import { useState, useContext } from 'react';
import { IconButton, Image, Modal, ModalOverlay, ModalContent, ModalBody,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Card } from '../../types';
import getFileUrl from '../../utils/getFileUrl';
import playAudioIcon from '../../images/play-audio.svg';
import nextIcon from '../../images/discover-next.svg';
import prevIcon from '../../images/discover-back.svg';
import styles from './Discover.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import CardView from '../../components/CardView';
import getMeaning from '../../utils/getMeaning';
import { AppContext } from '../../AppContext';
import { gaTrack } from '../../services/analytics';
import { truncateText } from '../../utils/textUtils';
import Video from '../../components/Video';

interface DiscoverInterface {
  cards: Card[];
}

interface DiscoverImageInterface {
  card: Card;
  onClick: (card: Card) => void;
}

const DiscoverImage: React.FC<DiscoverImageInterface> = ({ card, onClick }) => {
  const [playing, setPlaying] = useState(false);
  const context = useContext(AppContext);
  const imageUrl = getFileUrl(card.imageFile);

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    gaTrack('Discover', 'Playback audio');
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  return (
    <div className={styles.card} onClick={() => onClick(card)}>
      <Video className={styles.video} imageUrl={imageUrl}>
        <Image
          objectFit="cover"
          w={200}
          h={200}
          src={imageUrl}
          alt="card"
        />
      </Video>
      <div className={styles.control}>
        <div>
          <div className={styles.title}>{ truncateText(card.title) }</div>
          <div className={styles.meaning}>
            { truncateText(getMeaning(card.meanings, context.currentUser?.systemLanguage, true)) }
          </div>
        </div>
        <IconButton
          _hover={{ filter: 'brightness(90%)' }}
          aria-label="play"
          background="none"
          height="40px"
          width="40px"
          onClick={onPlayClicked}
          disabled={!card.audioFile || playing}
          icon={<img alt="play" src={playAudioIcon} />}
        />
      </div>
    </div>
  );
};

const Discover: React.FC<DiscoverInterface> = ({ cards }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [currentCard, setCurrentCard] = useState<Card>();
  const history = useHistory();
  const context = useContext(AppContext);

  const onNext = () => {
    swiper?.slideNext();
  };

  const onBack = () => {
    swiper?.slidePrev();
  };

  const onCloseModal = () => {
    setCurrentCard(undefined);
  };

  const onCardClick = (card: Card) => {
    gaTrack('Discover', 'View card');
    setCurrentCard(card);
  };

  const onDownloadClicked = () => {
    gaTrack('Discover', 'Download card');
    if (currentCard) {
      history.push(`card/edit?id=new&fromCardId=${currentCard.id}`);
    }
  };

  return <div className={styles.discover}>
    <Swiper
      spaceBetween={30}
      slidesPerView="auto"
      onSwiper={(s) => { setSwiper(s); }}
      className={styles.swiper}
    >
      {cards.map(card => (
        <SwiperSlide
          className={styles.slide}
          key={card.id}
        >
          <DiscoverImage card={card} onClick={onCardClick} />
        </SwiperSlide>
      ))}
    </Swiper>

    <IconButton
      className={styles.prev}
      aria-label="back"
      background="none"
      height="40px"
      width="40px"
      top="80px"
      left="-60px"
      position="absolute"
      onClick={onBack}
      icon={<img alt="previous" src={prevIcon} />}
    />

    <IconButton
      className={styles.next}
      position="absolute"
      top="80px"
      right="-60px"
      aria-label="next"
      background="none"
      height="40px"
      width="40px"
      onClick={onNext}
      icon={<img alt="next" src={nextIcon} />}
    />

    <Modal isOpen={!!currentCard} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent background="none" boxShadow="none">
        <ModalBody padding="0" margin="0 10px">
          { currentCard && (
            <div className={styles.currentCardWrapper}>
              <CardView
                imageUrl={getFileUrl(currentCard.imageFile)}
                word={currentCard.title}
                audio={currentCard.audioFile}
                meaning={getMeaning(
                  currentCard.meanings, context.currentUser?.systemLanguage, true)}
                pronunciation={currentCard.pronunciation}
                hideShadow={true}
                onClose={onCloseModal}
                onDownloadClicked={onDownloadClicked}
                language={context.currentUser?.targetLanguage}
              />
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  </div>;
};

export default Discover;
