import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton  } from '@chakra-ui/react';

import axios from '../../utils/axios';
import { User } from '../../types';
import styles from './FriendsTab.module.scss';
import acceptIcon from '../../images/friends/accept.svg';
import rejectIcon from '../../images/friends/reject.svg';
import useToast from '../../hooks/useToast';
import FriendsTabRow from './FriendsTabRow';
import LoadingSpinner from '../../components/LoadingSpinner';

interface FriendRequestRowInterface {
  user: User;
  onUpdate: () => void;
}

const actionButtonStyle = {
  width: '34px',
  height: '34px',
  _hover: {
    filter: 'brightness(90%)',
  },
};

const ReceivedFriendRequestRow: React.FC<FriendRequestRowInterface > =
({ user, onUpdate }) => {

  const { t } = useTranslation('friends');
  const toast = useToast();

  const onAcceptFriendClicked = async () => {
    try {
      await axios.put(
        '/friendRequests/accept', { userId: user.id });
    } catch (e) {
      toast({
        title: t('error'),
        description: e.response ?  e.response.data.data.message : t('error_desc'),
        status: 'error',
      });
    }

    onUpdate();
  };

  const onRejectFriendClicked = async () => {
    try {
      await axios.put(
        '/friendRequests/reject', { userId: user.id });
    } catch (e) {
      toast({
        title: t('error'),
        description: e.response ?  e.response.data.data.message : t('error_desc'),
        status: 'error',
      });
    }

    onUpdate();
  };

  return <FriendsTabRow
    user={user}
    message={<p className={styles.message}>{ t('sent_you_a_friend_request') }</p>}
    actionButtons={<>
      <IconButton
        {...actionButtonStyle}
        onClick={onAcceptFriendClicked}
        aria-label="cover"
        isRound={true}
        variant="none"
        icon={<img alt="accept" src={acceptIcon} />}
        marginRight="2px"
      />
      <IconButton
        {...actionButtonStyle}
        onClick={onRejectFriendClicked}
        aria-label="cover"
        isRound={true}
        variant="none"
        icon={<img alt="reject" src={rejectIcon} />}
      />
    </>}
  />;
};

const SentFriendRequestRow: React.FC<FriendRequestRowInterface> =
({ user, onUpdate }) => {

  const { t } = useTranslation('friends');
  const toast = useToast();

  const onDeleteFriendClicked = async () => {
    try {
      await axios.delete(
        '/friendRequests', { data: { userId: user.id } });
    } catch (e) {
      toast({
        title: t('error'),
        description: e.response ?  e.response.data.data.message : t('error_desc'),
        status: 'error',
      });
    }

    onUpdate();
  };

  return <FriendsTabRow
    user={user}
    message={<p className={styles.message}>{ t('waiting_for_approval') }</p>}
    actionButtons={<>
      <IconButton
        {...actionButtonStyle}
        onClick={onDeleteFriendClicked}
        aria-label="cover"
        isRound={true}
        variant="none"
        icon={<img alt="reject" src={rejectIcon} />}
      />
    </>}
  />;
};

interface FriendRequestInterface {
  receivedFriendRequests: User[];
  sentFriendRequests: User[];
  onUpdate: () => void;
  loading: boolean;
}

const FriendRequest: React.FC<FriendRequestInterface> =
({ receivedFriendRequests, sentFriendRequests, onUpdate, loading }) => {
  const { t } = useTranslation('friends');
  const n = receivedFriendRequests.length + sentFriendRequests.length;
  return <div>
    <h4 className={styles.title}>
      { t('waiting_n', { n })}
    </h4>
    <LoadingSpinner spin={loading}>
      {receivedFriendRequests.map(user => (
        <ReceivedFriendRequestRow key={user.id} user={user} onUpdate={onUpdate} />
      ))}

      {sentFriendRequests.map(user => (
        <SentFriendRequestRow key={user.id} user={user} onUpdate={onUpdate} />
      ))}

      {(n === 0) && <p className={styles.empty}>{t('no_friend_request')}</p>}
    </LoadingSpinner>
  </div>;
};

const PendingTab = () => {
  const [receivedFriendRequests, setReceivedFriendRequests] = useState<User[]>([]);
  const [sentFriendRequests, setSentFriendRequests] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  const getReceivedFriendRequests = async () => {
    const response = await axios.get('/friendRequests/received');
    setReceivedFriendRequests(response.data.data.records.map((r: any) => r.sender));
  };

  const getSentFriendRequests = async () => {
    const response = await axios.get('/friendRequests/sent');
    setLoading(false);
    setSentFriendRequests(response.data.data.records.map((r: any) => r.receiver));
  };

  const onUpdate = useCallback(
    () => {
      getReceivedFriendRequests();
      getSentFriendRequests();
    },
    [],
  );

  useEffect(
    () => {
      onUpdate();
    },
    [onUpdate],
  );

  return <div className={styles.friendsTab}>
    <FriendRequest
      receivedFriendRequests={receivedFriendRequests}
      sentFriendRequests={sentFriendRequests}
      onUpdate={onUpdate}
      loading={loading}
    />
  </div>
  ;
};

export default PendingTab;
