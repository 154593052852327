import { Image, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Card } from '../../types';
import getFileUrl from '../../utils/getFileUrl';
import styles from './VocabularySwiper.module.scss';
import placeholderImage from '../../images/image-placeholder.svg';

interface VocabularySwiperInterface {
  cards: Card[];
  spin: boolean;
  onChange: (progress: any) => void;
}

interface VocabularySwiperCardInterface {
  card: Card;
}

const VocabularySwiperCard: React.FC<VocabularySwiperCardInterface> = ({ card }) => {
  return (
    <Link
      to={`/deck?id=${card.deck.nid}&cardId=${card.nid}`}
    >
      <div className={styles.card}>
        <Image
          objectFit="cover"
          w={110}
          h={110}
          borderRadius={10}
          src={getFileUrl(card.imageFile)}
          alt="card"
          fallback={<div className={styles.placeholderImageWrapper}>
              <img className={styles.placeholderImage} src={placeholderImage} alt=""/>
            </div>
            }
        />
      </div>
    </Link>
  );
};

const VocabularySwiper: React.FC<VocabularySwiperInterface> = ({ cards, spin, onChange }) => {
  return <div>
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      onProgress={(param) => { onChange(param.progress); }}
      className={styles.swiper}
    >
      {cards.map(card => (
        <SwiperSlide
          className={styles.slide}
          key={card.id}
        >
          <VocabularySwiperCard card={card} />
        </SwiperSlide>
      ))}
      { spin &&
        <SwiperSlide
          className={styles.slide}>
          <div className={styles.spinnerWrapper}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.600"
              size="xl"/>
          </div>
        </SwiperSlide>
      }
    </Swiper>
  </div>;
};

export default VocabularySwiper;
