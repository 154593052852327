import { Link } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';
import { useState, useContext } from 'react';
import classnames from 'classnames';

import { Card } from '../../types';
import styles from './CardDisplayRow.module.scss';
import ScoreIcon from '../../components/ScoreIcon';
import getMeaning from '../../utils/getMeaning';
import getFileUrl from '../../utils/getFileUrl';
import playAudioIcon from '../../images/play-audio-blue.svg';
import playIcon from '../../images/play.svg';
import { AppContext } from '../../AppContext';

interface CardDisplayRowInterface {
  card: Card;
  disable?: boolean;
}

const CardDisplayRow: React.FC<CardDisplayRowInterface> = ({ card, disable = false }) => {
  const [playing, setPlaying] = useState(false);
  const [isDisable] = useState(disable);
  const { currentUser } = useContext(AppContext);

  const onPlayAudioClicked = () => {
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const meaning = getMeaning(card.meanings, currentUser?.systemLanguage, true) || '-';
  return (
    <div key={card.id} className={styles.row} >
      <div className={classnames(styles.statusCol, { [styles.disable]: isDisable })}>
        <ScoreIcon score={card.score}/>
      </div>
      <Link
        className={classnames(styles.word, styles.wordCol, { [styles.disable]: isDisable })}
        to={!isDisable ? `/deck?id=${ card.deck?.nid || card.deckId}&cardId=${card.nid }` : '#'}
      >
        <div className={styles.wordTitle}>{card.title}</div>
        <div className={styles.wordMeaning}>
          { meaning }
        </div>
      </Link>
      <div className={
        classnames(styles.meaning, styles.meaningCol, { [styles.disable]: isDisable })}>
        {meaning}
      </div>
      <div className={styles.buttonCol}>
        <IconButton
        className={styles.playAudio}
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play audio"
        background="none"
        height="42px"
        width="42px"
        onClick={onPlayAudioClicked}
        disabled={!card.audioFile || isDisable || playing}
        icon={<img alt="play" src={playAudioIcon} />}
        />
        <IconButton
          className={styles.play}
          _hover={{ filter: 'brightness(90%)' }}
          aria-label="play"
          background="none"
          height="42px"
          width="42px"
          marginLeft="46px"
          to={isDisable ? '#' : '/play/auto?limit=20'}
          as={Link}
          icon={<img alt="play" src={playIcon} />}
          disabled={isDisable}
        />
      </div>
    </div>
  );
};

export default CardDisplayRow;
