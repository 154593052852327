import { ChangeEvent, useRef, useContext, useEffect,
  useState, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Input, InputGroup,
  InputRightElement, IconButton, Button,
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { useLocation, useHistory } from 'react-router-dom';

import TopBar from '../../components/TopBar';
import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import { File as FileType, Deck, StateType } from '../../types';
import useInput from '../../hooks/useInput';
import styles from './CreateCard.module.scss';
import dictIcon from '../../images/dict-button.svg';
import detectIcon from '../../images/detect.svg';
import recordIcon from '../../images/record.svg';
import stopIcon from '../../images/stop.svg';
import scanIcon from '../../images/scan.svg';
import { start as startRecording, stop as stopRecording } from '../../utils/recorder';
import CreateDeckFormModal from '../../components/CreateDeckForm';
import variables from '../../variables.module.scss';
import analytics, { gaTrack } from '../../services/analytics';
import compressImage from '../../utils/compressImage';
import useToast from '../../hooks/useToast';
import LoadingSpinner from '../../components/LoadingSpinner';
import OcrResultPicker from './OcrResultPicker';
import DictModal, { Meanings } from './DictModal';
import useStore from '../../hooks/useStore';
import getDeckName from '../../utils/getDeckName';

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#2D65C2',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
};

const nextButtonStyle = {
  width: '368px',
  display: 'block',
  margin: '0 auto',
  height: '53px',
};

const CreateCard = () => {
  const context = useContext(AppContext);
  const { createCardLastDeckId, setStores } = useStore();
  const { t } = useTranslation('create_card');
  const [decks, setDecks] = useState<Deck[]>([]);
  const [uploading, setUploading] = useState(false);
  const location = useLocation<StateType>();
  const [deckId, setDeckId] = useState(location.state?.deckId || createCardLastDeckId || '');
  const [word, onWordChange, setWord] = useInput('');
  const history = useHistory();
  const inputScanFile = useRef<HTMLInputElement>(null);
  const inputDetectFile = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const [recording, setRecording] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);
  const recorderButton = useRef<HTMLButtonElement>(null);
  const [isOpenDeckForm, setIsOpenDeckForm] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [ocrImage, setOcrImage] = useState<FileType | undefined>(undefined);
  const [ocrWords, setOcrWords] = useState([]);
  const [meaning, setMeaning] = useState('');
  const [showDictModal, setShowDictModal] = useState(false);
  const [meanings, setMeanings] = useState<Meanings>();

  const onDeckIdChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    if (value === 'create_deck') {
      setIsOpenDeckForm(true);
    } else {
      setDeckId(value);
    }
  };

  const onCloseDeckForm = (updated: boolean, _newDeckNId?: string, newDeckId?: string) => {
    setIsOpenDeckForm(false);
    if (updated && newDeckId) {
      setDeckId(newDeckId);
      getDecks();
    }
  };

  const getDecks = useCallback(
    async () => {
      const response = await axios.get('/decks', { params: { type: 'created' } });
      setDecks(response.data.data.decks);
      if (response.data.data.decks.map((d: any) => d.id).indexOf(deckId) < 0) {
        if (response.data.data.decks.length > 0) {
          setDeckId(response.data.data.decks[0].id);
        } else {
          setDeckId('');
        }
      }
    },
    [deckId, setDeckId],
  );

  useEffect(
    () => {
      if (!initialized) {
        analytics.page();
        context.setCurrentTab('card');
        setInitialized(true);
      }
    },
    [getDecks, context, initialized],
  );

  useEffect(
    () => {
      getDecks();
    },
    [context.currentUser?.targetLanguage], // eslint-disable-line
  );

  const onNextClick = useCallback(
    () => {
      if (word && !showDictModal) {
        gaTrack('Create Card', 'Next button clicked');
        setStores({ createCardLastDeckId: deckId });
        history.push({
          pathname: '/card/edit',
          search: '?id=new',
          state: {
            deckId,
            word,
            ocrImage,
            meanings,
          },
        });
      }
    },
    [deckId, history, word, ocrImage, setStores, meanings, showDictModal],
  );

  const onScanImageClick = () => {
    if (inputScanFile.current !== null) {
      inputScanFile.current.click();
    }
  };

  const onDetectImageClick = () => {
    if (inputDetectFile.current !== null) {
      inputDetectFile.current.click();
    }
  };

  const getImageDim = (file: File) => {
    return new Promise<any>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const onScanImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      gaTrack('Create Card', 'Use image recognition');
      let file = event.target.files[0];
      file = (await compressImage(file))!;

      if (!file) {
        toast({
          title: t('error'),
          description: t('try_again'),
          status: 'error',
        });
        return;
      }

      try {
        setUploading(true);
        setWord('');
        setMeaning('');
        const formData = new FormData();
        formData.append('files', file);
        const response = await axios.post('/files', formData);

        const imageId = response.data.data.file.id;
        setOcrImage({ id: imageId, name: response.data.data.file.name });
        const { width: imageW, height: imageH } = await getImageDim(file);
        const ocrResponse = await axios.post(
          '/recognize/ocr',
          {
            id: imageId,
            width: imageW,
            height: imageH,
          },
        );

        const result = ocrResponse.data.data.words;
        if (result && result.length > 0) {
          setOcrWords(result);
        } else {
          toast({
            title: t('no_words'),
            description: t('try_again'),
            status: 'info',
          });
        }
      } catch {
        toast({
          title: t('error'),
          description: t('try_again'),
          status: 'error',
        });
      }

      setUploading(false);
    }
  };

  const onDetectImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      gaTrack('Create Card', 'Use object detection');
      let file = event.target.files[0];
      file = (await compressImage(file))!;

      if (!file) {
        toast({
          title: t('timeout'),
          description: t('try_again'),
          status: 'info',
        });
        return;
      }

      try {
        setUploading(true);
        setWord('');
        setMeaning('');
        const formData = new FormData();
        formData.append('files', file);
        const response = await axios.post('/files', formData);

        const imageId = response.data.data.file.id;
        setOcrImage({ id: imageId, name: response.data.data.file.name });
        const ocrResponse = await axios.post(
          '/recognize/object',
          {
            id: imageId,
          },
        );
        const result = ocrResponse.data.data.labelTargetLang;
        if (result) {
          setMeaning(ocrResponse.data.data.labelSystemLang);
          setWord(result);
        } else {
          toast({
            title: t('no_words'),
            description: t('try_again'),
            status: 'info',
          });
        }
      } catch {
        toast({
          title: t('error'),
          description: t('try_again'),
          status: 'error',
        });
      }
      setUploading(false);
    }
  };

  const onRecorderToggled = () => {
    if (!recording) {
      gaTrack('Create Card', 'Use speech recognition');
      setRecording(true);
      startRecording();

      const tmpTimeoutId: ReturnType<typeof setTimeout> = setTimeout(
        () => {
          if (recorderButton.current !== null) {
            recorderButton.current.click();
          }
        },
        3000,
      );
      setTimeoutId(tmpTimeoutId);
    } else {
      clearTimeout(timeoutId as ReturnType<typeof setTimeout>);
      onStopRecording();
    }
  };

  const onStopRecording = async () => {
    // retrieve wav file
    setRecording(false);
    const file = stopRecording('wav');

    if (!file) {
      toast({
        title: t('no_words'),
        description: t('try_again'),
        status: 'info',
      });
      return;
    }

    try {
      setWord('');
      setMeaning('');
      setUploading(true);

      const formData = new FormData();
      formData.append('files', file);

      const response = await axios.post('/files', formData);
      const audioId = response.data.data.file.id;

      const speechResponse = await axios.post('/recognize/speech', { id: audioId });
      const result = speechResponse.data.data.res.word;
      if (result) {
        setWord(result);
      } else {
        toast({
          title: t('no_words'),
          description: t('try_again'),
          status: 'info',
        });
      }
    } catch {
      toast({
        title: t('error'),
        description: t('try_again'),
        status: 'error',
      });
    }

    setUploading(false);
  };

  const onSelectOcrResult = (text: string) => {
    setWord(text);
    setOcrWords([]);
  };

  const onDictButtonClick = () => {
    setShowDictModal(true);
  };

  const onCloseDictModal = (text: string, m?: Meanings) => {
    setShowDictModal(false);
    if (text) {
      setWord(text);
      setMeanings(m);
    }
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextClick();
  };

  return (
    <div className={styles.outer}>
      <TopBar />
      <form className={styles.createCard} onSubmit={onFormSubmit}>
        <div className={styles.createCardInner}>
          <div className={styles.spacer} />
          <div className={styles.title}>{t('title')}</div>
          <Select
            value={deckId}
            onChange={onDeckIdChange}
            {...selectStyle}
            icon={<TriangleDownIcon />}
            textAlign="center"
          >
            {decks.map(deck => (
              <option key={deck.id} value={deck.id}>
                {getDeckName(deck, context.currentUser)}
              </option>
            ))}
            <option value="create_deck">{t('create_deck')}</option>
          </Select>
          <div className={styles.spacer} />
          <InputGroup>
            <Input
              id="new_word"
              variant="flushed"
              placeholder={t('new_word')}
              textAlign="center"
              height="60px"
              fontSize="24px"
              fontFamily={variables.normalFontFamily}
              _placeholder={{
                fontWeight: 200,
                letterSpacing: '2px',
              }}
              fontWeight={600}
              paddingLeft="50px"
              paddingRight="50px"
              borderColor="#97ACD9"
              onChange={(e) => {
                onWordChange(e);
                setMeaning('');
                setMeanings(undefined);
              }}
              value={word}
              maxLength={50}
            />
            <InputRightElement style={{ width: 0, position: 'relative' }}>
              { (context.currentUser?.systemLanguage !== context.currentUser?.targetLanguage) && (
                <IconButton
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="dictionary"
                  background="none"
                  position="absolute"
                  zIndex="1"
                  icon={<img alt="scan" src={dictIcon} />}
                  width="64px"
                  bottom="-24px"
                  right="-16px"
                  onClick={onDictButtonClick}
                />
              ) }
            </InputRightElement>
          </InputGroup>
          { meaning && <div className={styles.meaning}>{meaning}</div>}
          <div className={styles.spacer} />
          <div className={styles.instruction}>{t('instruction')}</div>
          <LoadingSpinner spin={uploading} height={80}>
            <div className={styles.buttonContainer}>
              <div className={styles.button}>
                <IconButton
                  id="record"
                  onClick={onRecorderToggled}
                  ref={recorderButton}
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="record"
                  background="none"
                  height="80px"
                  icon={ recording ? <img alt="record"
                    src={stopIcon} /> : <img alt="record" src={recordIcon} />}
                />
              </div>
              <div className={styles.button}>
                <input
                  onChange={onDetectImageChange}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  className={styles.imageInput}
                  ref={inputDetectFile}
                  autoCapitalize="none"
                />
                <IconButton
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="scan"
                  background="none"
                  height="80px"
                  icon={<img alt="scan" src={detectIcon} />}
                  onClick={onDetectImageClick}
                />
              </div>
              <div className={styles.button}>
                <input
                  onChange={onScanImageChange}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  className={styles.imageInput}
                  ref={inputScanFile}
                  autoCapitalize="none"
                />
                <IconButton
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="scan"
                  background="none"
                  height="80px"
                  icon={<img alt="scan" src={scanIcon} />}
                  onClick={onScanImageClick}
                />
              </div>
            </div>
          </LoadingSpinner>
          <div className={styles.spacer} />
          <Button
            className={styles.next}
            textTransform="uppercase"
            {...nextButtonStyle}
            disabled={!word || uploading}
            type="submit"
          >
            {t('next')}
          </Button>
          <div className={styles.spacer} />
        </div>
      </form>
      <CreateDeckFormModal isOpen={isOpenDeckForm} onClose={onCloseDeckForm} />
      <OcrResultPicker words={ocrWords} image={ocrImage} onSelect={onSelectOcrResult} />
      <DictModal isOpen={showDictModal} onClose={onCloseDictModal} />
    </div>
  );
};

export default CreateCard;
