import urlJoin from 'url-join';
import { File } from '../types';

const getFileUrl = (file: File | null | undefined): string | undefined => {
  if (file && process.env.REACT_APP_FILES_BASE_URL) {
    return urlJoin(process.env.REACT_APP_FILES_BASE_URL, file.name);
  }
};

export default getFileUrl;
