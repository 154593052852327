import React, { useState, useContext, useEffect, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as ReactLink } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, Button, ModalBody, Input,
  ModalFooter, Stack, ModalHeader, ModalCloseButton, FormControl, Link, Text,
  ModalHeaderProps, LinkProps, TextProps, InputRightElement, InputGroup,
  Tooltip, ScaleFade,
} from '@chakra-ui/react';

import axios from '../../utils/axios';
import styles from './Signup.module.scss';
import useInput from '../../hooks/useInput';
import { AppContext } from '../../AppContext';

import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import analytics, { gaTrack } from '../../services/analytics';
import variables from '../../variables.module.scss';
import useToast from '../../hooks/useToast';
import GoogleLoginButton from '../../components/GoogleLoginButton';

interface ErrorType {
  value: string;
  msg: string;
  param: string;
}

const modalContentStyle = {
  borderRadius: '2xl',
  padding: '10px',
};

const modalHeaderStyle: ModalHeaderProps = {
  fontFamily: variables.titleFontFamily,
  letterSpacing: '0.02em',
  color: '#F79411',
  fontWeight: '500',
  marginBottom: '20px',
  display: 'flex',
  flexFlow: 'row',
  textTransform: 'uppercase',
};

const linkStyle: LinkProps = {
  fontFamily: variables.titleFontFamily,
  color: '#c5d2e8',
  letterSpacing: '0.02em',
  fontWeight: '500',
  _focus: { outline: 0 },
  textTransform: 'uppercase',
};

const modelCloseButtonStyle = {
  margin: '6px',
  size: 'md',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  borderRadius: '50%',
};

const modalBody = {
  paddingTop: '0px',
  paddingBottom: '40px',
  paddingRight: { base: '15px', md: '50px', lg: '80px' },
  paddingLeft: { base: '15px', md: '50px', lg: '80px' },
  maxWidth: '375px',
  margin: '0 auto',
};

const stackStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  paddingBottom: '20px',
};

const inputNameStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'row',
  alignItems: 'baseline',
};

const inputStyle = {
  fontFamily: variables.normalFontFamily,
  padding: '26px',
  marginTop: '20px',
  border: '0px',
  borderRadius: '5px',
  bg: '#6dd8e0',
  outline: 'none',
  _placeholder: { color: 'white' },
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
};

const buttonStyle = {
  bg: 'white',
  height: '3rem',
  letterSpacing: '0.01em',
  color: '#F79411',
  border: '1px solid #F79411',
  fontWeight: '600',
  _focus: { outline: 0 },
  width: 'calc(100% - 30px)',
  margin: '0 15px',
  fontSize: '14px',
  textTransform: 'none',
  fontFamily: variables.normalFontFamily,
};

const createTextStyle: TextProps = {
  fontFamily: variables.normalFontFamily,
  color: '#2D65C2',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '16px',
  fontWeight: '600',
  textTransform: 'uppercase',
};

const agreeText = {
  fontFamily: variables.normalFontFamily,
  paddingTop: '2em',
  color: '#9FBDBC',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '1.0em',
  fontWeight: '500',
};

const termsText = {
  fontFamily: variables.normalFontFamily,
  color: '#9FBDBC',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '1.0em',
  fontWeight: '600',
};

const checkCircleIconStyle = {
  boxSize: '25px',
  color: 'green.400',
  background: 'white',
  borderRadius: '50%',
};

const infoIconStyle = {
  boxSize: '25px',
  color: 'orange.400',
  background: 'white',
  borderRadius: '50%',
};

const iconFadeStyle = {
  initialScale: 0.8,
  in: true,
};

interface SignupInterface {
  isOpen: boolean;
}

const Signup: React.FC<SignupInterface> = ({ isOpen }) => {
  const { t, i18n } = useTranslation('signup');
  const translateBackend = useTranslation('backend').t;
  const history = useHistory();
  const context = useContext(AppContext);
  const toast = useToast();

  const [email, setEmail] = useInput('');
  const [password, setPassword] = useInput('');
  const [confirmPassword, setConfirmPassword] = useInput('');
  const [firstName, setFirstName] = useInput('');
  const [lastName, setLastName] = useInput('');

  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      analytics.page();
      context.setCurrentTab('account');
    },
    [context],
  );

  const onSubmit = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await axios.post('/auth/signup', {
          email, password, confirmPassword, firstName, lastName, systemLanguage: i18n.language,
        });
        context.updateToken(response.data.data.token);
        toast({
          title: t('signup_success'),
          description: t('signup_success_desc'),
          status: 'success',
        });
        gaTrack('Signup', 'Submit signup');
        history.replace({
          pathname: '/tutorial',
        });

      } catch (err) {
        const errorsMap: Record<string, ErrorType> = {};
        err.response.data.data.errors.forEach((error: ErrorType) => {
          errorsMap[error.param] = error;
        });

        if (errorsMap.email) {
          setEmailErrorMsg(errorsMap.email.msg);
        } else {
          setEmailErrorMsg('');
        }

        if (errorsMap.password) {
          setPasswordErrorMsg(errorsMap.password.msg);
        } else {
          setPasswordErrorMsg('');
        }

        if (errorsMap.confirmPassword) {
          setConfirmPasswordErrorMsg(errorsMap.confirmPassword.msg);
        } else {
          setConfirmPasswordErrorMsg('');
        }

        if (errorsMap.firstName) {
          setFirstNameErrorMsg(errorsMap.firstName.msg);
        } else {
          setFirstNameErrorMsg('');
        }

        if (errorsMap.lastName) {
          setLastNameErrorMsg(errorsMap.lastName.msg);
        } else {
          setLastNameErrorMsg('');
        }
      }
      setLoading(false);
    },
    [confirmPassword, context, email,
      firstName, history,
      password, toast, lastName, t, i18n.language],
  );

  const onClose = () => {
    history.replace('/');
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const onGoogleLoginError = (msg: string) => {
    setEmailErrorMsg(msg);
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent {...modalContentStyle}>
        <form onSubmit={onFormSubmit}>
          <ModalHeader {...modalHeaderStyle}>
            <Link {...linkStyle} as={ReactLink} to="/login" >
              {t('login')}
            </Link>
            &nbsp;&nbsp;&nbsp;<span style={{ color: '#16B5DF' }}>|</span>&nbsp;&nbsp;&nbsp;
            {t('signup')}
          </ModalHeader>
          <ModalCloseButton {...modelCloseButtonStyle}/>
          <ModalBody {...modalBody}>
            <Text {...createTextStyle}>{t('create')}</Text>
            <Stack {...stackStyle}>
              <Stack {...inputNameStyle}>
                <FormControl isRequired>
                  <Input {...inputStyle} type="text" placeholder={t('first_name')}
                    value={firstName} onChange={setFirstName} autoFocus />
                  { firstNameErrorMsg  && (
                    <span className={styles.errorMsg}>{ translateBackend(firstNameErrorMsg) }</span>
                  )}
                </FormControl>
                <div className={styles.columnSpacer} />
                <FormControl isRequired>
                  <Input {...inputStyle} type="text" placeholder={t('last_name')}
                    value={lastName} onChange={setLastName} />
                  { lastNameErrorMsg  && (
                    <span className={styles.errorMsg}>{ translateBackend(lastNameErrorMsg) }</span>
                  )}
                </FormControl>
              </Stack>
              <FormControl isRequired>
                <Input {...inputStyle} type="email" placeholder={t('email')}
                  value={email} onChange={setEmail} />
                { emailErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(emailErrorMsg) }</span>
                )}
              </FormControl>
              <FormControl isRequired>
                <Input
                  {...inputStyle} type="password" placeholder={`${t('password')} ${t('8char')}`}
                  value={password} onChange={setPassword} />
                { passwordErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(passwordErrorMsg) }</span>
                )}
              </FormControl>
              <FormControl isRequired>
                <InputGroup>
                <Input {...inputStyle} type="password" placeholder={t('confirm_password')}
                  value={confirmPassword} onChange={setConfirmPassword} />
                  { (password && password === confirmPassword) ?
                    (<InputRightElement {...inputStyle}
                      children={<ScaleFade {...iconFadeStyle}>
                        <CheckCircleIcon {...checkCircleIconStyle}/>
                        </ScaleFade>} />
                    ) : ((confirmPassword !== '') ?
                    (<Tooltip hasArrow label={translateBackend('passwords must match')}>
                      <InputRightElement {...inputStyle}
                      children={<ScaleFade {...iconFadeStyle}>
                        <InfoIcon {...infoIconStyle}/></ScaleFade>} />
                    </Tooltip>) : null)
                  }
                </InputGroup>
                { confirmPasswordErrorMsg && (
                  <span className={styles.errorMsg}>{
                    translateBackend(confirmPasswordErrorMsg) }</span>
                )}
              </FormControl>
              <Text {...agreeText}>{t('agreeto')}</Text>
              <Link {...termsText} as={ReactLink} to="/terms">{t('terms')}</Link>
            </Stack>
            <ModalFooter {...footerStyle}>
              <div className={styles.footer}>
                <Button {...buttonStyle as any} type="submit" isLoading={loading}>
                  {t('signup_with_email')}</Button>
                <div className={styles.or}><span>{t('or')}</span></div>
                <GoogleLoginButton onError={onGoogleLoginError}/>
              </div>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default Signup;
