import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import TopBar from '../../components/TopBar';
import axios from '../../utils/axios';
import { Card } from '../../types';
import styles from './PlayResult.module.scss';
import Revision from '../Home/Revision';
import { AppContext } from '../../AppContext';
import { gaTrack } from '../../services/analytics';
import playDoneAnim from '../../animations/play-done.json';
import Lottie from '../../components/Lottie';

interface PlayIndexParamsInterface {
  id: string;
}

const okButtonStyle = {
  width: '266px',
  height: '47px',
  margin: '40px auto',
};

const PlayResult = () => {
  const [initialized, setInitialized] = useState(false);
  const { id } = useParams<PlayIndexParamsInterface>();
  const [cards, setCards] = useState<Card[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const { t } = useTranslation('play_result');
  const context = useContext(AppContext);

  useEffect(
    () => {
      if (!initialized) {
        const getRevision = async () => {
          const response = await axios.get(`/revision/${id}`);
          const revision = response.data.data.revision;
          setCards(revision.response.cards);
          const results = revision.results;
          setTotalCount(results.length);
          setCorrectCount(results.filter((r: any) => r.result).length);

        };
        getRevision();
        setInitialized(true);
      }
    },
    [id, initialized, setInitialized],
  );

  useEffect(
    () => {
      if (cards.length > 0) {
        context.setFooterColor('#D7EFEE');
      } else {
        context.setFooterColor('');
      }
      return () => {
        context.setFooterColor('');
      };
    },
    [cards], // eslint-disable-line
  );

  return (
    <div className={styles.playResultOuter}>
      <TopBar />
      <>
        <div className={styles.playResult}>
          <div className={styles.title}>
            {totalCount === correctCount ? t('well_done') : t('good_job')}
          </div>
          <Lottie
            style={{ width: 120, height: 120 }}
            className={styles.image}
            animationData={playDoneAnim}
            segments={[0, 70]}
            rendererSettings={{
              progressiveLoad: true,
              hideOnTransparent: false,
            }}
            play
          />
          <div className={styles.scores}>
            <div className={styles.left}>
              <div>
                <div className={styles.challenged}>{totalCount}</div>
                <div className={styles.scoreName}>{t('challenged')}</div>
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.right}>
              <div>
                <div className={styles.correct}>{correctCount}</div>
                <div className={styles.scoreName}>{t('correct')}</div>
              </div>
            </div>
          </div>
          <Button
            as={Link}
            onClick={() => { gaTrack('Play', 'Completed game'); }}
            to="/play"
            textTransform="uppercase"
            {...okButtonStyle}
          >
            {t('other_decks')}
          </Button>
        </div>
        { cards.length > 0 && (
        <div className={styles.revision}>
          <div className={styles.revisionTitle}>{t('need_further_revision')}</div>
          <div className={styles.revisionInner}>
            <Revision cards={cards} />
          </div>
        </div>
        )}
      </>
    </div>
  );
};

export default PlayResult;
