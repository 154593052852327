import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { AppContext } from '../../AppContext';
import styles from './PlayModal.module.scss';

interface PlayModalInterface {
  onClose: () => void;
}

const buttonStyle = {
  width: '252px',
  bg: 'white',
  fontWeight: 700,
  color: ' #2D65C2',
  variant: 'none',
  marginBottom: '34px',
  borderRadius: '20px',
  maxWidth: 'calc(100% - 40px)',
  marginLeft: '20px',
  marginRight: '20px',
  height: 'auto',
  padding: '14px',
};

const PlayModal: React.FC<PlayModalInterface> = ({ onClose }) => {
  const [closing, setClosing] = useState(false);
  const { t } = useTranslation('tabbar');

  const context = useContext(AppContext);

  const onOutsideClick = () => {
    setClosing(true);
    setTimeout(() => onClose(), 200);
  };

  return <div className={classnames(styles.playModal, { [styles.closing] : closing })}>
    <OutsideClickHandler onOutsideClick={onOutsideClick} >
      <div className={styles.inner}>
        <h4 className={styles.title}>
          {t('play')}
        </h4>
        <h4 className={styles.text}>
          <Trans
            t={t}
            i18nKey="you_have_remembered_n"
            values={{
              count: context.todayCount,
              target: context.currentUser?.dailyGoal,
            }}
          >
            Today you remembered
            <span className={styles.number}>
              {context.todayCount} / {context.currentUser?.dailyGoal}
            </span>
            cards
          </Trans>
        </h4>
        <Button
          {...buttonStyle}
          as={Link}
          whiteSpace="break-spaces"
          to="/play/auto?limit=20"
          onClick={onOutsideClick}
        >
          {t('quick_play')}
        </Button>
        <Button
          {...buttonStyle}
          as={Link}
          whiteSpace="break-spaces"
          to="/play/decks"
          onClick={onOutsideClick}
        >
          {t('choose_a_deck_to_play')}
        </Button>
      </div>
    </OutsideClickHandler>
  </div>;
};

export default PlayModal;
