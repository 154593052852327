import data from './words.json';
import { Language } from '../../types';

type SupportedLanguage = 'en' | 'fr' | 'zh' | 'es' | 'ja';

const generateRandomWords = (
  language: Language, numOfWords: number, capitalize: boolean): string[] => {
  let lang: SupportedLanguage =
    ['en', 'fr', 'zh', 'es', 'ja'].includes(language) ? language as SupportedLanguage : 'en';

  if (language === 'yue') {
    lang = 'zh';
  }

  const shuffled = data[lang].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numOfWords).map(
    w => (capitalize && w) ? (w[0].toUpperCase() + w.slice(1)) : w);
};

export default generateRandomWords;
