import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@chakra-ui/react';

import styles from './FriendsTabRow.module.scss';
import { User } from '../../types';
import getFileUrl from '../../utils/getFileUrl';

interface FriendTabRowInterface {
  user: User;
  actionButtons?: ReactElement;
  message?: ReactElement;
}

const avatarStyle = {
  w: '60px',
  h: '60px',
  marginRight: {
    base: '21px',
    sm: '46px',
  },
  border: '0.5px solid #97ACD9',
  boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
};

const FriendTabRow: React.FC<FriendTabRowInterface> =
({ user, actionButtons, message }) => {

  return <div className={styles.row}>
    <Link className={styles.left} to={`/user/${user.username}`}>
      <Avatar
        { ...avatarStyle }
        name={user.fullName}
        src={getFileUrl(user.avatarFile)}
      />
      <div>
        <h4 className={styles.fullName}>
          { user.fullName }
        </h4>
        {message}
      </div>
    </Link>

    <div className={styles.buttons}>
      {actionButtons}
    </div>
  </div>;
};

export default FriendTabRow;
