import { useState, Dispatch, SetStateAction } from 'react';

const useInput = <T>(
  defaultValue: T,
  getValue: (event: any) => T = event => (event.currentTarget.value)):
  [T, ((event: any) => void), Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(defaultValue);
  return [value, (event: any) => {
    setValue(getValue(event));
  }, setValue];
};

export default useInput;
