import { useState, useContext, useEffect, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as ReactLink, useLocation } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, Button, ModalBody, Input,
  ModalFooter, VStack, ModalHeader, ModalCloseButton, FormControl, Link,
  ModalHeaderProps,
} from '@chakra-ui/react';

import axios  from '../../utils/axios';
import styles from './Login.module.scss';
import useInput from '../../hooks/useInput';
import { AppContext } from '../../AppContext';
import { StateType } from '../../types';
import GoogleLoginButton from '../../components/GoogleLoginButton';

import analytics, { gaTrack } from '../../services/analytics';
import variables from '../../variables.module.scss';

interface ErrorType {
  value: string;
  msg: string;
  param: string;
}

const modalContentStyle: ModalHeaderProps = {
  borderRadius: '2xl',
  padding: '10px',
};

const linkStyle = {
  fontFamily: variables.titleFontFamily,
  color: '#c5d2e8',
  letterSpacing: '0.02em',
  fontWeight: '500',
  _focus: { outline: 0 },
};

const forgotPasswordStyle = {
  fontFamily: variables.titleFontFamily,
  color: '#F79411',
  fontWeight: '200',
  textDecoration: 'underline',
  alignSelf: 'flex-end',
  paddingTop: '10px',
};

const modalHeaderStyle: ModalHeaderProps = {
  fontFamily: variables.titleFontFamily,
  letterSpacing: '0.02em',
  color: '#0FD4CB',
  fontWeight: '500',
  marginBottom: '20px',
  display: 'flex',
  flexFlow: 'row',
  textTransform: 'uppercase',
};

const modelCloseButtonStyle = {
  margin: '6px',
  size: 'md',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  borderRadius: '50%',
};

const modalBody = {
  paddingTop: '20px',
  paddingBottom: '40px',
  paddingRight: { base: '15px', md: '50px', lg: '80px' },
  paddingLeft: { base: '15px', md: '50px', lg: '80px' },
  maxWidth: '375px',
  margin: '0 auto',
};

const stackStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  paddingBottom: '30px',
};

const inputStyle = {
  fontFamily: variables.normalFontFamily,
  padding: '26px',
  marginTop: '20px',
  border: '0px',
  borderRadius: '5px',
  bg: '#6dd8e0',
  outline: 'none',
  _placeholder: { color: 'white',
    textTransform: 'uppercase' },
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
};

const buttonStyle = {
  bg: 'white',
  height: '3rem',
  letterSpacing: '0.01em',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  fontWeight: '600',
  _focus: { outline: 0 },
  width: 'calc(100% - 30px)',
  margin: '0 15px',
  fontSize: '14px',
  textTransform: 'none',
  fontFamily: variables.normalFontFamily,
};

interface LoginInterface {
  isOpen: boolean;
}

const Login: React.FC<LoginInterface> = ({ isOpen }) => {
  const { t } = useTranslation('login');
  const translateBackend = useTranslation('backend').t;

  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation<StateType>();

  const [email, setEmail] = useInput('');
  const [password, setPassword] = useInput('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      analytics.page();
      context.setCurrentTab('account');
    },
    [context],
  );

  const onSubmit = useCallback(
    async () => {
      setLoading(true);
      try {
        const response = await axios.post('/auth/login', {
          email, password,
        });
        context.updateToken(response.data.data.token);

        gaTrack('Login', 'Submit login');
        history.replace(location.state?.referrer || '/');

      } catch (err) {
        const errorsMap: Record<string, ErrorType> = {};
        err.response.data.data.errors.forEach((error: ErrorType) => {
          errorsMap[error.param] = error;
        });

        if (errorsMap.email) {
          setEmailErrorMsg(errorsMap.email.msg);
        } else {
          setEmailErrorMsg('');
        }

        if (errorsMap.password) {
          setPasswordErrorMsg(errorsMap.password.msg);
        } else {
          setPasswordErrorMsg('');
        }
      }

      setLoading(false);
    },
    [context, email, history, password, location.state?.referrer],
  );

  const onClose = () => {
    history.replace('/');
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const onGoogleLoginError = (msg: string) => {
    setEmailErrorMsg(msg);
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent {...modalContentStyle}>
        <form onSubmit={onFormSubmit}>
          <ModalHeader {...modalHeaderStyle}>
            {t('login')} &nbsp;&nbsp;&nbsp;
            <span style={{ color: '#16B5DF' }}>|</span>&nbsp;&nbsp;&nbsp;
            <Link {...linkStyle} as={ReactLink} onClick={() => gaTrack('Signup', 'Click for signup page')} to="/signup" >
              {t('signup')}
            </Link>
          </ModalHeader>
          <ModalCloseButton {...modelCloseButtonStyle}/>
          <ModalBody {...modalBody}>
            <VStack {...stackStyle}>
              <FormControl>
                <Input {...inputStyle} type="email" placeholder={t('placeholder_email') }
                value={email} onChange={setEmail} autoFocus />
                { emailErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(emailErrorMsg) }</span>
                )}
              </FormControl>
              <FormControl>
                <Input {...inputStyle} type="password" placeholder={t('placeholder_password')}
                value={password} onChange={setPassword} />
                { passwordErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(passwordErrorMsg) }</span>
                )}
              </FormControl>
              <Link {...forgotPasswordStyle} as={ReactLink} to="/forgotPassword" >
                {t('forgot_password')}
              </Link>
            </VStack>
            <ModalFooter {...footerStyle}>
              <div className={styles.footer}>
                <Button {...buttonStyle as any} type="submit" isLoading={loading}>
                  {t('login_with_email')}</Button>
                <div className={styles.or}><span>{t('or')}</span></div>
                <GoogleLoginButton onError={onGoogleLoginError} />
              </div>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default Login;
