import { useTranslation, Trans } from 'react-i18next';

import createFlashcardIcon from '../../images/create-flashcard.svg';
import reviewCardIcon from '../../images/review-your-card.svg';
import enhanceMemoryIcon from '../../images/enhance-memory.svg';
import stepArrowIcon from '../../images/step-arrow.svg';

import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Welcome.module.scss';

const Flashcard = () => {
  const { t } = useTranslation('home');
  return <div className={styles.step}>
    <div className={styles.createFlashcardIcon}>
      <img src={createFlashcardIcon} alt="create flashcard" />
    </div>
    <Trans t={t} i18nKey="create_flashcard">
      Create flashcard <br /> (NEW CARD)
    </Trans>
  </div>;
};

const ReviewCard = () => {
  const { t } = useTranslation('home');
  return <div className={styles.step}>
    <div className={styles.reviewCardIcon}>
      <img src={reviewCardIcon} alt="review your card" />
    </div>
    <Trans t={t} i18nKey="review_your_card" >
      Create flashcard <br /> (NEW CARD)
    </Trans>
  </div>;
};

const EnhanceMemory = () => {
  const { t } = useTranslation('home');
  return <div className={styles.step}>
    <div className={styles.enhanceMemoryIcon}>
      <img src={enhanceMemoryIcon}  alt="enhance memory" />
    </div>
    <Trans t={t} i18nKey="enhance_memory">
      Enhance memory <br /> (Play)
    </Trans>
  </div>;
};

const Steps = () => {
  return <div className={styles.steps}>
    <Flashcard />
    <img className={styles.stepArrowIcon} src={stepArrowIcon} alt="step arrow"/>
    <ReviewCard />
    <img className={styles.stepArrowIcon} src={stepArrowIcon} alt="step arrow"/>
    <EnhanceMemory />
  </div>;
};

const Welcome = () => {
  const { t } = useTranslation('home');
  return <div>
    <div className={styles.welcomeToCardoze}>{t('welcome_to_cardoze')}</div>
    <div className={styles.threeStepsToSuccess}>{t('3_steps_to_success')}</div>
    <div className={styles.desktop}>
      <Steps />
    </div>
    <div className={styles.mobile}>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        className={styles.steps}
        pagination
      >
        <SwiperSlide>
          <Flashcard />
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard />
        </SwiperSlide>
        <SwiperSlide>
          <EnhanceMemory />
        </SwiperSlide>
      </Swiper>

    </div>
  </div>;
};

export default Welcome;
