import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const buttonStyle = {
  width: '266px',
  height: '47px',
  marginTop: '10px',
  backgroundColor: '#ffffff',
  border: '1px solid #2d65c2',
  color: '#2d65c2',
};

const AnkiUploadButton: React.FC = () => {
  const { t } = useTranslation('create_deck_form');
  const history = useHistory();

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      history.push({
        pathname: '/anki',
        state: { f: event.target.files[0] },
      });
    }
  };

  const inputFileRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (inputFileRef.current !== null) {
      inputFileRef.current.click();
    }
  };

  return <>
    <Button onClick={onClick} {...buttonStyle}>
      {t('import_from_anki')}
    </Button>
    <input
      onChange={onChange}
      ref={inputFileRef}
      style={{ display: 'none' }}
      type="file"
    />
  </>;
};

export default AnkiUploadButton;
