import { useState, useEffect } from 'react';

import { IconButton } from '@chakra-ui/react';
import getFileUrl from '../../utils/getFileUrl';
import generateRandomWords from '../../utils/generateRandomWords';
import { Card } from '../../types';
import styles from './ImageToWordMC.module.scss';
import { useTranslation } from 'react-i18next';
import playAudioIcon from '../../images/play-audio.svg';
import MCButton from './MCButton';
import axios from '../../utils/axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import Video from '../../components/Video';

interface ImageToWordMCInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const ImageToWordMC: React.FC<ImageToWordMCInterface> = ({ card, setResult, isShowingAnswer }) => {
  const [choices, setChoices] = useState<string[]>([]);
  const [answer, setAnswer] = useState('');
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation('play');
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(
    () => {
      const getChoices = async () => {
        setSpin(true);
        let fetchedChoices = [];
        try {
          const response = await axios.get('/revision/choices', { params: { cardId: card.id } });
          fetchedChoices = response.data.data.cards.map((c: any) => c.title);
          fetchedChoices = [...(new Set(fetchedChoices.filter((c: any) => c)).values())];
        } catch {
          // do nothing
        }

        setChoices(
          generateRandomWords(
            card.deck.language, 3 - fetchedChoices.length, /[A-Z]/.test(card.title[0]))
            .concat([card.title]).concat(fetchedChoices).sort(() => 0.5 - Math.random()),
        );
        setSpin(false);
      };
      getChoices();

    },
    [card, setChoices],
  );

  useEffect(
    () => {
      setAnswer('');
    },
    [setAnswer, card],
  );

  const onButtonClick = (value: string) => {
    setAnswer(value);
    setResult(value === card.title);
  };

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const imageUrl = getFileUrl(card.imageFile);

  return <div className={styles.imageToWordMC}>
    <div className={styles.imageWrapper}>
      <Video imageUrl={imageUrl} className={styles.image}>
        <div className={styles.image}
          style={{ backgroundImage: `url(${imageUrl}` }} />
      </Video>
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        height={card.imageFile ? '40px' : '60px'}
        width={card.imageFile ? '40px' : '60px'}
        onClick={onPlayClicked}
        disabled={!card.audioFile || playing}
        icon={
          <img
            alt="play"
            className={!card.imageFile ? styles.largeAudio : undefined}
            src={playAudioIcon}
          />
        }
        className={card.imageFile ? styles.audio : undefined}
      />
    </div>

    <div className={styles.instruction}>{t('choose_the_correct_word')}</div>

    <LoadingSpinner spin={spin}>
      <div className={styles.choices}>
        {choices.map(word => (
          <MCButton isShowingAnswer={isShowingAnswer}
            isAnswer={card.title === word}
            value={word}
            key={word}
            checked={answer === word}
            onClick={onButtonClick} />
        ))}
      </div>
    </LoadingSpinner>
  </div>;
};

export default ImageToWordMC;
