import React from 'react';
import ReactDOM from 'react-dom';
import 'ress';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/autoplay';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'spl.js/dist/index';

import './locales/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './global.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

SwiperCore.use([Navigation, Pagination, Autoplay]);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
