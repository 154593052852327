import { useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import useToast from '../../hooks/useToast';
import { SUPPORTED_SPECIAL_IMAGE_TYPES } from '../../constants';

type FileType = 'image' | 'video';

const useImageInput = (onFileSelected: (
  dataUrl: string, fileType: FileType, fileExt: string, fileSize: number) => void) => {

  const inputFile = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('card_form');
  const toast = useToast();

  const onInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList !== null && fileList.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const [fileType] = fileList[0].type.split('/');
        const fileExt = `.${fileList[0].name.split('.').pop()}`;
        const fileSize = fileList[0].size;

        if (SUPPORTED_SPECIAL_IMAGE_TYPES.indexOf(fileExt) >= 0) {
          if (fileSize > 1024 * 1024 * 2) {
            toast({
              title: t('error_title'),
              description: t('file_size_exceeded'),
              status: 'error',
            });
            return;
          }
        } else if (fileType !== 'image') {
          toast({
            title: t('error_title'),
            description: t('unsupported_format', { ext: fileExt }),
            status: 'error',
          });
          return;
        }

        onFileSelected(
          fileReader.result as string,
          fileType as FileType,
          fileExt,
          fileSize);
      };
      fileReader.readAsDataURL(fileList[0]);
    }
  };

  const clickImageInput = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const component = <input
    onChange={onInputChange}
    type="file"
    accept="image/*,video/*"
    ref={inputFile}
    style={{ display: 'none' }}
  />;

  return { clickImageInput, component };
};

export default useImageInput;
