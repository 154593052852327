import { useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation  } from 'react-i18next';
import { getUserLocale } from 'get-user-locale';

import en from './en.json';
import zh from './zh.json';
import { SystemLanguage } from '../types';
import useStore from '../hooks/useStore';

const initializeLanguage = () => {
  const locale = getUserLocale();
  if (locale.startsWith('zh')) {
    return 'zh';
  }
  return 'en';
};

i18n.use(initReactI18next)
  .init({
    resources: {
      en,
      zh,
    },
    lng: initializeLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

const useI18n = () => {
  const translation = useTranslation();
  const { systemLanguage, setStores } = useStore();

  useEffect(
    () => {
      if (systemLanguage) {
        translation.i18n.changeLanguage(systemLanguage);
      }
    },
    [systemLanguage, translation.i18n],
  );

  const setLanguage = (lang: SystemLanguage) => {
    translation.i18n.changeLanguage(lang);
    setStores({ systemLanguage: lang });
  };

  return {
    ...translation, setLanguage, systemLanguage,
  };
};

export default useI18n;
