import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, ModalContent, ModalCloseButton, ModalBody,
  Modal, ModalOverlay } from '@chakra-ui/react';

import styles from './CreateDeckForm.module.scss';
import useInput from '../../hooks/useInput';
import axios from '../../utils/axios';
import { ErrorType } from '../../types';
import { gaTrack } from '../../services/analytics';
import useToast from '../../hooks/useToast';
import AnkiUploadButton from '../../pages/Anki/AnkiUploadButton';

interface DeckFormInterface {
  onSave: (deckNid: string, deckId: string) => void;
}

const saveButtonStyle = {
  width: '266px',
  color: 'white',
  height: '47px',
};

const CreateDeckForm: React.FC<DeckFormInterface> = ({ onSave }) => {
  const { t } = useTranslation('create_deck_form');
  const { t: translateBackend } = useTranslation('backend');

  const [isLoading, setIsLoading] = useState(false);
  const [name, onNameChange] = useInput('');
  const toast = useToast();
  const [nameErrorMsg, setNameErrorMsg] = useState('');

  const onSaveClicked = async () => {
    setIsLoading(true);
    gaTrack('Deck', 'Save new deck');
    try {
      const response = await axios.post('/decks', { name });
      onSave(
        response.data.data.deck.nid, response.data.data.deck.id);

      toast({
        title: t('deck_created'),
        description: t('deck_created_desc', { name }),
        status: 'success',
      });

    } catch (err) {
      const errorsMap: Record<string, ErrorType> = {};
      err.response.data.data.errors.forEach((error: ErrorType) => {
        errorsMap[error.param] = error;
      });

      if (errorsMap.name) {
        setNameErrorMsg(errorsMap.name.msg);
      }
    }

    setIsLoading(false);
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSaveClicked();
  };

  return <form className={styles.deckForm} onSubmit={onFormSubmit}>
    <div className={styles.title}>{t('name_of_deck')}</div>
    <Input
      variant="flushed"
      placeholder={t('placeholder')}
      value={name}
      onChange={onNameChange}
      autoFocus
    />

    { nameErrorMsg && (
      <span className={styles.errorMsg}>
        { translateBackend(nameErrorMsg) }
      </span>
    )}
    <div className={styles.buttonWrapper}>
      <Button
        {...saveButtonStyle}
        isLoading={isLoading}
        type="submit"
      >
        {t('save')}
      </Button>
      <AnkiUploadButton />
    </div>
  </form>;
};

interface DeckFormModalInterface {
  onClose: (isUpdated: boolean, deckNid?: string, deckId?: string) => void;
  isOpen: boolean;
}

const CreateDeckFormModal: React.FC<DeckFormModalInterface> = ({ isOpen, onClose }) => {
  const onCloseModal = () => {
    onClose(false);
  };
  return <Modal isOpen={isOpen} onClose={onCloseModal} size="md" isCentered>
    <ModalOverlay />
    <ModalContent margin="20px" maxHeight="calc(100vh - 40px)" overflowY="auto" overflow="auto">
      <ModalCloseButton />
      <ModalBody>
        <CreateDeckForm
          onSave={(deckNid, deckId) => onClose(true, deckNid, deckId)}
        />
      </ModalBody>
    </ModalContent>
  </Modal>;
};

export default CreateDeckFormModal;
