import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './AccountDeckList.module.scss';
import { Deck } from '../../types';
import DeckBox from '../../components/DeckBox';

interface AccountDeckListInterface {
  decks: Deck[];
  onDeckUpdated: () => void;
  locked: boolean;
}

const AccountDeckList: React.FC<AccountDeckListInterface> = ({ decks, onDeckUpdated, locked }) => {
  return (
    <div>
      <div className={styles.decks}>
        {decks.map(deck => (
          <DeckBox
            deck={deck}
            key={deck.id}
            onDeckUpdated={onDeckUpdated}
            type="account"
            locked={locked}
          />
        ))}
      </div>
      <Swiper
        slidesPerView="auto"
        spaceBetween={30}
        className={styles.decksMobile}
      >
        {decks.map(deck => (
          <SwiperSlide className={styles.slide} key={deck.id}>
            <DeckBox
              deck={deck}
              key={deck.id}
              onDeckUpdated={onDeckUpdated}
              type="account"
              locked={locked}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AccountDeckList;
