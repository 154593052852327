import React, { useRef, useEffect, useState, DetailedHTMLProps, SyntheticEvent } from 'react';
import equal from 'fast-deep-equal/es6/react';
import cloneDeep from 'lodash.clonedeep';
import lottie, {
  AnimationItem,
  AnimationConfig,
  AnimationDirection,
  AnimationEventCallback,
  AnimationSegment } from 'lottie-web';

type LottieInterface = DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
  Pick<AnimationConfig, 'loop' | 'renderer' | 'rendererSettings' | 'audioFactory'> &
  {
    play?: boolean,
    goTo?: number,
    speed?: number,
    direction?: AnimationDirection,
    segments?: AnimationSegment | AnimationSegment[],
    onComplete?: AnimationEventCallback,
    onLoopComplete?: AnimationEventCallback,
    onEnterFrame?: AnimationEventCallback,
    onSegmentStart?: AnimationEventCallback,
    animationData?: any,
    path?: string,
  };

const Lottie: React.FC<LottieInterface> = ({
  animationData,
  path,
  play,
  speed,
  direction,
  segments: segmentsIn,
  goTo,
  renderer,
  loop,
  rendererSettings: rendererSettingsIn,
  audioFactory,
  onLoad,
  onComplete,
  onLoopComplete,
  onEnterFrame,
  onSegmentStart,
  ...props
}) => {
  const animElementRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<AnimationItem>();

  const [ready, setReady] = useState(false);

  const [segments, setSegments] = useState(segmentsIn);

  useEffect(
    () => {
      if (!equal(segments, segmentsIn)) {
        setSegments(segmentsIn);
      }
    },
    [segmentsIn, segments],
  );

  const [rendererSettings, setRendererSettings] = useState(rendererSettingsIn);

  useEffect(
    () => {
      if (!equal(rendererSettings, rendererSettingsIn)) {
        setRendererSettings(rendererSettingsIn);
      }
    },
    [rendererSettingsIn, rendererSettings],
  );

  useEffect(
    () => () => animRef.current?.removeEventListener('complete', onComplete),
    [onComplete]);

  useEffect(
    () => () => animRef.current?.removeEventListener('loopComplete', onLoopComplete),
    [onLoopComplete],
  );

  useEffect(
    () => () => animRef.current?.removeEventListener('enterFrame', onEnterFrame),
    [onEnterFrame],
  );

  useEffect(
    () => () => animRef.current?.removeEventListener('segmentStart', onSegmentStart),
    [onSegmentStart],
  );

  useEffect(
    () => {
      const parseAnimationData = () => {
        if (animationData == null || typeof animationData !== 'object') {
          return animationData;
        }

        if (typeof animationData.default === 'object') {
          return cloneDeep(animationData.default);
        }
        return cloneDeep(animationData);
      };

      if (animElementRef.current === null) {
        return;
      }

      animRef.current = lottie.loadAnimation({
        path,
        renderer,
        rendererSettings,
        audioFactory,
        animationData: parseAnimationData(),
        container: animElementRef.current,
        loop: false,
        autoplay: false,
      });

      const onDomLoaded = (e: SyntheticEvent<HTMLDivElement, Event>) => {
        setReady(true);
        if (onLoad) {
          onLoad(e);
        }
      };

      animRef.current?.addEventListener('DOMLoaded', onDomLoaded);

      return () => {
        animRef.current?.removeEventListener('DOMLoaded', onDomLoaded);
        setReady(false);
        animRef.current?.destroy();
        animRef.current = undefined;
      };
    },
    [loop, renderer, rendererSettings, animationData, path, audioFactory], // eslint-disable-line
  );

  useEffect(
    () => {
      if (onComplete) {
        animRef.current?.addEventListener('complete', onComplete);
      }
    },
    [onComplete],
  );

  useEffect(
    () => {
      if (onLoopComplete) {
        animRef.current?.addEventListener('loopComplete', onLoopComplete);
      }
    },
    [onLoopComplete],
  );

  useEffect(
    () => {
      if (onEnterFrame) {
        animRef.current?.addEventListener('enterFrame', onEnterFrame);
      }
    },
    [onEnterFrame],
  );

  useEffect(
    () => {
      if (onSegmentStart) {
        animRef.current?.addEventListener('segmentStart', onSegmentStart);
      }
    },
    [onSegmentStart],
  );

  useEffect(
    () => {
      if (!ready) {
        return;
      }
      if (animRef.current && loop !== undefined) {
        (animRef.current as any).loop = loop;
      }
    },
    [ready, loop],
  );

  const wasPlayingSegmentsRef = useRef(false);

  useEffect(
    () => {
      if (!ready) {
        return;
      }

      const playReverse = (lastFrame: number | undefined) => {
        if (lastFrame !== undefined) {
          animRef.current?.goToAndPlay(lastFrame, true);
        }

        if (direction !== undefined) {
          animRef.current?.setDirection(direction);
        }
      };

      if (play === true) {
        const force = true;
        if (segments) {
          animRef.current?.playSegments(segments, force);
          wasPlayingSegmentsRef.current = true;

          if (direction === -1) {
            const lastSeg = segments[1];
            const lastFrame = typeof lastSeg === 'number' ? lastSeg : lastSeg[1];
            playReverse(lastFrame);
          }
        } else {
          if (wasPlayingSegmentsRef.current) animRef.current?.resetSegments(force);
          wasPlayingSegmentsRef.current = false;

          if (direction === -1) {
            const lastFrame = animRef.current?.getDuration(true);
            playReverse(lastFrame);
          } else {
            animRef.current?.play();
          }
        }
      } else if (play === false) {
        animRef.current?.pause();
      }
    },
    [play, segments, ready], // eslint-disable-line
  );

  useEffect(
    () => {
      if (!ready) {
        return;
      }
      if (Number.isNaN(speed)) {
        return;
      }
      if (speed !== undefined) {
        animRef.current?.setSpeed(speed);
      }
    },
    [speed, ready],
  );

  useEffect(
    () => {
      if (!ready) {
        return;
      }
      if (direction !== undefined) {
        animRef.current?.setDirection(direction);
      }
    },
    [direction, ready],
  );

  useEffect(
    () => {
      if (!ready) {
        return;
      }
      if (goTo == null) {
        return;
      }
      const isFrame = true;
      if (play) {
        animRef.current?.goToAndPlay(goTo, isFrame);
      } else {
        animRef.current?.goToAndStop(goTo, isFrame);
      }
    },
    [goTo, play, ready],
  );

  return (
    <div
      {...props}
      ref={animElementRef}
    />
  );
};

export default Lottie;
