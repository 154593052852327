import { Modal, ModalOverlay, ModalContent, Button, ModalBody, Input,
  ModalFooter, VStack, ModalHeader, ModalCloseButton, FormControl,
  ModalHeaderProps,
  IconButton, Image,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import axios from '../../utils/axios';
import useInput from '../../hooks/useInput';
import styles from './ResetPassword.module.scss';
import resetPasswordBackIcon from '../../images/resetpassword-back.svg';
import successIcon from '../../images/success.svg';
import variables from '../../variables.module.scss';

interface ErrorType {
  value: string;
  msg: string;
  param: string;
}

const modalContentStyle: ModalHeaderProps = {
  borderRadius: '2xl',
  padding: '10px',
};

const modalHeaderStyle: ModalHeaderProps = {
  marginBottom: '20px',
  display: 'flex',
  flexFlow: 'row',
};

const modelCloseButtonStyle = {
  margin: '6px',
  size: 'md',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  borderRadius: '50%',
};

const modalBody = {
  display: 'flex',
  flexFlow: 'column',
  paddingTop: '20px',
  paddingBottom: '40px',
  paddingRight: { base: '15px', md: '50px', lg: '80px' },
  paddingLeft: { base: '15px', md: '50px', lg: '80px' },
};

const stackStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  paddingBottom: '30px',
};

const inputStyle = {
  fontFamily: variables.normalFontFamily,
  padding: '26px',
  marginTop: '20px',
  border: '1px',
  borderRadius: '5px',
  borderColor: '#f79411',
  bg: 'white',
  outline: 'none',
  _placeholder: { color: '#c4c4c4',
    textTransform: 'uppercase' },
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
};

const buttonStyle = {
  bg: 'white',
  width: '100%',
  height: '3rem',
  letterSpacing: '0.01em',
  margin: '30px',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  fontWeight: '500',
  _focus: { outline: 0 },
};

const ResetPassword = () => {

  const { t } = useTranslation('reset_password');
  const [token] = useQueryParam('token', StringParam);
  const translateBackend = useTranslation('backend').t;
  const [password, setPassword] = useInput('');
  const [confirmPassword, setConfirmPassword] = useInput('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const [tokenErrorMsg, setTokenErrorMsg] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const history = useHistory();
  const onClose = () => {
    history.replace('/');
  };

  const toLogin = () => {
    history.push('/login');
  };

  const backStyle = {
    fontFamily: variables.titleFontFamily,
    color: '#F79411',
    letterSpacing: '0.2px',
    fontWeight: '500',
  };

  const successIconStyle = {
    alignSelf: 'center',
    marginBottom: '20px',
  };

  const onSubmit = useCallback(
    async () => {
      try {
        const response = await axios.put('/resetPassword', {
          token, password, confirmPassword,
        });

        if (response) {
          setSubmitSuccess(true);
          setPasswordErrorMsg('');
          setConfirmPasswordErrorMsg('');
        }

      } catch (err) {
        const errorsMap: Record<string, ErrorType> = {};
        err.response.data.data.errors.forEach((error: ErrorType) => {
          errorsMap[error.param] = error;
        });

        if (errorsMap.password) {
          setPasswordErrorMsg(errorsMap.password.msg);
        } else {
          setPasswordErrorMsg('');
        }

        if (errorsMap.confirmPassword) {
          setConfirmPasswordErrorMsg(errorsMap.confirmPassword.msg);
        } else {
          setConfirmPasswordErrorMsg('');
        }

        if (errorsMap.token) {
          setTokenErrorMsg(errorsMap.token.msg);
        } else {
          setTokenErrorMsg('');
        }

      }
    },
    [token, password, confirmPassword],
  );

  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent {...modalContentStyle}>
        <ModalHeader {...modalHeaderStyle}>
          { submitSuccess &&
            <div className={styles.headerBlock}>
              <div className={styles.backLogin}>
                <IconButton icon={<img alt="back to" src={resetPasswordBackIcon} />}
                  aria-label="back to" onClick={toLogin} variant="link">
                </IconButton>
                <Button {...backStyle} variant="link" onClick={toLogin}>
                  {t('login').toUpperCase()}
                </Button>
              </div>
            </div>
          }
        </ModalHeader>
        <ModalCloseButton {...modelCloseButtonStyle}/>
        <ModalBody {...modalBody}>
            {!submitSuccess ?
              <>
              {tokenErrorMsg ?
                <>
                  <div className={styles.messageStyle}>{translateBackend(tokenErrorMsg)}</div>
                  <div className={styles.messageStyle}>{t('token_problem')}</div>
                </> :
                <div className={styles.messageStyle}>{t('msg_instruction')}</div>
              } </>
              :
              <>
                <Image
                {...successIconStyle}
                boxSize="35px"
                objectFit="cover"
                src={successIcon}
                alt="success"/>
                <div className={styles.messageStyle}>
                  {t('msg_success')}
                </div>
              </>
            }
          <VStack {...stackStyle}>
            { !submitSuccess &&
              <FormControl>
                <Input {...inputStyle} type="password"
                  placeholder={t('placeholder_password') }
                value={password} onChange={setPassword} autoFocus />
                { passwordErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(passwordErrorMsg) }</span>
                )}
                <Input {...inputStyle} type="password"
                  placeholder={t('placeholder_confirm_password') }
                value={confirmPassword} onChange={setConfirmPassword} autoFocus />
                { confirmPasswordErrorMsg && (
                  <span className={styles.errorMsg}>
                    { translateBackend(confirmPasswordErrorMsg) }</span>
                )}
              </FormControl>
            }
          </VStack>
          <ModalFooter {...footerStyle}>
            { !submitSuccess &&
              <Button {...buttonStyle} onClick={onSubmit}>
                {t('submit')}</Button>
            }
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResetPassword;
