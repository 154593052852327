import styles from './Checkbox.module.scss';
import checkboxImage from '../../images/checkbox-checked.svg';
import checkboxUncheckedImage from '../../images/checkbox-unchecked.svg';

interface CheckboxInterface {
  checked: boolean;
  onClick: () => void;
}

const Checkbox: React.FC<CheckboxInterface> = ({ checked, onClick }) => {
  return (
    <img
      src={checked ? checkboxImage : checkboxUncheckedImage}
      alt="checkbox"
      onClick={onClick}
      className={styles.checkbox}
    />
  );
};

export default Checkbox;
