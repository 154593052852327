import { Spinner } from '@chakra-ui/react';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerInterface {
  spin: boolean;
  height?: number;
}

const LoadingSpinner: React.FC<LoadingSpinnerInterface> = ({ spin, children, height }) => {
  return <>
    {
      spin &&
        <div className={styles.spinnerWrapper} style={{ height: `${height || 142 }px` }}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.600"
            size="xl"/>
        </div>
    }
    {!spin && children}
  </>;

};

export default LoadingSpinner;
