import { useState, useCallback, useEffect } from 'react';

const useThrottle = (func: (...args: any[]) => void, duration = 1000) => {
  const [id, setId] = useState<number>(0);
  const [args, setArgs] = useState<any[]>();

  const callback = useCallback(
    (...a) => {
      if (id) {
        clearTimeout(id);
      }
      setId(window.setTimeout(() => setArgs(a), duration));
    },
    [id, setId, setArgs, duration],
  );

  useEffect(
    () => {
      if (args) {
        func(...args);
      }
    },
    [args], // eslint-disable-line
  );

  return callback;
};

export default useThrottle;
