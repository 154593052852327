import { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import seedRandom from 'seed-random';

import TopBar from '../../components/TopBar';
import styles from './DeckCardList.module.scss';
import axios from '../../utils/axios';
import { Card, Deck } from '../../types';
import getFileUrl from '../../utils/getFileUrl';
import { AppContext } from '../../AppContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import { truncateText } from '../../utils/textUtils';
import Video from '../../components/Video';
import DeckCardTopBar from './DeckCardTopBar';

interface CardBoxInterface {
  card: Card;
  deckId: string;
  index: number;
}

const addNewButtonStyle = {
  width: '368px',
  height: '53px',
  maxWidth: '100%',
  marginTop: '20px',
};

const placeholderColors = [
  '#6C7DB3',
  '#80CFBA',
  '#A6C4EA',
  '#EEB9D3',
  '#E2AA9B',
  '#FF9379',
  '#6BB2AA',
  '#E8C98F',
  '#B3C0ED',
  '#BBCCA8',
  '#FC93A4',
];

const CardBox: React.FC<CardBoxInterface> = ({ card, deckId, index }) => {
  const offset = Math.floor(seedRandom(deckId)() * placeholderColors.length);
  const imageUrl = getFileUrl(card.imageFile);

  return (
    <Link to={`/deck?id=${deckId}&cardId=${card.nid}`} className={styles.box}>
      <div className={styles.imageWrapper}>
        <Video imageUrl={imageUrl} className={styles.img}>
          <Image
            src={imageUrl}
            className={styles.img}
            fallback={
              <div className={styles.placeholder} style={{
                backgroundColor: placeholderColors[(index + offset)
                   % placeholderColors.length] }}>
                {truncateText(card.title)}
              </div>
            }
          />
        </Video>
      </div>
      <div className={styles.titleText}>{truncateText(card.title)}</div>
    </Link>
  );
};

const DeckCardList = () => {
  const { t } = useTranslation('deck_card_list');
  const [deckId] = useQueryParam('id', StringParam);

  const listRef = useRef<HTMLDivElement>(null);

  const [initialized, setInitialized] = useState(false);
  const [cursor, setCursor] = useState<string>();
  const [fetching, setIsFetching] = useState(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [deck, setDeck] = useState<Deck>();
  const [count, setCount] = useState<number>();
  const [spin, setSpin] = useState<boolean>(true);

  const context = useContext(AppContext);

  useEffect(
    () => {
      context.setCurrentTab('deck');
    },
    [context],
  );

  useEffect(
    () => {
      context.setShowTabBar(false);
      return () => {
        context.setShowTabBar(true);
      };
    },
    [], // eslint-disable-line
  );

  const shouldFetch = useCallback(
    () => {
      return !fetching &&
        (listRef.current && (window.scrollY + window.innerHeight >
          (listRef.current.offsetTop + listRef.current.scrollHeight) / 2)) &&
        cursor;
    },
    [fetching, cursor, listRef],
  );

  const getCards = useMemo(
    () => async (reload: boolean = false) => {
      setSpin(true);
      setIsFetching(true);
      try {
        const response = await axios.get(
          `/decks/${deckId}/cards`,
          { params: { cursor: reload ? undefined : cursor, descending: true } },
        );
        setCursor(response.data.data.cursor);
        if (reload) {
          setCards(response.data.data.cards);
        } else {
          setCards([...cards, ...response.data.data.cards]);
        }
        setDeck(response.data.data.deck);
        setCount(response.data.data.count);
      } finally {
        setIsFetching(false);
        setSpin(false);
      }
    },
    [cursor, cards, setCursor, setCards, setDeck, setCount, deckId],
  );

  const getCardsIfShouldFetch = useCallback(
    () => {
      if (shouldFetch()) {
        getCards();
      }
    },
    [shouldFetch, getCards],
  );

  useEffect(
    () => {
      getCardsIfShouldFetch();
    },
    [cards, getCardsIfShouldFetch],
  );

  useEffect(
    () => {
      window.addEventListener('resize', getCardsIfShouldFetch);
      window.addEventListener('scroll', getCardsIfShouldFetch);

      return () => {
        window.removeEventListener('resize', getCardsIfShouldFetch);
        window.removeEventListener('scroll', getCardsIfShouldFetch);
      };
    },
    [getCardsIfShouldFetch],
  );

  useEffect(
    () => {
      if (!initialized) {
        getCards();
        setInitialized(true);
      }
    },
    [getCards, initialized, setInitialized],
  );

  return (
    <div className={styles.outer}>
      <TopBar showBackButton={true} />
      <div className={styles.deckCardEdit}>
        <DeckCardTopBar deck={deck} count={count} getCards={getCards} />
        <div className={styles.inner}>
          <div className={styles.list} ref={listRef}>
            {cards.map((card, i) => (
              <CardBox key={card.id} card={card} deckId={deckId || ''} index={i} />
            ))}
          </div>
          <LoadingSpinner spin={spin}>
            {cards.length === 0 &&
            <div>
              <div className={styles.emptylist}>{t('empty_list')}</div>
              <div className={styles.noCard}>
                <Button
                  as={Link}
                  to={{ pathname: '/card/new/', state: { deckId } }}
                  textTransform="uppercase"
                  {...addNewButtonStyle}
                >
                  {t('add_new')}
                </Button>
              </div>
            </div>}
          </LoadingSpinner>
        </div>
      </div>
    </div>
  );
};

export default DeckCardList;
