import { useRef, useState } from 'react';
import { AlertDialog, AlertDialogOverlay, AlertDialogHeader, AlertDialogBody,
  AlertDialogFooter, AlertDialogContent, Button,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

import axios from '../../utils/axios';
import styles from './UnfriendDialog.module.scss';
import useToast from '../../hooks/useToast';

interface UnfriendDialogInterface {
  fullName: string;
  userId: string;
  onClose: (unfriended: boolean) => void;
  isOpen: boolean;
}

const UnfriendDialog: React.FC<UnfriendDialogInterface> = (
    { userId, fullName, onClose, isOpen }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('account');
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onUnfriend = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete('/friends', { data: { userId } });
      if (response.data.status === 'fail') {
        toast({
          title: t('unfriend_failed'),
          description: response.data.data.message,
          status: 'error',
        });
      } else {
        toast({
          title: t('unfriend_success'),
          description: t('unfriend_success_desc', { name: fullName }),
          status: 'success',
        });
      }

    } catch {
      toast({
        title: t('unfriend_failed'),
        description: t('unfriend_failed_desc'),
        status: 'error',
      });
    }
    setIsLoading(false);
    onClose(true);
  };

  return <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={() => onClose(false)}
    size="xl"
    isCentered
  >
    <AlertDialogOverlay>
      <AlertDialogContent margin="20px">
        <AlertDialogHeader fontSize="xl" fontWeight="bold">
          {t('unfriend_title')}
        </AlertDialogHeader>
        <AlertDialogBody fontSize="lg">
          <Trans t={t} i18nKey="are_you_sure_unfriend" name={fullName}>
            Are you sure to unfriend
            <span className={styles.unfriendUser}>{{ name: fullName }}</span>?
          </Trans>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            fontSize="16px"
            ref={cancelRef}
            variant="solid"
            onClick={() => onClose(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            variant="solid"
            fontSize="16px"
            onClick={onUnfriend}
            ml={3}
          >
            {t('unfriend')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>;
};

export default UnfriendDialog;
