import React, { useContext, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './TabBar.module.scss';
import { AppContext, TabType } from '../../AppContext';
import newImage from '../../images/new-tab.svg';
import newActiveImage from '../../images/new-tab-active.svg';
import deckImage from '../../images/deck-tab.svg';
import deckActiveImage from '../../images/deck-tab-active.svg';
import accImage from '../../images/acc-tab.svg';
import accActiveImage from '../../images/acc-tab-active.svg';
import friendImage from '../../images/friend-tab.svg';
import friendActiveImage from '../../images/friend-tab-active.svg';
import { FriendRequestNotificationCount } from '../../components/NotificationCount';
import PlayTab from './PlayTab';
import PlayModal from './PlayModal';

interface TabInterface {
  route: string;
  label: string;
  tab: TabType;
  image: string;
  imageActive: string;
}

const FriendTab: React.FC = () => {
  const tab = 'friend';
  const { t } = useTranslation('tabbar');
  const context = useContext(AppContext);
  const isActive = context.currentTab === tab;

  return <Link
    to="/friends"
    className={classnames(styles.tab, { [styles.active]: isActive })}
  >
    <div className={styles.imgWrapper}>
      <img style={{ display: isActive ? 'block' : 'none' }} src={friendActiveImage} alt={tab} />
      <img style={{ display: isActive ? 'none' : 'block' }} src={friendImage} alt={tab} />
      <FriendRequestNotificationCount className={styles.notificationCount} />
    </div>
    { t('friend') }
  </Link>;
};

const Tab: React.FC<TabInterface> = ({ route, label, image, imageActive, tab }) => {
  const context = useContext(AppContext);
  const isActive = context.currentTab === tab;

  return <Link
    to={route}
    className={classnames(styles.tab, { [styles.active]: isActive })}
  >
    <div className={styles.imgWrapper}>
      <img style={{ display: isActive ? 'block' : 'none' }} src={imageActive} alt={tab} />
      <img style={{ display: isActive ? 'none' : 'block' }} src={image} alt={tab} />
    </div>
    { label }
  </Link>;
};

const TabBar: React.FC = () => {
  const { t } = useTranslation('tabbar');

  const context = useContext(AppContext);
  const showTabBar = context.showTabBar && context.hasToken;
  const [showPlayModal, setShowPlayModal] = useState(false);

  const dataLeft: TabInterface[] = [
    {
      route: '/card/new',
      label: t('new'),
      tab: 'card',
      image: newImage,
      imageActive: newActiveImage,
    },
    {
      route: '/decks',
      label: t('deck'),
      tab: 'deck',
      image: deckImage,
      imageActive: deckActiveImage,
    },
  ];

  const dataRight: TabInterface[] = [
    {
      route: context.currentUser ? `/user/${context.currentUser.username}` : '/login',
      label: t('acc'),
      tab: 'account',
      image: accImage,
      imageActive: accActiveImage,
    },
  ];

  const onPlayClicked = useCallback(
    () => {
      if (!showPlayModal) {
        setShowPlayModal(true);
      }
    },
    [showPlayModal],
  );

  const onClosePlayModal = () => {
    setShowPlayModal(false);
  };

  return <>
    {showPlayModal && <PlayModal onClose={onClosePlayModal} />}
      <div className={styles.tabBar} style={{ display: showTabBar ? 'block' : 'none' }}>
        <div className={styles.tabBarInner}>
        {dataLeft.map(tab => (
          <Tab {...tab} key={tab.tab} />
        ))}
        <PlayTab onClick={onPlayClicked}/>
        <FriendTab />
        {dataRight.map(tab => (
          <Tab {...tab} key={tab.tab} />
        ))}
      </div>
    </div>
  </>;
};

export default TabBar;
