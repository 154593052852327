import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import Signup from '../../components/Signup';
import Login from '../../components/Login';
import ForgotPassword from '../../components/ForgotPassword';
import ResetPassword from '../../components/ResetPassword';
import Terms from '../../components/Terms';
import styles from './HomePublic.module.scss';
import logoImg from '../../images/home-public/logo.svg';
import phoneImg from '../../images/home-public/phone.png';
import enImg from '../../images/home-public/en.svg';
import zhImg from '../../images/home-public/zh.svg';
import frImg from '../../images/home-public/fr.svg';
import esImg from '../../images/home-public/es.svg';
import jpImg from '../../images/home-public/jp.svg';
import yueImg from '../../images/home-public/yue.svg';
import * as hello from '../../sfx/hello';
import CardView from '../../components/CardView';
import cardozeImg from '../../images/home-public/cardoze.svg';
import slide1Img from '../../images/home-public/slide1.svg';
import slide2Img from '../../images/home-public/slide2.svg';
import slide3Img from '../../images/home-public/slide3.svg';
import slide4Img from '../../images/home-public/slide4.svg';
import slide5Img from '../../images/home-public/slide5.svg';
import slide6Img from '../../images/home-public/slide6.svg';
import cardImg from '../../images/home-public/card_image.jpg';
import cardAudio from '../../images/home-public/card_audio.mp3';
import desktopImg from '../../images/home-public/desktop.svg';
import mobileImg from '../../images/home-public/mobile.svg';
import star4Img from '../../images/home-public/star_4.svg';
import star5Img from '../../images/home-public/star_5.svg';
import { gaTrack } from '../../services/analytics';

const HomePublic: React.FC = () => {
  const { t, i18n } = useTranslation('home_public');
  const routeMatch = useRouteMatch();
  return <div className={classnames({ [styles.zh]: i18n.language === 'zh' })}>
    <div className={styles.section1}>
      <div className={styles.section1Inner}>
        <div className={styles.topBar}>
          <img src={logoImg} alt="logo" className={styles.logo} />
          <div className={styles.topBarButtons}>
            <a href="https://blog.cardozeapp.com" onClick={() => gaTrack('Home', 'Visit blog')}
              className={styles.blogButton}>{t('blog')}</a>
            <Link to="/login" className={styles.loginButton}>{t('login')}</Link>
          </div>
        </div>
        <h1 className={styles.title}>{t('language_at_your_fingertips')}</h1>
        <h2 className={styles.subTitle}>{t('memorize_things')}</h2>
      </div>
    </div>
    <div className={styles.section2}>
      <div className={styles.wave} />
      <div className={styles.section2Inner}>
        <img src={phoneImg} className={styles.phone} alt="phone" />
        <div className={styles.section2Right}>
          <h3 className={styles.chooseLangTitle}>{t('choose_a_lang')}</h3>
          <div className={styles.audioRow}>
            <div className={styles.audio}>
              <h4 className={styles.audioLang}>{t('en')}</h4>
              <button className={styles.audioButton} onClick={() => hello.en.play()}>
                <img src={enImg} alt="en"/></button>
            </div>
            <div className={styles.audio}>
              <h4 className={styles.audioLang}>{t('zh')}</h4>
              <button className={styles.audioButton}  onClick={() => hello.zh.play()}>
                <img src={zhImg} alt="zh" /></button>
            </div>
            <div className={styles.audio} onClick={() => hello.fr.play()}>
              <h4 className={styles.audioLang}>{t('fr')}</h4>
              <button className={styles.audioButton}>
                <img src={frImg} alt="fr" /></button>
            </div>
          </div>
          <div className={styles.audioRow}>
            <div className={styles.audio}>
              <h4 className={styles.audioLang}>{t('es')}</h4>
              <button className={styles.audioButton} onClick={() => hello.es.play()}>
                <img src={esImg} alt="es" /></button>
            </div>
            <div className={styles.audio}>
              <h4 className={styles.audioLang}>{t('jp')}</h4>
              <button className={styles.audioButton} onClick={() => hello.jp.play()}>
                <img src={jpImg} alt="jp" /></button>
            </div>
            <div className={styles.audio}>
              <h4 className={styles.audioLang}>{t('yue')}</h4>
              <button className={styles.audioButton} onClick={() => hello.yue.play()}>
                <img src={yueImg} alt="yue" /></button>
            </div>
          </div>

          <div className={styles.audioRow}>
          </div>
        </div>
      </div>
      <div className={styles.section2InnerMobile}>
        <a href="https://blog.cardozeapp.com" onClick={() => gaTrack('Home', 'Visit blog')}
          className={styles.blogButton}>{t('blog')}</a>
        <Link to="/login" className={styles.loginButton}>{t('login')}</Link>
      </div>
    </div>
    <div className={styles.section3}>
      <div className={styles.section3Inner}>
        <div className={styles.section3Left}>
          <h2 className={styles.title}>
            {t('power_of_flashcards')}
          </h2>
          <h4 className={styles.subTitle}>
            {t('para1')}
            <br />
            <br />
            {t('para2')}
          </h4>
          <Link to="/signup" onClick={() => gaTrack('Signup', 'Click for signup page')}
            className={styles.signupButton}>
            <Trans t={t} i18nKey="signup_for_free">
              Signup for <span className={styles.free}>FREE</span>
            </Trans>
          </Link>
        </div>
        <div className={styles.section3Right}>
          <h2 className={styles.titleMobile}>{t('power_of_flashcards')}</h2>
          <div className={styles.cardPreview}>
            <CardView
              word="Amusement"
              meaning={t('fun')}
              pronunciation="amyzmɑ̃"
              imageUrl={cardImg}
              audio={cardAudio}
              score={2}
              language="fr"
            />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.section4}>
      <img src={cardozeImg} alt="cardoze" />
      <h3 className={styles.section4Title}>
        <Trans t={t} i18nKey="a_simple_and_intelligent">
          a <span className={styles.bold}>Simple</span> and
          &nbsp;<span className={styles.bold}>Intelligent</span>&nbsp;
          flashcard tool
        </Trans>
      </h3>
      <Swiper
        className={styles.cardSwiper}
        loop={true}
        spaceBetween={30}
        autoplay={{
          delay: 5000,
        }}
        pagination={true}
      >
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide1Img} alt="card1" className={styles.cardImg} />
              <div className={styles.cardTitle}>{t('card_slide_1_title')}</div>
              <div className={styles.cardDesc}>{t('card_slide_1_desc')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide2Img} alt="card2" className={styles.cardImg} />
              <div className={styles.cardTitle}>{t('card_slide_2_title')}</div>
              <div className={styles.cardDesc}>{t('card_slide_2_desc')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide3Img} alt="card3" className={styles.cardImg} />
              <div className={styles.cardTitle}>{t('card_slide_3_title')}</div>
              <div className={styles.cardDesc}>{t('card_slide_3_desc')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide4Img} alt="card4" className={styles.cardImg} />
              <div className={styles.cardTitle}>{t('card_slide_4_title')}</div>
              <div className={styles.cardDesc}>{t('card_slide_4_desc')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide5Img} alt="card5" className={styles.cardImg} />
              <div className={styles.cardTitle}>{t('card_slide_5_title')}</div>
              <div className={styles.cardDesc}>{t('card_slide_5_desc')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardSlide}>
            <div className={styles.card}>
              <img src={slide6Img} alt="card6" className={styles.cardImg} />
              <div className={styles.freeCardTitle}>{t('card_slide_6_title')}</div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <h2 className={styles.device}>{t('device')}</h2>
      <h3 className={styles.availableOn}>
        <Trans t={t} i18nKey="available_on">
          Available on both <span className={styles.bold}>DESKTOP</span> and
          <span className={styles.bold}>MOBILE</span>
        </Trans>
        <br />
        {t('no_need')}
      </h3>
      <div className={styles.devices}>
        <img src={desktopImg} alt="desktop" />
        <img src={mobileImg} alt="mobile" />
      </div>
    </div>
    <div className={styles.section5}>
      <Link to="/signup" onClick={() => gaTrack('Signup', 'Click for signup page')}
        className={styles.signupButton}>
        <Trans t={t} i18nKey="signup_for_free">
          Signup for <span className={styles.free}>FREE</span>
        </Trans>
      </Link>
      <Swiper
        className={styles.reviewSwiper}
        spaceBetween={30}
        centeredSlides={true}
        slidesPerView="auto"
        autoplay={{
          delay: 5000,
        }}
        initialSlide={1}
      >
        <SwiperSlide className={styles.slide}>
          <div className={styles.review}>
            <img src={star4Img} alt="4 star" />
            <h4>{t('review_1')}</h4>
            <p>{t('user_1')}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <div className={styles.review}>
            <img src={star5Img} alt="5 star" />
            <h4>{t('review_2')}</h4>
            <p>{t('user_2')}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <div className={styles.review}>
            <img src={star5Img} alt="5 star" />
            <h4>{t('review_3')}</h4>
            <p>{t('user_3')}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <div className={styles.review}>
            <img src={star5Img} alt="5 star" />
            <h4>{t('review_4')}</h4>
            <p>{t('user_4')}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <Signup isOpen={ routeMatch.path === '/signup' } />
    <Login isOpen={ routeMatch.path === '/login' } />
    <Switch>
      <Route path="/forgotPassword">
        <ForgotPassword />
      </Route>
      <Route path="/resetPassword">
        <ResetPassword />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
    </Switch>
  </div>;
};

export default HomePublic;
