import { Language } from '../types';

interface LabelAndCountryInterface {
  label: string;
  country: string;
}

const languageMapping: Record<string, LabelAndCountryInterface> = {
  en: {
    label: 'English',
    country: 'gb',
  },
  fr: {
    label: 'Français',
    country: 'fr',
  },
  zh: {
    label: '中文',
    country: 'cn',
  },
  yue: {
    label: '廣東話',
    country: 'hk',
  },
  es: {
    label: 'Español',
    country: 'es',
  },
  ja: {
    label: '日本語',
    country: 'jp',
  },
};

const getLabelAndCountry = (lang: Language) => {
  if (lang && lang in languageMapping) {
    return languageMapping[lang] as LabelAndCountryInterface;
  }
  return languageMapping.en;
};

export default getLabelAndCountry;
