import { useState } from 'react';
import store from 'store2';
import { SystemLanguage } from '../types';

interface ValuesInterface {
  createCardLastDeckId?: string;
  systemLanguage?: SystemLanguage;
}

const STORE_KEYS: Record<string, string> = {
  createCardLastDeckId: 'last_deck_id',
  systemLanguage: 'system_language',
};

const useStore = () => {
  const [values, setValues] = useState<ValuesInterface>({
    createCardLastDeckId: store(STORE_KEYS.createCardLastDeckId),
    systemLanguage: store(STORE_KEYS.systemLanguage),
  });

  const setValuesWithStore = (newValues: ValuesInterface) => {
    setValues(v => ({ ...v, ...newValues }));
    for (const [key, value] of Object.entries(newValues)){
      store(STORE_KEYS[key], value);
    }
  };

  return { ...values, setStores: setValuesWithStore };
};

export default useStore;
