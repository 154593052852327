import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AnimationSegment } from 'lottie-web';
import classnames from 'classnames';

import { AppContext } from '../../AppContext';
import playProgressAnim from '../../animations/play-progress.json';
import playDolphinAnim from '../../animations/play-dolphin.json';
import playAnim from '../../animations/play.json';
import Lottie from '../../components/Lottie';
import styles from './PlayTab.module.scss';

interface PlayTabInterface {
  onClick: () => void;
}

const PlayTab: React.FC<PlayTabInterface> = ({ onClick }) => {
  const [dolphinSegment, setDolphinSegment] = useState<AnimationSegment[]>(
    [[0, 75], [36, 75]]);
  const [showPlay, setShowPlay] = useState(false);

  const context = useContext(AppContext);

  const progress = Math.min(1, context.currentUser ?
    context.todayCount / context.currentUser?.dailyGoal : 0);

  const onProgressLoopComplete = () => {
    setShowPlay(true);
  };

  const onDolphinLoopComplete = () => {
    setDolphinSegment([[36, 75]]);
  };

  const onPlayLoopComplete = () => {
    setShowPlay(false);
  };

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClick();
  };

  return <Link
    to="/play"
    onClick={onPlayClicked}
    className={classnames(styles.tab, styles.play)}
  >
    <Lottie
      style={{ width: 95, height: 95 }}
      animationData={playProgressAnim}
      loop={false}
      rendererSettings={{ viewBoxSize: '31 21 180 180' }}
      segments={[0, 30 + 35 * progress]}
      onComplete={onProgressLoopComplete}
      play
    />
    <Lottie
      style={{ width: 40, height: 40, position: 'absolute', top: 28 }}
      animationData={playDolphinAnim}
      segments={dolphinSegment}
      rendererSettings={{ viewBoxSize: '80 72 80 82' }}
      onLoopComplete={onDolphinLoopComplete}
      loop={true}
      play
    />
    { showPlay && <Lottie
        style={{ width: 100, height: 80, position: 'absolute', top: -30, right: -36 }}
        animationData={playAnim}
        onLoopComplete={onPlayLoopComplete}
        play={true}
      />
    }
  </Link>;
};

export default PlayTab;
