import { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';

import styles from './SubMenu.module.scss';
import variables from '../../variables.module.scss';
import subMenuArrowWhite from '../../images/submenu-arrow.svg';
import subMenuArrowBlue from '../../images/submenu-arrow-blue.svg';

type menuType = string | ReactElement;

interface SubMenuInterface {
  onPageClick: (page: number) => void;
  menu: menuType[];
  arrowColor: 'white' | 'blue';
  currentIndex: number;
}

const buttonStyles = {
  fontFamily: variables.normalFontFamily,
  width: '100%',
  height: '32px',
  backgroundColor: '#ffffff',
  color: '#aeadad',
  fontSize: '15px',
  marginTop: '5px',
  border: '1px solid #2d65c2',
  fontWeight: 500,
};

const buttonSelectedStyles = {
  color: '#2d65c2',
  border: '3px solid #2d65c2',
  boxShadow: '5px 0px 5px -4px #1f1f1f',
  fontWeight: 600,
};

const SubMenu: React.FC<SubMenuInterface> = ({ onPageClick, menu, arrowColor, currentIndex }) => {
  const onClick = (index: number) => {
    onPageClick(index);
  };

  const arrowImg = arrowColor === 'white' ? subMenuArrowWhite : subMenuArrowBlue;
  return <div className={styles.subMenu}>
    {
      menu.map((option, index) => {
        return <div
          key={index}
          className={ styles.buttonWrapper }
        >
          <Button
            position="relative"
            textTransform="capitalize"
            { ...buttonStyles }
            { ...(currentIndex === index && buttonSelectedStyles) }
            paddingLeft={index < currentIndex ? 0 : undefined}
            paddingRight={index > currentIndex ? 0 : undefined}
            zIndex={menu.length - Math.abs(currentIndex - index)}
            onClick={() => onClick(index)}
          >
            { option }
          </Button>
          {currentIndex === index &&
            <img
              className={styles.arrow}
              src={arrowImg}
              alt="submenu-arrow" />
          }
          </div>;
      },
      )
    }
  </div>;
};

export default SubMenu;
