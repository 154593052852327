import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';

interface PrivateRouteInterface {
  path: string | string[];
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteInterface> = ({ children, ...props }) => {

  const location = useLocation();
  const context = useContext(AppContext);

  if (context.hasToken) {
    return (
      <Route {...props} >
        {children}
      </Route>
    );
  }

  return <Redirect to={{ pathname: '/login', state: { referrer: location.pathname } }} />;
};

export default PrivateRoute;
