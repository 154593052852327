import { Modal, ModalOverlay, ModalContent, Button, ModalBody, Input,
  ModalFooter, VStack, ModalHeader, ModalCloseButton, FormControl,
  ModalHeaderProps,
  IconButton, Image,
} from '@chakra-ui/react';
import { useCallback, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';

import axios from '../../utils/axios';
import useInput from '../../hooks/useInput';
import styles from './ForgotPassword.module.scss';
import forgotPasswordBackIcon from '../../images/forgotpassword-back.svg';
import successIcon from '../../images/success.svg';
import variables from '../../variables.module.scss';

const modalContentStyle: ModalHeaderProps = {
  borderRadius: '2xl',
  padding: '10px',
};

const modalHeaderStyle: ModalHeaderProps = {
  marginBottom: '20px',
  display: 'flex',
  flexFlow: 'row',
};

const modelCloseButtonStyle = {
  margin: '6px',
  size: 'md',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  borderRadius: '50%',
};

const modalBody = {
  display: 'flex',
  flexFlow: 'column',
  paddingTop: '20px',
  paddingBottom: '40px',
  paddingRight: { base: '15px', md: '50px', lg: '80px' },
  paddingLeft: { base: '15px', md: '50px', lg: '80px' },
};

const stackStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  paddingBottom: '30px',
};

const inputStyle = {
  fontFamily: variables.normalFontFamily,
  padding: '26px',
  marginTop: '20px',
  border: '0px',
  borderRadius: '5px',
  bg: '#6dd8e0',
  outline: 'none',
  _placeholder: { color: 'white',
    textTransform: 'uppercase' },
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
};

const buttonStyle = {
  bg: 'white',
  width: '100%',
  height: '3rem',
  letterSpacing: '0.01em',
  margin: '30px',
  color: '#2D65C2',
  border: '1px solid #2D65C2',
  fontWeight: '500',
  _focus: { outline: 0 },
};

const backStyle = {
  fontFamily: variables.titleFontFamily,
  color: '#16B5DF',
  letterSpacing: '0.2px',
  fontWeight: '500',
};

const successIconStyle = {
  alignSelf: 'center',
  marginBottom: '20px',
};

const ForgotPassword = () => {

  const { t } = useTranslation('forgot_password');

  const translateBackend = useTranslation('backend').t;
  const [email, setEmail] = useInput('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const context = useContext(AppContext);

  const onClose = () => {
    history.replace('/');
  };

  const onBack = () => {
    context.goBack();
  };

  const onSubmit = useCallback(
    async () => {
      setIsLoading(true);
      try {
        const response = await axios.post('/forgotPassword', {
          email,
        });

        if (response) {
          setSubmitSuccess(true);
          setEmailErrorMsg('');
        }

      } catch (err) {
        setEmailErrorMsg(err.response.data.data.errors[0].msg);
      }
      setIsLoading(false);
    },
    [email],
  );

  useEffect(
    () => {
      const eventListener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onSubmit();
        }
      };

      window.addEventListener('keydown', eventListener);
      return () => {
        window.removeEventListener('keydown', eventListener);
      };
    },
    [onSubmit],
  );

  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent {...modalContentStyle}>
        <ModalHeader {...modalHeaderStyle}>
          <div className={styles.headerBlock}>
            <div className={styles.backLogin}>
              <IconButton icon={<img alt="back to" src={forgotPasswordBackIcon} />}
                aria-label="back to" onClick={onBack} variant="link">
              </IconButton>
              <Button {...backStyle} variant="link" onClick={onBack}>
                {t('login').toUpperCase()}
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton {...modelCloseButtonStyle}/>
        <ModalBody {...modalBody}>
          { submitSuccess &&
            <Image
              {...successIconStyle}
              boxSize="35px"
              objectFit="cover"
              src={successIcon}
              alt="success"/>
          }
          <div className={styles.messageStyle}>
            { !submitSuccess ? t('msg_instruction') : t('msg_success') }
          </div>
          <VStack {...stackStyle}>
            { !submitSuccess &&
              <FormControl>
                <Input {...inputStyle} type="email" placeholder={t('placeholder_email') }
                value={email} onChange={setEmail} isDisabled={isLoading} autoFocus />
                { emailErrorMsg && (
                  <span className={styles.errorMsg}>{ translateBackend(emailErrorMsg) }</span>
                )}
              </FormControl>
            }
          </VStack>
          <ModalFooter {...footerStyle}>
            { !submitSuccess &&
              <Button
                {...buttonStyle}
                onClick={onSubmit}
                isLoading={isLoading}>
                {t('submit')}</Button>
            }
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPassword;
