import axios from 'axios';
import store from 'store2';

const STORE_KEY = 'token';
const TIMEOUT = 20000;

export const hasToken = (): boolean => {
  return !!store(STORE_KEY);
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: hasToken() ? {
    Authorization: `Bearer ${store(STORE_KEY)}`,
  } : undefined,
  timeout: TIMEOUT,
});

export const setToken = (token: string | null) => {
  if (token) {
    store(STORE_KEY, token);
  } else {
    store.remove(STORE_KEY);
  }
  instance.defaults.headers.Authorization = token ? `Bearer ${token}` : undefined;
};

export default instance;
