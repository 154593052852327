import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';

import NotificationCount from './NotificationCount';

interface FriendRequestNotificationCountInterface {
  className: string;
}

const FriendRequestNotificationCount: React.FC<FriendRequestNotificationCountInterface>
= ({ className }) => {
  const context = useContext(AppContext);
  return <NotificationCount count={context.friendRequestCount} className={className} />;
};

export default FriendRequestNotificationCount;
