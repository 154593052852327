import React, { useContext, ChangeEvent } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import classnames from 'classnames';
import { Select } from '@chakra-ui/react';

import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import dolphinImage from '../../images/dolphin.svg';
import igImg from '../../images/home-public/ig.svg';
import fbImg from '../../images/home-public/fb.svg';
import { SYSTEM_LANGUAGES } from '../../constants';
import useI18n from '../../locales/i18n';
import { SystemLanguage } from '../../types';

const SimpleFooter = () => {
  const { t } = useTranslation('footer');

  const location = useLocation();
  const context = useContext(AppContext);

  const simple = location.pathname !== '/' || !context.hasToken;

  return <div
    className={classnames(styles.footer, { [styles.simple]: simple })}
    style={{ backgroundColor: context.footerColor }}
  >
    <div className={styles.footerInner}>
      <div className={styles.copyright}>{t('copyright', { year: new Date().getFullYear() })}</div>
    </div>
  </div>;
};

const HomeFooter = () => {
  const { t } = useTranslation('footer');

  const context = useContext(AppContext);

  return <div
    className={classnames(styles.footer)}
    style={{ backgroundColor: context.footerColor }}
  >
    <div className={styles.footerInner}>
      <div className={styles.wave} >
        <img src={dolphinImage} className={styles.dolphin} alt="dolphin" />
      </div>
      <div className={styles.pitch}>
        <span className={styles.pitchInner}>
          {t('language_at_your_fingertips')}
        </span>
      </div>
      <div className={styles.copyright}>{t('copyright', { year: new Date().getFullYear() })}</div>
    </div>
  </div>;
};

const PublicHomeFooter = () => {
  const { t, i18n } = useTranslation('footer');
  const { t: translateSystemLanguage } = useTranslation('system_language');

  const { setLanguage } = useI18n();

  const onSystemLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value as SystemLanguage);
  };

  return <div className={styles.publicHome}>
    <div className={styles.inner}>
      <div className={styles.left}>
        <div className={styles.buttons}>
          <a className={styles.ig} href="https://www.instagram.com/cardozeapp" target="_blank" rel="noreferrer">
            <img src={igImg} alt="instagram" />
          </a>
          <a className={styles.fb} href="https://facebook.com/cardozeapp" target="_blank" rel="noreferrer">
            <img src={fbImg} alt="facebook" />
          </a>
        </div>
        <p className={styles.copyright}>{t('copyright')}</p>
      </div>
      <div className={styles.right}>
        <h4 className={styles.systemLanguage}>{t('system_language')}</h4>
        <Select
          value={i18n.language}
          onChange={onSystemLanguageChange}
          border="none"
          fontWeight="600"
          fontSize="18px"
          color="#0FD4CB"
          paddingLeft="0"
          width="auto"
          className={styles.select}
          flexShrink={0}
        >
          {SYSTEM_LANGUAGES.map(lang => (
            <option value={lang} key={lang}>{translateSystemLanguage(lang)}</option>
          ))}
        </Select>
      </div>
    </div>
  </div>;
};

const Footer: React.FC = () => {
  const context = useContext(AppContext);

  return <Switch>
    <Route path={['/', '/tutorial', '/friends', '/vocabulary/*']} exact>
      { context.hasToken ? <HomeFooter /> : <PublicHomeFooter /> }
    </Route>
    <Route path={[
      '/signup', '/login', '/terms', '/forgotPassword', '/resetPassword']} exact>
      <PublicHomeFooter />
    </Route>
    <Route path="*">
      <SimpleFooter />
    </Route>
  </Switch>;
};

export default Footer;
