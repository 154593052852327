import { useContext } from 'react';
import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import shortNumber from 'short-number';

import { AppContext } from '../../AppContext';
import getFileUrl from '../../utils/getFileUrl';
import styles from './DeckBoxV2.module.scss';
import placeholderImage from '../../images/deck_placeholder.svg';
import { Deck } from '../../types';
import followerCountIcon from '../../images/follower-count.svg';
import getDeckName from '../../utils/getDeckName';
import NewDeckIcon from '../../images/new-deck.svg';

interface DeckBoxV2Interface {
  deck: Deck;
  onDeckUpdated: (deckId: string) => void;
}

const avatarStyle = {
  w: '60px',
  h: '60px',
  border: '4px solid white',
};

const DeckBoxV2: React.FC<DeckBoxV2Interface> =
({ deck }) => {
  const context = useContext(AppContext);
  const currentUser = context.currentUser;
  const { t } = useTranslation('deck_list');

  const deckName = getDeckName(deck, currentUser);
  const imageUrl = getFileUrl(deck.coverImageFile);

  return (
    <Link className={styles.linkWrapper} to={`/deck?id=${deck.nid}`}>
      <div className={styles.deckName}>{deckName}</div>
      <div className={styles.content}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${imageUrl}` }}
        >
          { !imageUrl && (
            <img className={styles.placeholderImage} src={placeholderImage} alt={deckName} />
          )}
        </div>
        <div className={styles.right}>
          {deck.userDeckScore > 0 && deck.cardsCount > 0 ? (
            <div className={styles.progress}>
              {Math.floor(deck.userDeckScore / deck.cardsCount * 100) }%
            </div>
          ) : <img src={NewDeckIcon} alt="new_deck" className={styles.newDeck} />}
          <div>{ t('n_cards', { n: deck.cardsCount }) }</div>
        </div>
      </div>
      <div className={styles.topRight}>
        <Avatar
          { ...avatarStyle as any}
          name={deck.createdByUser.fullName}
          src={getFileUrl(deck.createdByUser.avatarFile)}
        />
        <div className={styles.follower}>
          <img src={followerCountIcon} alt="follower"/>
          <div className={styles.followerNumber}>{shortNumber(deck.followerCount)}</div>
        </div>
      </div>
    </Link>
  );
};

export default DeckBoxV2;
