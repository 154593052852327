import { useState, useRef, useEffect } from 'react';

type Callback = () => void;

const useStateWithCallback = <T>(defaultValue: T):
  [T, (newValue: T, callback?: Callback) => void] => {

  const callbackRef = useRef<Callback>();
  const newValueRef = useRef<T>();

  const [value, setValue] = useState<T>(defaultValue);

  useEffect(
    () => {
      if (newValueRef.current === value && callbackRef.current) {
        callbackRef.current();
        callbackRef.current = undefined;
      }
    },
    [value],
  );

  const setter = (newValue: T, callback?: Callback) => {
    if (newValue === value) {
      if (callback) {
        callback();
      }
    } else {
      callbackRef.current = callback;
      newValueRef.current = newValue;
      setValue(newValue);
    }
  };

  return [value, setter];
};

export default useStateWithCallback;
