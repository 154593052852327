import BlogPostBox from '../../components/BlogPostBox';
import { BlogPost } from '../../types';
import styles from './Blog.module.scss';

interface BlogInterface {
  posts: BlogPost[];
}

const Blog: React.FC<BlogInterface> = ({ posts }) => {
  return (
    <div>
      <div className={styles.blogPosts}>
        {posts.map(post => (
          <BlogPostBox key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export default Blog;
