import classnames from 'classnames';
import { useParams } from 'react-router';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Vocabulary.module.scss';
import { AppContext } from '../../AppContext';
import TopBar from '../../components/TopBar';
import axios from '../../utils/axios';
import { Card, VocabularyType } from '../../types';
import VocabularyList from './VocabularyList';
import VocabularySwiper from './VocabularySwiper';
import { Spinner } from '@chakra-ui/spinner';

interface VocabularyParamsInterface {
  type: VocabularyType;
}

const Vocabulary = () => {
  const { t } = useTranslation('vocabulary');
  const context = useContext(AppContext);
  const { type: vocabularyType } = useParams<VocabularyParamsInterface>();
  const [vocabularyCards, setVocabularyCards] = useState<Card[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [spin, setSpin] = useState<boolean>(false);
  const [noCardShown, setNoCardShown] = useState<boolean>(false);
  const [fetching, setIsFetching] = useState(false);
  const [cursor, setCursor] = useState<string>();
  const listRef = useRef<HTMLDivElement>(null);

  const getVocabularyCards = useCallback(
    async () => {
      setIsFetching(true);
      setSpin(true);
      const response = await axios.get(
        '/vocabulary/cards',
        { params: {
          vocabularyType,
          cursor,
        } });

      if (response.data.data.cards.length === 0) {
        setNoCardShown(true);
      }
      setCursor(response.data.data.cursor);
      setVocabularyCards([...vocabularyCards, ...response.data.data.cards]);
      setSpin(false);
      setIsFetching(false);
    },
    [vocabularyType, cursor, vocabularyCards],
  );

  const shouldFetch = useCallback(
    () => {
      return !fetching &&
        (listRef.current && (window.scrollY + window.innerHeight >
          (listRef.current.offsetTop + listRef.current.scrollHeight) / 2)) &&
        cursor;
    },
    [fetching, cursor, listRef],
  );

  const getCardsIfShouldFetch = useCallback(
    () => {
      if (shouldFetch()) {
        getVocabularyCards();
      }
    },
    [shouldFetch, getVocabularyCards],
  );

  const shouldSwiperFetch = useCallback(
    (progress: any) => {
      return !fetching &&
        (progress >= 0.5) &&
        cursor;
    },
    [fetching, cursor],
  );

  const getCardsIfSwiperShouldFetch = useCallback(
    (progress: any) => {
      if (shouldSwiperFetch(progress)) {
        getVocabularyCards();
      }
    },
    [shouldSwiperFetch, getVocabularyCards],
  );

  useEffect(
    () => {
      if (!initialized) {
        context.setCurrentTab('home');
        getVocabularyCards();
        setInitialized(true);
      }
    },
    [getVocabularyCards, initialized, setInitialized, context],
  );

  useEffect(
    () => {
      window.addEventListener('resize', getCardsIfShouldFetch);
      window.addEventListener('scroll', getCardsIfShouldFetch);

      return () => {
        window.removeEventListener('resize', getCardsIfShouldFetch);
        window.removeEventListener('scroll', getCardsIfShouldFetch);
      };
    },
    [getCardsIfShouldFetch],
  );

  return (
    <div className={classnames(styles.outer, { [styles.zh]: context.currentUser?.systemLanguage === 'zh' })}>
      <TopBar showBackButton={true}/>
      <div className={styles.inner}>
        <div className={styles.widthWrapper}>
          <div className={styles.title}>
            <Link to="/home/revision" className={styles.link} >
              {t('vocabulary')}
            </Link>
            <div className={styles.slash}>&nbsp;/&nbsp;</div> {t(vocabularyType)}
          </div>
          { vocabularyType === 'new' && vocabularyCards.length > 0 &&
            <>
              <VocabularySwiper cards={vocabularyCards}
                spin={spin} onChange={getCardsIfSwiperShouldFetch}/>
              <div className={styles.separator}/>
            </>
          }
          { noCardShown ?
            <div className={styles.notice}>
              {t('empty_card_list')}
            </div> :
            <VocabularyList cards={vocabularyCards} listRef={listRef}/>
          }
          { spin &&
            <div className={styles.spinnerWrapper} >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.600"
                size="xl"/>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Vocabulary;
