import { useContext, MouseEvent } from 'react';

import { Link } from 'react-router-dom';
import { Avatar, Button, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import variables from  '../../variables.module.scss';
import { RecommendedUser, User, SystemLanguage } from '../../types';
import getFileUrl from '../../utils/getFileUrl';
import CountryFlag from '../../components/CountryFlag';
import styles from './RecommendedUsersList.module.scss';
import axios from '../../utils/axios';
import { AppContext } from '../../AppContext';
import addFriendImage from '../../images/recommended-add-friend.svg';
import { getName } from '../../utils/textUtils';

interface RecommendedUsersListInterface {
  users: User[];
  onAddUser: (userId: string) => void;
}

const avatarStyle = {
  w: '70px',
  h: '70px',
};

const buttonStyle = {
  _hover: { filter: 'brightness(90%)' },
  textTransform: 'none',
  height: '35px',
  fontFamily: variables.normalFontFamily,
  boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
  top: 0,
  right: '5px',
  position: 'absolute',
  fontSize: '14px',
};

const getTitle = (recommendedUser: RecommendedUser, lang: SystemLanguage) => {
  return recommendedUser.title[lang] || recommendedUser.title.en;
};

const RecommendedUsersList: React.FC<RecommendedUsersListInterface> = ({ users, onAddUser }) => {
  const { t } = useTranslation('friends');

  const onAddClicked = async (e: MouseEvent<HTMLButtonElement>, userId: string) => {
    e.preventDefault();
    await axios.post('/friendRequests/', { userId });
    onAddUser(userId);
  };

  const context = useContext(AppContext);
  const currentUser = context.currentUser;

  return <Swiper
    spaceBetween={10}
    slidesPerView="auto"
    className={styles.swiper}
  >
    {users.map(user => (
      <SwiperSlide key={user.id} className={styles.userWrapper}>
        <Link to={`/user/${user.username}`} className={styles.user}>
          <div className={styles.upper}>
            <Avatar
              { ...avatarStyle }
              name={user.fullName}
              src={getFileUrl(user.avatarFile)}
            />
            <div className={styles.right}>
              <h4 className={styles.name}>{getName(user.fullName)}</h4>
              <h4 className={styles.teacher}>
                {getTitle(user.recommendedUser!, currentUser!.systemLanguage) || t('teacher')}
              </h4>
              <div className={styles.languages}>
                {user.recommendedUser!.languages.map(lang => (
                  <div key={lang}>
                    <CountryFlag
                      countryCode={lang}
                      style={{
                        width: '36px',
                        height: '27px',
                        fontSize: '12px',
                        margin: '0 3px',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.lower}>
            <div className={styles.stat}>
              <h4>{t('decks')}</h4>
              <h3>{user.decksCreated}</h3>
            </div>
            <div className={styles.stat}>
              <h4>{t('cards')}</h4>
              <h3>{user.cardsCreated}</h3>
            </div>
          </div>
          { currentUser?.id !== user.id && user.friendStatus !== 'isFriend' && (
            <Button
              aria-label="add"
              onClick={e => onAddClicked(e, user.id)}
              background="#F79411"
              color="white"
              {...buttonStyle as any}
            >
              <Image src={addFriendImage} marginRight="8px" />
              {t('add')}
            </Button>
          )}
        </Link>
      </SwiperSlide>
    ))}
  </Swiper>
  ;
};

export default RecommendedUsersList;
