import { ChangeEvent, ReactElement } from 'react';
import { Image, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import useToast from '../../hooks/useToast';
import getFileUrl from '../../utils/getFileUrl';
import avatarFallbackImage from '../../images/avatar-fallback.svg';
import cameraIcon from '../../images/camera.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import AccountDeckList from './AccountDeckList';
import styles from './Profile.module.scss';
import isFriendIcon from '../../images/friends/is-friend.svg';
import addFriendIcon from '../../images/friends/plus.svg';
import acceptIcon from '../../images/friends/accept.svg';
import rejectIcon from '../../images/friends/reject.svg';
import axios from '../../utils/axios';
import CountryFlag from '../../components/CountryFlag';
import { Deck, Language, File as FileType, FriendStatus } from '../../types';

const avatarStyle = {
  color: '#67D29E',
  border: '2px',
  boxSize: '110px',
};

const avatarFallbackStyle = {
  padding: '30px',
  color: '#67D29E',
  border: '2px',
  boxSize: '110px',
  background: '#ffffff',
};

const avatarCamStyle = {
  bottom: '0px',
  right: '-10px',
  color: '#F79411',
  borderColor: '#F79411',
  width: '35px',
  height: '35px',
  background: 'white',
  boxShadow: '2px 2px 4px #808080',
  zIndex: '1',
};

const addAsFriendButtonStyle = {
  width: '48px',
  height: '48px',
};

interface ProfileInterface {
  isSelf: boolean;
  getUser: () => void;
  userId: string;
  subMenu: ReactElement;
  friendStatus: FriendStatus;
  fullName: string;
  spin: boolean;
  locked: boolean;
  decks: Deck[];
  acquiredWords: number;
  friendsCount: number;
  decksCreated: number;
  learningLanguages: Language[];
  onUnfriendClicked: () => void;
  inputFile: React.Ref<HTMLInputElement>;
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onImageClick: () => void;
  avatarFileObject: FileType | null;
  onDeckUpdated: () => void;
  onAddFriendClicked: () => void;
  systemLanguage: string | undefined;
}

const Profile: React.FC<ProfileInterface> = ({
  isSelf, getUser, userId, subMenu, friendStatus, fullName, spin,
  locked, decks, acquiredWords, friendsCount, decksCreated, onImageClick,
  learningLanguages, onUnfriendClicked, inputFile, onImageChange,
  avatarFileObject, onDeckUpdated, onAddFriendClicked, systemLanguage,
}) => {

  const { t } = useTranslation('account');

  const toast = useToast();

  const onAcceptFriendClicked = async () => {
    if (!isSelf) {
      try {
        await axios.put(
          '/friendRequests/accept', { userId });
      } catch (e) {
        toast({
          title: t('error'),
          description: e.response ?  e.response.data.data.message : t('error_desc'),
          status: 'error',
        });
      }

      getUser();
    }
  };

  const onRejectFriendClicked = async () => {
    if (!isSelf) {
      try {
        await axios.put(
          '/friendRequests/reject', { userId });
      } catch (e) {
        toast({
          title: t('error'),
          description: e.response ?  e.response.data.data.message : t('error_desc'),
          status: 'error',
        });
      }

      getUser();
    }
  };

  return (
    <div className={classnames({ [styles.zh]: systemLanguage === 'zh' })}>
      <div className={styles.avatarSection}>
        {subMenu}
        <div className={styles.avatarWrapper}>
          <div className={styles.left} />
          <div className={styles.middle}>
            <div className={styles.avatarBox}>
                <input
                  onChange={onImageChange}
                  type="file"
                  accept="image/*"
                  ref={inputFile}
                  className={styles.imageInput}
                />
              { isSelf && (
                <IconButton
                  {...avatarCamStyle}
                  onClick={onImageClick}
                  pos="absolute"
                  aria-label="cover"
                  variant="outline"
                  isRound={true}
                  icon={<img alt="cover" width="70%" src={cameraIcon} />}
                />
              )}
              <Image
                {...avatarStyle}
                src={getFileUrl(avatarFileObject)}
                objectFit="cover"
                borderRadius="full"
                alt={fullName}
                fallback={
                  <Image
                    {...avatarFallbackStyle}
                    src={avatarFallbackImage}
                    borderRadius="full"
                    alt={fullName} />
                }
              />
              { !isSelf && friendStatus === 'isFriend' && (
                <IconButton
                  onClick={onUnfriendClicked}
                  aria-label="cover"
                  position="absolute"
                  bottom="-10px"
                  right="-10px"
                  background="none"
                  height="fit-content"
                  width="fit-content"
                  icon={<img alt="is friend" src={isFriendIcon} />}
                />
              )}
            </div>
          </div>
          <div className={styles.right}>
            { !isSelf && friendStatus !== 'isFriend' && (
              <div className={styles.friendActions}>
                <h4 className={styles.friendActionsText}>
                  {friendStatus === 'none' && t('add_as_friend')}
                  {friendStatus === 'requestReceived' && t('accept_friend_request')}
                  {friendStatus === 'requestSent' && t('friend_request_sent')}
                </h4>
                {['none', 'requestSent'].includes(friendStatus) && (
                  <IconButton
                    {...addAsFriendButtonStyle}
                    onClick={onAddFriendClicked}
                    aria-label="cover"
                    isRound={true}
                    disabled={friendStatus === 'requestSent'}
                    icon={<img alt="add friend" src={addFriendIcon} />}
                  />
                )}
                { friendStatus === 'requestReceived' && (
                  <div className={styles.buttons}>
                    <IconButton
                      {...addAsFriendButtonStyle}
                      onClick={onAcceptFriendClicked}
                      aria-label="cover"
                      isRound={true}
                      icon={<img alt="accept" src={acceptIcon} />}
                    />
                    <IconButton
                      {...addAsFriendButtonStyle}
                      onClick={onRejectFriendClicked}
                      aria-label="cover"
                      isRound={true}
                      icon={<img alt="reject" src={rejectIcon} />}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.userName}>{fullName}</div>
        <div className={styles.userLanguages}>
          {learningLanguages.map(lang => (
            <div key={lang}>
              <CountryFlag
                countryCode={lang}
                style={{
                  border: '1px solid white',
                  width: '36px',
                  height: '27px',
                  fontSize: '12px',
                  margin: '0 3px',
                }}
              />
            </div>
          ))}
        </div>
        <div className={styles.statisticsWrapper}>
          <div className={styles.statisticsItem}>
            <div className={styles.statisticsNumber}>{decksCreated}</div>
            <div className={styles.statisticsTitle}>{t('decks_created')}</div>
          </div>
          <div className={styles.statisticsItem}>
            <div className={styles.statisticsNumber}>{acquiredWords}</div>
            <div className={styles.statisticsTitle}>{t('mastered_words')}</div>
          </div>
          <div className={styles.statisticsItem}>
          <div className={styles.statisticsNumber}>{friendsCount}</div>
            <div className={styles.statisticsTitle}>{t('friends_count')}</div>
          </div>
        </div>
      </div>
      <div className={styles.deckListWrapper}>
        <LoadingSpinner spin={spin}>
          { decks.length > 0 && (
            <div className={styles.deckWidthWrapper}>
              <div className={styles.accountDecksTitle}>
                { t('account_decks', { number: decks.length }) }
                { locked && <span className={styles.locked}>{t('will_be_unlocked')}</span>}
              </div>
              <AccountDeckList
                locked={locked}
                decks={decks}
                onDeckUpdated={onDeckUpdated}
              />
            </div>
          )}
        </LoadingSpinner>
      </div>
    </div>
  );
};

export default Profile;
