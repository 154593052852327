import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Select } from 'chakra-react-select';

import { CARD_FIELDS } from '../../utils/anki';
import styles from './Anki.module.scss';
import variables from '../../variables.module.scss';

interface MappingConfigProps {
  mapping: [string, string[]][];
  deckName: string;
  count: number;
  onDeckNameChange: (deckName: string) => void;
  onMappingChange: (mapping: [string, string[]][]) => void;
}

const COLOR_SCHEME_MAPPING: Record<string, string> = {
  title: 'blue',
  image: 'purple',
  audio: 'red',
  pronunciation: 'orange',
  meanings: 'yellow',
  sentences: 'green',
  remark: 'gray',
};

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
};

const MappingConfig: React.FC<MappingConfigProps> = (
  { mapping, count, onMappingChange, deckName, onDeckNameChange }) => {

  const { t } = useTranslation('create_deck_form');

  const onSelectChange = (k: string, v: string[]) => {
    onMappingChange(mapping.map(x => (x[0] === k ? [k, v] : x)));
  };

  return <div>
    <div>
      <FormControl className={styles.formControl}>
        <FormLabel htmlFor="deckName" className={styles.label}>{t('deck_name')}</FormLabel>
        <Input value={deckName} id="deckName" onChange={
          e => onDeckNameChange(e.target.value)} {...selectStyle} />
      </FormControl>
      <FormControl className={styles.formControl}>
        <FormLabel htmlFor="count" className={styles.label}>{t('count')}</FormLabel>
        <h4 id="count" className={styles.content}>{count}</h4>
      </FormControl>
      {mapping.map(entry => (
        <FormControl key={entry[0]} className={styles.formControl}>
          <FormLabel htmlFor={entry[0]} className={styles.label}>{entry[0]}</FormLabel>
          <Select
            isMulti
            id={entry[0]}
            value={entry[1].map(
              x => ({ value: x, label: t(x), colorScheme: COLOR_SCHEME_MAPPING[x] }))}
            onChange={(values) => {
              onSelectChange(entry[0], values.map(x => x.value));
            }}
            chakraStyles={{
              input: provided => ({ ...provided, height: '40px' }),
              multiValue: provided => ({
                ...provided,
                fontSize: '16px',
                padding: '6px 12px',
              }),
            }}
            options={[...CARD_FIELDS.map(f => ({
              value: f,
              label: t(f),
              colorScheme: COLOR_SCHEME_MAPPING[f],
            }))]}
          />
        </FormControl>
      ))}
    </div>
  </div>;
};

export default MappingConfig;
