import { useState, useEffect, useContext } from 'react';

import { IconButton } from '@chakra-ui/react';
import getFileUrl from '../../utils/getFileUrl';
import { Card } from '../../types';
import styles from './WordToMeaningMC.module.scss';
import { useTranslation } from 'react-i18next';
import playAudioIcon from '../../images/play-audio.svg';
import generateRandomWords from '../../utils/generateRandomWords';
import { AppContext } from '../../AppContext';
import getMeaning from '../../utils/getMeaning';
import axios from '../../utils/axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import MCButton from './MCButton';

interface ShuffledWordInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const WordToMeaningMC: React.FC<ShuffledWordInterface> = ({ card, setResult, isShowingAnswer }) => {

  const context = useContext(AppContext);

  const [answer, setAnswer] = useState('');
  const [choices, setChoices] = useState<string[]>([]);
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation('play');
  const currentUser = context.currentUser;
  const meaning = getMeaning(card.meanings, currentUser?.systemLanguage);
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(
    () => {
      const getChoices = async () => {
        setSpin(true);
        let fetchedChoices = [];
        try {
          const response = await axios.get('/revision/choices', { params: { cardId: card.id } });
          fetchedChoices = response.data.data.cards.map(
            (c: any) => getMeaning(c.meanings, currentUser?.systemLanguage));
          fetchedChoices = [...(new Set(fetchedChoices.filter((c: any) => c)).values())];

        } catch {
          // do nothing
        }

        setChoices(
          generateRandomWords(
            card.deck.language, 3 - fetchedChoices.length, /[A-Z]/.test(card.title[0]))
            .concat([meaning]).concat(fetchedChoices).sort(() => 0.5 - Math.random()),
        );
        setSpin(false);
      };
      getChoices();

    },
    [card, setChoices, currentUser?.systemLanguage, meaning],
  );

  useEffect(
    () => {
      setResult(answer === meaning);
    },
    [answer, setResult, card, meaning],
  );

  useEffect(
    () => {
      setAnswer('');
    },
    [setAnswer, card],
  );

  const onPlayClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (card.audioFile) {
      const player = new Audio(getFileUrl(card.audioFile));
      player.addEventListener('ended', () => {
        setPlaying(false);
      });

      setPlaying(true);
      player.play();
    }
  };

  const onButtonClick = (value: string) => {
    setAnswer(value);
    setResult(value === card.title);
  };

  return <div className={styles.wordToMeaningMC}>
    <div className={styles.questionBox}>
      {card.title}
      <IconButton
        _hover={{ filter: 'brightness(90%)' }}
        aria-label="play"
        background="none"
        height="40px"
        width="40px"
        onClick={onPlayClicked}
        disabled={!card.audioFile || playing}
        icon={<img
          alt="play"
          src={playAudioIcon}
        />}
        className={styles.audio}
      />
    </div>

    <div className={styles.instruction}>{t('what_does_it_mean')}</div>

    <LoadingSpinner spin={spin}>
      <div className={styles.choices}>
        {choices.map(word => (
          <MCButton isShowingAnswer={isShowingAnswer} isAnswer={meaning === word}
            value={word} key={word} checked={answer === word} onClick={onButtonClick} />
        ))}
      </div>
    </LoadingSpinner>
  </div>;
};

export default WordToMeaningMC;
