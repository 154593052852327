import React from 'react';

import { Button } from '@chakra-ui/react';

import styles from './Keyboard.module.scss';

interface KeyboardInterface {
  onCharClicked: (char: string) => void;
  onBackspace: () => void;
  chars: string[];
}

const buttonStyle = {
  textTransform: 'none',
  variant: 'outline',
  margin: '6px',
  borderColor: '#2D65C2',
  color: '#2D65C2',
  background: 'white',
  width: '40px',
  height: '35px',
};

const charMap: Record<string, string> = {
  ' ': '␣',
};

const Keyboard: React.FC<KeyboardInterface> = ({ chars, onBackspace, onCharClicked }) => {
  return (
    <div className={styles.keyboard}>
      {chars.map(char => (
        <Button
          {...buttonStyle as any}
          onClick={() => onCharClicked(char)}
          key={char}>{charMap[char] || char}</Button>
      ))}
      <Button
        {...buttonStyle as any}
        onClick={() => onBackspace()}
        >←</Button>
    </div>
  );
};

export default Keyboard;
