import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';
import { useParams, useHistory } from 'react-router-dom';
import { Progress, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';
import TopBar from '../../components/TopBar';
import { Card, GameMode, SystemLanguage } from '../../types';
import styles from './Play.module.scss';
import tickIcon from '../../images/tick.svg';
import ImageToWordMC from './ImageToWordMC';
import ShuffledLetter from './ShuffledLetter';
import WordToMeaningMC from './WordToMeaningMC';
import MeaningToWordMC from './MeaningToWordMC';
import ImageToSpelling from './ImageToSpelling';
import WordToImageMC from './WordToImageMC';
import { AppContext } from '../../AppContext';
import * as sfx from '../../sfx';
import getMeaning from '../../utils/getMeaning';
import { gaTrack } from '../../services/analytics';
import LoadingSpinner from '../../components/LoadingSpinner';
import useToast from '../../hooks/useToast';
import shuffleList from '../../utils/shuffleList';

interface PlayParams {
  deckId: string;
}

interface ResultInterface {
  cardId: string;
  result: boolean;
}

const okButtonStyle = {
  width: '266px',
  height: '47px',
  margin: '40px auto',
};

const nextButtonStyle = {
  width: '266px',
  height: '47px',
  margin: '40px auto',
};

interface GameModePickerInterface {
  card: Card;
  setResult: (result: boolean) => void;
  isShowingAnswer: boolean;
}

const pickGameMode = (card: Card, systemLanguage?: SystemLanguage): GameMode => {

  const _pick = (excludedGameModes: GameMode[]) => {
    const candidates: GameMode[] = [];
    const meaning = getMeaning(card.meanings, systemLanguage);
    if (meaning && excludedGameModes.indexOf('word_to_meaning') < 0) {
      candidates.push('word_to_meaning');
    }

    if ((card.audioFile || card.imageFile) && excludedGameModes.indexOf('image_to_word') < 0) {
      candidates.push('image_to_word');
    }

    if (card.imageFile && excludedGameModes.indexOf('word_to_image') < 0) {
      candidates.push('word_to_image');
    }

    if (meaning && excludedGameModes.indexOf('meaning_to_word') < 0) {
      candidates.push('meaning_to_word');
    }

    if ((card.audioFile || card.imageFile) && excludedGameModes.indexOf('image_to_spelling') < 0) {
      candidates.push('image_to_spelling');
    }

    if (excludedGameModes.indexOf('shuffled_letter') < 0) {
      candidates.push('shuffled_letter');
    }
    return candidates;
  };

  let results = _pick(card.deck.excludedGameModes);
  if (results.length === 0) {
    results = _pick([]);
  }
  results = shuffleList(results, card.id);

  return results[card.score % results.length];
};

const GAME_MODE_TO_COMPONENT = {
  word_to_meaning: WordToMeaningMC,
  image_to_word: ImageToWordMC,
  word_to_image: WordToImageMC,
  meaning_to_word: MeaningToWordMC,
  image_to_spelling: ImageToSpelling,
  shuffled_letter: ShuffledLetter,
};

const GameModePicker: React.FC<GameModePickerInterface> =
({ card, setResult, isShowingAnswer }) => {

  const context = useContext(AppContext);
  const systemLanguage = context.currentUser?.systemLanguage;

  const gameMode = pickGameMode(card, systemLanguage);
  const GameModeComponent = GAME_MODE_TO_COMPONENT[gameMode];
  return <GameModeComponent
    card={card}
    setResult={setResult}
    isShowingAnswer={isShowingAnswer}
  />;
};

const Play = () => {
  const { t } = useTranslation('play');

  const [cards, setCards] = useState<Card[]>([]);
  const [limit] = useQueryParam('limit', NumberParam);
  const { deckId } = useParams<PlayParams>();
  const [initialized, setInitialized] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [result, setResult] = useState<boolean>();
  const [results, setResults] = useState<ResultInterface[]>([]);
  const [spin, setSpin] = useState<boolean>(true);
  const [isShowingAnswer, setIsShowingAnswer] = useState(false);
  const context = useContext(AppContext);
  const toast = useToast();

  useEffect(
    () => {
      context.setShowTabBar(false);
      context.setFooterColor('#D7EFEE');
      return () => {
        context.setShowTabBar(true);
        context.setFooterColor('');
      };
    },
    [], // eslint-disable-line
  );

  const correctCount = useMemo(() => results.filter(r => r.result).length, [results]);

  const history = useHistory();

  const getCards = useCallback(
    async () => {
      setSpin(true);
      const response = await axios.get('/revision/cards', { params: {
        limit,
        deckId: deckId === 'auto' ? null : deckId,
      }});
      const cardsResponse = response.data.data.cards;
      if (cardsResponse.length > 0) {
        setCards(response.data.data.cards);
      } else {
        history.replace('/play');
        toast({
          title: t('no_card'),
          description: t('no_card_desc'),
          status: 'success',
        });
      }
      setSpin(false);
    },
    [setCards, deckId, limit, history, toast, t],
  );

  const card = useMemo<Card | undefined>(
    () => cards.length > 0 ? cards[currentIndex] : undefined,
    [cards, currentIndex],
  );

  useEffect(
    () => {
      if (!initialized) {
        setInitialized(true);
        context.setCurrentTab('play');
        const initialize = async () => {
          await getCards();
        };
        initialize();
      }
    },
    [getCards, initialized, context],
  );

  const onConfirm = useCallback(
    () => {
      if (result !== undefined) {
        if (result) {
          sfx.correct.play();
        } else {
          sfx.wrong.play();
        }
        gaTrack('Play', 'Answer question');
        setIsShowingAnswer(true);
      }
    },
    [setIsShowingAnswer, result],
  );

  useEffect(
    () => {
      const eventListener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          if (!isShowingAnswer) {
            onConfirm();
            e.preventDefault();
          }
        }
      };

      window.addEventListener('keydown', eventListener);
      return () => {
        window.removeEventListener('keydown', eventListener);
      };
    },
    [onConfirm, isShowingAnswer],
  );

  const onNextQuestion = useCallback(
    async () => {
      setIsShowingAnswer(false);
      if (currentIndex === cards.length - 1) {
        if (results.length === results.filter((r: any) => r.result).length) {
          sfx.welldone.play();
        } else {
          sfx.good.play();
        }

        const response = await axios.post(
          '/revision', {
            results: [...results, {
              result,
              cardId: card!.id,
            }],
            deckId: deckId === 'auto' ? undefined : deckId,
          });
        history.replace(`/play/result/${response.data.data.revision.id}`);

      } else if (result !== undefined) {
        setResults(resultList => [...resultList, {
          result,
          cardId: card!.id,
        }]);
        setCurrentIndex(index => index + 1);
        setResult(undefined);
      }
    },
    [currentIndex, setCurrentIndex, card,
      result, results, setResults, cards, deckId, history,  setIsShowingAnswer],
  );

  return (
    <div className={styles.playOuter}>
      <TopBar showBackButton={true} />
      <LoadingSpinner spin={spin}>
        <div className={styles.play}>
          {card && (
            <div>
              <div className={styles.progressSection}>
                <Progress
                  value={currentIndex / cards.length * 100}
                  height="3px"
                  borderRadius="2px"
                  flex="1"
                />
                <div className={styles.progressText}>{currentIndex + 1} / {cards.length}</div>
              </div>
              <div className={styles.correctCount}>
                <img src={tickIcon} alt="tick" />
                <div>{t('correct_count', { count: correctCount })}</div>
              </div>
              <GameModePicker
                card={card}
                setResult={setResult}
                isShowingAnswer={isShowingAnswer}
              />
            </div>
          )}
        </div>
      </LoadingSpinner>
      {isShowingAnswer ? <Button
        onClick={onNextQuestion}
        textTransform="uppercase"
        display="block"
        {...nextButtonStyle}
        >
        {t('next')}
        </Button> : <Button
          onClick={onConfirm}
          disabled={result === undefined}
          textTransform="uppercase"
          display="block"
          {...okButtonStyle}
          >
          {t('ok')}
        </Button>
      }
    </div>
  );
};

export default Play;
