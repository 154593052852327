import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import { AppContext } from '../AppContext';
import DeckCardList from './DeckCardList';
import DeckCardSwiper from './DeckCardSwiper';

const DeckRoute = () => {
  const [deckId] = useQueryParam('id', StringParam);
  const [cardId] = useQueryParam('cardId', StringParam);
  const context = useContext(AppContext);

  if (deckId) {
    if (cardId) {
      return <DeckCardSwiper />;
    }

    if (context.hasToken) {
      return <DeckCardList />;
    }
  }

  return <Redirect to="/decks" />;
};

export default DeckRoute;
