import { useState, useContext } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GoogleLogo from '../../images/google.svg';
import axios  from '../../utils/axios';
import { AppContext } from '../../AppContext';
import { gaTrack } from '../../services/analytics';
import { StateType } from '../../types';

const googleButtonStyle = {
  fontFamily: 'Roboto',
  textTransform: 'none',
  width: 'calc(100% - 30px)',
  margin: '0 15px',
  borderRadius: '2px',
  padding: 0,
  fontWeight: 500,
  backgroundColor: '#4285F4',
  fontSize: '14px',
  height: '42px',
  marginTop: '20px',
  paddingLeft: '15px',
};

interface ErrorType {
  value: string;
  msg: string;
  param: string;
}

interface GoogleLoginButtonInterface {
  onError: (msg: string) => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonInterface> = ({ onError }) => {
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<StateType>();
  const { t } = useTranslation('login');

  const onGoogleSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    try {
      setLoading(true);
      const tokenResponse = await axios.post('/auth/google', {
        tokenId: (response as GoogleLoginResponse).tokenId,
      });
      context.updateToken(tokenResponse.data.data.token);

      if (tokenResponse.data.data.isNewUser) {
        gaTrack('Signup', 'Submit google signup');
        history.replace({
          pathname: '/tutorial',
        });
      } else {
        gaTrack('Login', 'Submit google login');
        history.replace(location.state?.referrer || '/');
      }

    } catch (err) {
      const errorsMap: Record<string, ErrorType> = {};
      err.response.data.data.errors.forEach((error: ErrorType) => {
        errorsMap[error.param] = error;
      });

      if (errorsMap.email) {
        onError(errorsMap.email.msg);
      } else {
        onError('');
      }
    }

    setLoading(false);
  };

  const onGoogleFailure = () => {
    onError('invalid token');
  };

  return <GoogleLogin
    clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID!}
    onSuccess={onGoogleSuccess}
    onFailure={onGoogleFailure}
    cookiePolicy={'single_host_origin'}
    render={props => (
      <Button
        {...googleButtonStyle as any}
        onClick={props.onClick}
        isLoading={loading}
        disabled={props.disabled}
      >
        <Image
          src={GoogleLogo}
          alt="google"
          position="absolute"
          left="-2px"
        />
        {t('login_with_google')}
      </Button>
    )}
  />;
};

export default GoogleLoginButton;
