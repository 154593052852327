import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import axios, { hasToken as axiosHasToken, setToken as axiosSetToken } from './utils/axios';
import { CurrentUser } from './types';
import analytics from './services/analytics';
import { useLocation, useHistory } from 'react-router-dom';
import useI18n from './locales/i18n';

export type TabType = 'home' | 'card' | 'deck' | 'play' | 'friend' | 'account';

interface AppContextInterface {
  currentTab: TabType;
  setCurrentTab: Dispatch<SetStateAction<TabType>>;
  currentUser?: CurrentUser;
  getCurrentUser: () => void;
  removeCurrentUser: () => void;
  showTabBar: boolean;
  setShowTabBar: (shouldShow: boolean) => void;
  footerColor?: string;
  setFooterColor: (color: string) => void;
  goBack: () => void;
  hasToken: boolean;
  updateToken: (token: string) => void;
  friendRequestCount: number;
  i18nLanguage: string | undefined;
  todayCount: number;
}

interface AppProviderInterface {
  initialCurrentTab: TabType;
}

export const AppContext = React.createContext<AppContextInterface>({} as AppContextInterface);

export const AppProvider: React.FC<AppProviderInterface> = ({ initialCurrentTab, children }) => {
  const [currentTab, setCurrentTab] = useState<TabType>(initialCurrentTab);
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [todayCount, setTodayCount] = useState(0);
  const [showTabBar, setShowTabBar] = useState(true);
  const [footerColor, setFooterColor] = useState('none');
  const [friendRequestCount, setFriendRequestCount] = useState(0);
  const [hasToken, setHasToken] = useState(axiosHasToken());
  const { setLanguage, systemLanguage: i18nLanguage } = useI18n();

  const location = useLocation();
  const history = useHistory();

  const [locations, setLocations] = useState<any>([]);

  const goBack = useCallback(
    () => {
      if (locations.length > 1) {
        history.replace(locations[locations.length - 2]);
        setLocations((l: any) => l.slice(0, l.length - 2));
      } else {
        history.replace('/');
      }
    },
    [locations, history, setLocations],
  );

  const getTodayCount = async () => {
    if (axiosHasToken()) {
      try {
        const response = await axios.get(
          '/home/todayCount',
          { params: { utcOffset: -(new Date()).getTimezoneOffset() / 60 } });
        setTodayCount(response.data.data.todayCount);
      } catch {
        // empty
      }
    }
  };

  const getFriendRequestCount = async () => {
    if (axiosHasToken()) {
      try {
        const response = await axios.get('/friendRequests/received/count');
        setFriendRequestCount(response.data.data.count);
      } catch {
        // empty
      }
    }
  };

  useEffect(
    () => {
      if (location.pathname === '/deck' && locations.length > 0) {
        const previous = locations[locations.length - 1];
        const prevQuery = new URLSearchParams(previous.search);
        const currentQuery = new URLSearchParams(location.search);

        if (currentQuery.get('cardId') && prevQuery.get('cardId') &&
          prevQuery.get('deckId') === currentQuery.get('deckId')) {
          locations.pop();
          setLocations([...locations, location]);
          return;
        }
      }

      setLocations((l: any) => ([...l, location]));
      getFriendRequestCount();
      getTodayCount();
    },
    [location, setLocations], // eslint-disable-line
  );

  const updateToken = (token: string) => {
    axiosSetToken(token);
    setHasToken(!!token);
  };

  const trackCurrentUser = useCallback(
    async () => {
      if (hasToken) {
        try {
          await axios.get('/users/track');
        } catch {
          // empty
        }
      }
    },
    [hasToken],
  );

  const getCurrentUser = useCallback(
    async () => {
      if (hasToken) {
        const response = await axios.get('/users/me');
        const userRes = response.data.data.user;
        setCurrentUser(userRes);
        setLanguage(userRes.systemLanguage);
        analytics.identify(response.data.data.user.id as string);
      }
    },
    [setCurrentUser, hasToken], // eslint-disable-line
  );

  useEffect(
    () => {
      getCurrentUser();
      trackCurrentUser();
    },
    [hasToken, getCurrentUser, trackCurrentUser],
  );

  const removeCurrentUser = () => {
    setCurrentUser(undefined);
  };

  const value: AppContextInterface = {
    currentTab, setCurrentTab,
    getCurrentUser, currentUser, removeCurrentUser,
    showTabBar, setShowTabBar,
    footerColor, setFooterColor,
    goBack, hasToken, updateToken,
    friendRequestCount,
    i18nLanguage,
    todayCount,
  };

  return <AppContext.Provider value={value}>
    {children}
  </AppContext.Provider>;
};
