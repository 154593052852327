import styles from './NotificationCount.module.scss';
import React from 'react';
import classnames from 'classnames';

interface NotificationCountInterface {
  count: number;
  className: string;
}

const NotificationCount: React.FC<NotificationCountInterface> = ({ count, className }) => {
  return count > 0 ? <div className={classnames(styles.dot, className)} >{count}</div> : <></>;
};

export default NotificationCount;
