import { useState, useContext } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, NumberInput, NumberInputField,
  NumberInputStepper, NumberIncrementStepper, ModalContent,
  NumberDecrementStepper, Button, Select } from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';

import styles from './Tutorial.module.scss';
import zoomImage from '../../images/tutorial/zoom.svg';
import step1Icon from '../../images/tutorial/step-1.svg';
import step2Icon from '../../images/tutorial/step-2.svg';
import step3Icon from '../../images/tutorial/step-3.svg';
import step4Icon from '../../images/tutorial/step-4.svg';
import progress1Image from '../../images/tutorial/progress-1.svg';
import progress2Image from '../../images/tutorial/progress-2.svg';
import progress3Image from '../../images/tutorial/progress-3.svg';
import { Language } from '../../types';
import { AppContext } from '../../AppContext';
import axios from '../../utils/axios';
import useInput from '../../hooks/useInput';
import { SYSTEM_LANGUAGES, TARGET_LANGUAGES } from '../../constants';
import variables from '../../variables.module.scss';

interface SlideInterface {
  index: number;
  onNext: () => void;
  onSetup?: () => void;
}

const Slide: React.FC<SlideInterface> = ({ index, children, onNext, onSetup }) => {
  const { t } = useTranslation('tutorial');
  return <div className={styles.slide}>
    <div className={styles.sliderTitle1}>{t('start_your_learning_journey')}</div>
    <div className={styles.sliderTitle2}>{t('with_cardoze')}</div>
    <div className={styles.zoomWrapper}>
      { children }
      <img className={styles.zoom} src={zoomImage} alt="zoom" />
      { index < 4 && (
        <div className={styles.stepIconText}>{t(`step_${index}_icon`)}</div>
      )}
    </div>
    <div className={styles.stepN}>{t('step_n', { n: index })}</div>
    <div className={styles.stepText}>{t(`step_${index}_text`)}</div>
    <div className={styles.stepDesc}>{t(`step_${index}_desc`)}</div>
    { index < 4 ? (
      <Button
        w={202}
        h={47}
        fontSize={18}
        className={styles.next}
        onClick={onNext}
        fontWeight="normal"
      >
        {t('next')}
      </Button>
    ) : (
      <Button
        w={286}
        h={47}
        fontSize={18}
        className={styles.setupButton}
        onClick={onSetup}
        textTransform="none"
        fontWeight="normal"
      >
        {t('set_up_profile')}
      </Button>
    )}
  </div>;
};

interface SliderInterface {
  onSetup: () => void;
}

const Slider: React.FC<SliderInterface> = ({ onSetup }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const onNext = () => {
    swiper?.slideNext();
  };

  return <Swiper
    slidesPerView={1}
    spaceBetween={30}
    pagination
    className={styles.swiper}
    onSwiper={(s) => { setSwiper(s); }}
  >
    <SwiperSlide>
      <Slide index={1} onNext={onNext}>
        <img src={step1Icon} alt="new" style={{ marginLeft: '8px' }} />
      </Slide>
    </SwiperSlide>
    <SwiperSlide>
      <Slide index={2} onNext={onNext}>
        <img src={step2Icon} alt="deck" style={{ marginBottom: '6px' }} />
      </Slide>
    </SwiperSlide>
    <SwiperSlide>
      <Slide index={3} onNext={onNext}>
        <img src={step3Icon} alt="play" style={{ marginBottom: '6px' }} />
      </Slide>
    </SwiperSlide>
    <SwiperSlide>
      <Slide index={4} onNext={onNext} onSetup={onSetup}>
        <img src={step4Icon} alt="follow" />
      </Slide>
    </SwiperSlide>
  </Swiper>;
};

const selectStyle = {
  borderColor: '#67D29E90',
  _hover: {
    borderColor: '#67D29E',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#67D29E',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#F79411',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#67D29E',
  iconSize: '14px',
};

const inputStyle = {
  borderColor: '#67D29E90',
  _hover: {
    borderColor: '#67D29E',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#67D29E',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#F79411',
  padding: '20px',
  height: '50px',
  borderRadius: '4px',
};

interface SetupInterface {
  onNext: () => void;
}

const Setup1: React.FC<SetupInterface> = ({ onNext }) => {
  const { t } = useTranslation('tutorial');
  const [loading, setLoading] = useState(false);

  const context = useContext(AppContext);
  const currentUser = context.currentUser;
  const { t: translateLanguage } = useTranslation('language');

  const [targetLanguage, onTargetLanguageChange] = useInput<Language>(
    currentUser?.targetLanguage || 'en');

  const confirm = async () => {
    setLoading(true);
    await axios.put('/users/me', {
      ...currentUser,
      targetLanguage,
    });
    context.getCurrentUser();
    onNext();
    setLoading(false);
  };

  return (
    <div className={styles.setup}>
      <img src={progress1Image} alt="progress" className={styles.progress}/>
      <div className={styles.progressText}>{t('which_language')}</div>
      <div className={styles.select}>
        <Select
          value={targetLanguage}
          onChange={onTargetLanguageChange}
          {...selectStyle}
          icon={<TriangleDownIcon />}
        >
          {TARGET_LANGUAGES.map(lang => (
            <option value={lang} key={lang}>{translateLanguage(lang)}</option>
          ))}
        </Select>
      </div>
      <Button
        w={266}
        h={47}
        fontSize={18}
        onClick={confirm}
        fontWeight="normal"
        backgroundColor="#0FD4CB"
        loading={loading}
      >
        {t('ok')}
      </Button>
    </div>
  );
};

const Setup2: React.FC<SetupInterface> = ({ onNext }) => {
  const { t } = useTranslation('tutorial');
  const [loading, setLoading] = useState(false);

  const context = useContext(AppContext);
  const currentUser = context.currentUser;
  const { t: translateLanguage } = useTranslation('system_language');

  const [systemLanguage, onSystemLanguageChange] = useInput<Language>(
    currentUser?.systemLanguage || 'en');

  const confirm = async () => {
    setLoading(true);
    await axios.put('/users/me', {
      ...currentUser,
      systemLanguage,
    });
    context.getCurrentUser();
    onNext();
    setLoading(false);
  };

  return (
    <div className={styles.setup}>
      <img src={progress2Image} alt="progress" className={styles.progress}/>
      <div className={styles.progressText}>{t('select_system_language')}</div>
      <div className={styles.select}>
        <Select
          value={systemLanguage}
          onChange={onSystemLanguageChange}
          {...selectStyle}
          icon={<TriangleDownIcon />}
        >
          {SYSTEM_LANGUAGES.map(lang => (
            <option value={lang} key={lang}>{translateLanguage(lang)}</option>
          ))}
        </Select>
      </div>
      <Button
        w={266}
        h={47}
        fontSize={18}
        onClick={confirm}
        fontWeight="normal"
        loading={loading}
      >
        {t('ok')}
      </Button>
    </div>
  );
};

const Setup3: React.FC<SetupInterface> = ({ onNext }) => {
  const { t } = useTranslation('tutorial');
  const [loading, setLoading] = useState(false);

  const context = useContext(AppContext);
  const currentUser = context.currentUser;

  const [dailyGoal, onDailyGoalChange] = useInput<number>(
    currentUser?.dailyGoal || 20, (value: any) => value);

  const confirm = async () => {
    setLoading(true);
    await axios.put('/users/me', {
      ...currentUser,
      dailyGoal,
    });
    context.getCurrentUser();
    onNext();
    setLoading(false);
  };

  return (
    <div className={styles.setup}>
      <img src={progress3Image} alt="progress" className={styles.progress}/>
      <div className={styles.progressText}>{t('select_goal')}</div>
      <div className={styles.select}>
        <NumberInput
          type="number"
          step={5}
          min={5}
          max={100}
          value={dailyGoal}
          onChange={onDailyGoalChange}
        >
          <NumberInputField {...inputStyle} />
          <NumberInputStepper>
            <NumberIncrementStepper color="#67D29E" />
            <NumberDecrementStepper color="#67D29E" />
          </NumberInputStepper>
        </NumberInput>
      </div>
      <Button
        w={266}
        h={47}
        fontSize={18}
        onClick={confirm}
        fontWeight="normal"
        backgroundColor="#16B5DF"
        loading={loading}
        textTransform="none"
      >
        {t('lets_get_started')}
      </Button>
    </div>
  );
};

interface TutorialInterface {
  onClose: () => void;
}

const Tutorial: React.FC<TutorialInterface> = ({ onClose }) => {
  const [setupPage, setSetupPage] = useState(0);

  const onSetup = () => {
    setSetupPage(1);
  };

  const onNext = () => {
    if (setupPage < 3) {
      setSetupPage(s => s + 1);
    } else {
      onClose();
    }
  };

  const Content = () => (<>
    { setupPage === 0 && (
      <Slider onSetup={onSetup} />
    )}
    { setupPage === 1 && (
      <Setup1 onNext={onNext} />
    )}
    { setupPage === 2 && (
      <Setup2 onNext={onNext} />
    )}
    { setupPage === 3 && (
      <Setup3 onNext={onNext} />
    )}
  </>);

  return <>
    <Modal isOpen={true} onClose={() => null }>
      <ModalOverlay className={styles.modalOverlay} />
      <ModalContent
        motionPreset="none"
        className={styles.modalContent}
        textAlign="center"
        borderRadius="20px"
      >
        <Content />
      </ModalContent>
    </Modal>
    <div className={styles.mobile}>
      <Content />
    </div>
  </>;
};

export default Tutorial;
