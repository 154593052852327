import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader,
  AlertDialogBody, AlertDialogFooter,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import styles from './ConfirmSaveDuplicateDialog.module.scss';

interface ConfirmSaveDuplicateDialogInterface {
  onClose: (confirmed: boolean) => void;
  cardId?: string;
  word: string;
  deckName: string;
  deckId: string;
}

const ConfirmSaveDuplicateDialog: React.FC<ConfirmSaveDuplicateDialogInterface> =
({ onClose, cardId, deckName, word, deckId }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('card_form');

  return <AlertDialog
    isOpen={!!cardId}
    leastDestructiveRef={cancelRef}
    onClose={() => onClose(false)}
    size="xl"
    isCentered
  >
    <AlertDialogOverlay>
      <AlertDialogContent margin="20px">
        <AlertDialogHeader fontSize="xl" fontWeight="bold" />
        <AlertDialogBody fontSize="lg">
          <Trans
            t={t}
            i18nKey="duplicated_card_found_desc"
            values={{ word, deckName }}
            components={{
              lnk: <Link
                className={styles.highlighted}
                to={`/deck?id=${deckId}&cardId=${cardId}`}>{word}</Link>,
              deck: <span className={styles.highlighted}>{{ deckName }}</span>,
            }}
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} fontWeight="200" onClick={() => onClose(true)}>
            {t('create')}
          </Button>
          <Button variant="red" fontWeight="200" onClick={() => onClose(false)} ml={3}>
            {t('cancel')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>;
};

export default ConfirmSaveDuplicateDialog;
