import { useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { StringParam, useQueryParams, QueryParamProvider } from 'use-query-params';
import styles from './App.module.scss';
import Home from './pages/Home';
import HomePublic from './pages/HomePublic';
import Account from './pages/Account';
import DeckList from './pages/DeckList';
import CreateCard from './pages/CreateCard';
import CardForm from './pages/CardForm';
import Friends from './pages/Friends';
import PlayIndex from './pages/PlayIndex';
import PlayDeckSelect from './pages/PlayDeckSelect';
import Play from './pages/Play';
import PlayResult from './pages/PlayResult';
import Vocabulary from './pages/Vocabulary';
import DeckForm from './pages/DeckForm';
import Anki from './pages/Anki';
import Footer from './components/Footer';
import TabBar from './components/TabBar';
import { AppProvider, AppContext } from './AppContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ScrollToTopWhenRoute from './components/ScrollToTopWhenRoute';
import DeckRoute from './pages/DeckRoute';
import theme from './theme';
import { gaTrack } from './services/analytics';
import axios from './utils/axios';

const AppContent = () => {
  const context = useContext(AppContext);
  const [params] = useQueryParams({ referrer: StringParam });

  useEffect(
    () => {
      axios.get('/ping');
    },
    [],
  );

  useEffect(
    () => {
      if (params.referrer === 'weekly_email') {
        gaTrack('Email', 'Referred by weekly update');
      }
    },
    [params.referrer],
  );

  return <div className={styles.app}>
    <Switch>
      <Route path={['/', '/tutorial', '/home/:submenu']} exact>
        { context.hasToken ?  <Home /> : <HomePublic /> }
      </Route>
      <PublicRoute path={[
        '/signup', '/login', '/terms', '/forgotPassword', '/resetPassword']} exact>
        <HomePublic />
      </PublicRoute>
      <PrivateRoute path="/user/:id" exact>
        <Account />
      </PrivateRoute>
      <PrivateRoute path={['/decks']} exact>
        <DeckList />
      </PrivateRoute>
      <PrivateRoute path={['/card/new']} exact>
        <CreateCard />
      </PrivateRoute>
      <PrivateRoute path={['/card/edit']} exact>
        <CardForm />
      </PrivateRoute>
      <Route path={['/deck']} exact>
        <DeckRoute />
      </Route>
      <PrivateRoute path={['/deck/edit']} exact>
        <DeckForm />
      </PrivateRoute>
      <PrivateRoute path={['/play']} exact>
        <PlayIndex />
      </PrivateRoute>
      <PrivateRoute path={['/play/decks']} exact>
        <PlayDeckSelect />
      </PrivateRoute>
      <PrivateRoute path={['/play/:deckId']} exact>
        <Play />
      </PrivateRoute>
      <PrivateRoute path={['/play/result/:id']} exact>
        <PlayResult />
      </PrivateRoute>
      <PrivateRoute path={['/friends/']} exact>
        <Friends />
      </PrivateRoute>
      <PrivateRoute path={['/friends/:submenu']} exact>
        <Friends />
      </PrivateRoute>
      <PrivateRoute path={['/vocabulary/:type']} exact>
        <Vocabulary />
      </PrivateRoute>
      <PrivateRoute path={['/anki']} exact>
        <Anki />
      </PrivateRoute>
      <Redirect to="/" />
    </Switch>
    <Footer />
    <TabBar />
    <ScrollToTopWhenRoute />
  </div>;
};

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppProvider initialCurrentTab="home">
          <QueryParamProvider ReactRouterRoute={Route}>
            <AppContent />
          </QueryParamProvider>
        </AppProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
