import { useMemo } from 'react';
import styles from './ScoreIcon.module.scss';
import score0Icon from '../../images/score-0.svg';
import score1Icon from '../../images/score-1.svg';
import score2Icon from '../../images/score-2.svg';
import score3Icon from '../../images/score-3.svg';
import score4Icon from '../../images/score-4.svg';
import score5Icon from '../../images/score-5.svg';

interface ScoreIconInterface {
  score: number;
}

const ScoreIcon: React.FC<ScoreIconInterface> = ({ score }) => {
  const scoreIcon = useMemo(
    () => ({
      0: score0Icon,
      1: score1Icon,
      2: score2Icon,
      3: score3Icon,
      4: score4Icon,
      5: score5Icon,
    }[score < 5 ? score : 5]),
    [score],
  );

  return <>
    <div>
      <img className={styles.scoreImg} src={scoreIcon} alt="score"/>
    </div>
  </>;
};

export default ScoreIcon;
