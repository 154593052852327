import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Button, Avatar } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import shortNumber from 'short-number';

import { AppContext } from '../../AppContext';
import { gaTrack } from '../../services/analytics';
import getFileUrl from '../../utils/getFileUrl';
import styles from './DeckBox.module.scss';
import placeholderImage from '../../images/deck_placeholder.svg';
import { Deck } from '../../types';
import useToast from '../../hooks/useToast';
import followerCountIcon from '../../images/follower-count.svg';
import variables from  '../../variables.module.scss';
import axios from '../../utils/axios';
import getDeckName from '../../utils/getDeckName';
import { getName, truncateText } from '../../utils/textUtils';

interface DeckBoxInterface {
  deck: Deck;
  onDeckUpdated: (deckId: string) => void;
  type: 'home' | 'account';
  locked?: boolean;
  showAvatar?: boolean;
}

const avatarStyle = {
  w: '40px',
  h: '40px',
  border: '2px solid #67d29e',
  position: 'absolute',
  top: '10px',
  left: '8px',
};

const DeckBox: React.FC<DeckBoxInterface> =
({ deck, onDeckUpdated, type, locked, showAvatar }) => {
  const { t } = useTranslation('deck_list');
  const context = useContext(AppContext);
  const currentUser = context.currentUser;

  const toast = useToast();

  const deckName = getDeckName(deck, currentUser);

  const onFollowClicked = async (e: React.MouseEvent<HTMLElement>) => {
    gaTrack('Teachers Decks', 'Follow deck');
    e.preventDefault();
    await axios.post(`/decks/${deck.id}/follow`);

    toast({
      title: t('you_have_added_deck_title'),
      description: t('you_have_added_deck', { name: deckName }),
      status: 'success',
    });

    onDeckUpdated(deck.id);
  };

  const Wrapper: React.FC = ({ children }) => {
    if (locked) {
      return <div className={styles.lockWrapper}>
        {children}
        <div className={styles.lockOverlay}>
        </div>
      </div>;
    }
    return <Link className={styles.linkWrapper} to={`/deck?id=${deck.nid}`}>{children}</Link>;
  };

  return (
    <div className={classnames(
      styles.deckBox,
      { [styles.home]: type === 'home', [styles.account]: type === 'account' })}>
       <div className={styles.deckBoxImageWrapper}>
        <Wrapper>
          <Image
            className={styles.image}
            objectFit="cover"
            src={getFileUrl(deck.coverImageFile)}
            alt={deckName}
            fallback={<div className={styles.placeholderImageWrapper}>
              <img className={styles.placeholderImage} src={placeholderImage} alt={deckName} />
            </div>
            }
          />
          { showAvatar && (
            <Avatar
              { ...avatarStyle as any}
              name={deck.createdByUser.fullName}
              src={getFileUrl(deck.createdByUser.avatarFile)}
            />
          )}
        </Wrapper>
        { (currentUser && !deck.following && deck.createdBy !== currentUser?.id && !locked) && (
          <Button
            _hover={{ filter: 'brightness(90%)' }}
            aria-label="play audio"
            className={styles.followButton}
            onClick={onFollowClicked}
            color="#F79411"
            lineHeight="39px"
            height="43px"
            paddingLeft="16px"
            textTransform="none"
            backgroundRepeat="no-repeat"
            backgroundSize="133px"
            backgroundColor="white"
            border="2px solid #F79411"
            fontFamily={variables.normalFontFamily}
          >{t('follow')}</Button>)
        }
      </div>
      <div className={styles.deckBoxBottom}>
        <div className={styles.left}>
          <div className={styles.deckName}>{truncateText(deckName, 25)} ({ deck.cardsCount })</div>
          <div>
            {t('by_sb', { name: getName(deck.createdByUser.fullName) })}
          </div>
        </div>
        <div className={styles.followerCount}>
          <img src={followerCountIcon} alt="follower"/>
          <div className={styles.followerNumber}>{shortNumber(deck.followerCount)}</div>
        </div>
      </div>
    </div>
  );
};

export default DeckBox;
