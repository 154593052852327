import { useEffect, useCallback, useState, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';

import styles from './CardForm.module.scss';
import { Deck, StateType } from '../../types';
import { TriangleDownIcon } from '@chakra-ui/icons';
import variables from '../../variables.module.scss';
import useInput from '../../hooks/useInput';
import axios from '../../utils/axios';
import { AppContext } from '../../AppContext';
import getDeckName from '../../utils/getDeckName';

const selectStyle = {
  borderColor: '#97ACD990',
  _hover: {
    borderColor: '#97ACD9',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#97ACD9',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#97ACD9',
  iconSize: '14px',
};

const useDecks = () => {
  const [cardId] = useQueryParam('id', StringParam);
  const context = useContext(AppContext);
  const { t } = useTranslation('card_form');

  const location = useLocation<StateType>();

  const [deckId, onDeckIdChange, setDeckId] = useInput(location.state?.deckId || '');
  const [decks, setDecks] = useState<Deck[]>([]);

  const getDecks = useCallback(
    async () => {
      const response = await axios.get('/decks', { params: { type: 'created' } });
      setDecks(response.data.data.decks);
      if (!deckId && response.data.data.decks.length > 0 && cardId === 'new') {
        setDeckId(response.data.data.decks[0].id);
      }
    },
    [], // eslint-disable-line
  );

  const currentDeck = useMemo<Deck | undefined>(
    () => {
      return decks.find(deck => deck.id === deckId);
    },
    [decks, deckId],
  );

  useEffect(
    () => {
      getDecks();
    },
    [getDecks],
  );

  const component = <div className={styles.inputGroup}>
    <div className={styles.inputTitle}>{t('deck')}</div>
    <Select
      value={deckId}
      onChange={onDeckIdChange}
      {...selectStyle}
      icon={<TriangleDownIcon />}
      textAlign="center"
    >
      {decks.map(deck => (
        <option key={deck.id} value={deck.id}>
          {getDeckName(deck, context.currentUser)}
        </option>
      ))}
    </Select>
  </div>;

  return { component, setDeckId, deckId, currentDeck };
};

export default useDecks;
