import { Modal, ModalOverlay, ModalContent, Button, IconButton, ModalBody,
  ModalFooter, Stack, ModalHeader, Text,
  ModalHeaderProps, TextProps,
} from '@chakra-ui/react';
import styles from './Terms.module.scss';
import { useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import termsBackIcon from '../../images/terms-back.svg';
import variables from '../../variables.module.scss';

const Terms: React.FC = () => {
  const [showTerms, setShowTerms] = useState<boolean>(true);
  const context = useContext(AppContext);

  const onClose = () => {
    context.goBack();
  };

  const modalContentStyle = {
    borderRadius: '2xl',
    padding: '10px',
  };

  const modalHeaderStyle: ModalHeaderProps = {
    fontFamily: variables.titleFontFamily,
    color: '#F79411',
    fontWeight: '500',
    marginBottom: '20px',
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: '10px',
  };

  const createTextStyle: TextProps = {
    fontFamily: variables.normalFontFamily,
    color: '#2D65C2',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '13px',
    fontWeight: '700',
  };

  const modalBody = {
    paddingTop: '0px',
    paddingBottom: '40px',
    paddingRight: { base: '15px', md: '50px', lg: '80px' },
    paddingLeft: { base: '15px', md: '50px', lg: '80px' },
  };

  const stackStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    paddingBottom: '20px',
  };

  const footerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
  };

  const backStyle = {
    fontFamily: variables.titleFontFamily,
    color: '#F79411',
    letterSpacing: '0.2px',
    fontWeight: '500',
  };

  const linkStyle = {
    fontFamily: variables.normalFontFamily,
    color: '#16B5DF',
    textDecoration: 'underline',
    fontSize: '12px',
    fontWeight: '400',
    marginRight: '20px',
  };

  const contentTerms = (
    <div>
      <Text {...createTextStyle}>TERMS and CONDITIONS</Text>
      <Stack {...stackStyle}>
        <div className={styles.title}>Terms of Service</div>
        <div className={styles.paragraph}>
          The following terms and conditions govern all use of Cardoze (the 'Application') and all
          content, services and products available at or through the Application. The Application is
          owned and operated by Cardoze Incorporated The Application is offered subject to your
          acceptancewithout modification of all of the terms and conditions contained herein and all
          other operating rules, policies and procedures that may be updated from time to time on
          this page (collectively, the 'Agreement').
        </div>
        <div className={styles.paragraph}>
          Please read this Agreement carefully before accessing or using the Application. By
          accessing or using any part of the Application, you agree to become bound by the terms and
          conditions of this agreement. If you do not agree to all the terms and conditions of this
          agreement, then you may not access the Application or use any associated services. If
          these terms and conditions are considered an offer by Cardoze Incorporated, acceptance is
          expressly limited to these terms. The Application is available only to individuals who are
          at least 13 years old.
        </div>
        <div className={styles.title}>Intellectual Property</div>
        <div className={styles.paragraph}>
          This Agreement does not transfer from Cardoze Incorporated to you any Cardoze Incorporated
          or third party intellectual property, and all right, title and interest in and to such
          property will remain (as between the parties) solely with Cardoze Incorporated You agree
          not to disassemble, decompile, or reverse engineer the Application or its communication
          protocols. You agree not to alter, reproduce, adapt, distribute, display, or publish the
          source code of the Application. You agree not to create any source code derived from the
          Application.
        </div>
        <div className={styles.title}>Changes</div>
        <div className={styles.paragraph}>
          Cardoze Incorporated reserves the right, at its sole discretion, to modify or replace any
          part of this Agreement. It is your responsibility to check this Agreement periodically for
          changes. Your continued use of or access to the application following the posting of any
          changes to this Agreement constitutes acceptance of those changes. Cardoze Incorporated
          may also, in the future, offer new services and/or features through the Application
          (including, the release of new tools and resources). Such new features and/or services
          shall be subject to the terms and conditions of this Agreement.
        </div>
        <div className={styles.title}>Disclaimer of Warranties</div>
        <div className={styles.paragraph}>
          The Application is provided “as is”. Cardoze Incorporated and its suppliers and licensors
          hereby disclaim all warranties of any kind, express or implied, including, without
          limitation, the warranties of merchantability, fitness for a particular purpose and
          non-infringement. Neither Cardoze Incorporated nor its suppliers and licensors, makes any
          warranty that the Application will be error free or that access thereto will be continuous
          or uninterrupted. You understand that you download from, or otherwise obtain content or
          services through, the Application at your own discretion and risk.
        </div>
        <div className={styles.title}>Customer Support</div>
        <div className={styles.paragraph}>
          Cardoze Incorporated provides customer support at its discretion, and reserves the right
          to refuse support in cases of excessive support requests (as determined by Cardoze
          Incorporated), impoliteness, or for any other reason.
        </div>
        <div className={styles.title}>Limitation of Liability</div>
        <div className={styles.paragraph}>
          In no event will Cardoze Incorporated, or its suppliers or licensors, be liable with
          respect to any subject matter of this agreement under any contract, negligence, strict
          liability or other legal or equitable theory for:
        </div>
        <div className={styles.paragraph}>(i) any special, incidental or consequential damages</div>
        <div className={styles.paragraph}>
          (ii) the cost of procurement or substitute products or services;
        </div>
        <div className={styles.paragraph}>
          (iii) for interruption of use or loss or corruption of data; or
        </div>
        <div className={styles.paragraph}>
          (iv) for any amounts that exceed the fees paid by you to Cardoze Incorporated under this
          agreement during the three months period prior to the cause of action. Cardoze
          Incorporated shall have no liability for any failure or delay due to matters beyond their
          reasonable control. The foregoing shall not apply to the extent prohibited by applicable
          law.
        </div>
        <div className={styles.title}>General Representation and Warranty</div>
        <div className={styles.paragraph}>
          You represent and warrant that your use of the Application will be in strict accordance
          with the 'Cardoze Privacy Policy 'available at https://cardozeapp.com/privacy.html and
          incorporated herein by reference, with this Agreement and with all applicable laws and
          regulations (including without limitation any local laws or regulations in your country,
          state, city, or other governmental area, regarding online conduct and acceptable content,
          and including all applicable laws regarding the transmission of technical data exported
          from the Canada or the country in which you reside).
        </div>
        <div className={styles.title}>Indemnification</div>
        <div className={styles.paragraph}>
          You agree to indemnify and hold harmless Cardoze Incorporated, its contractors, and its
          licensors, and their respective directors, officers, employees and agents from and against
          any and all claims and expenses, including attorneys 'fees, arising out of your use of the
          Application, including but not limited to your violation of this Agreement.
        </div>
        <div className={styles.title}>Jurisdiction</div>
        <div className={styles.paragraph}>
          This Agreement shall be governed by and construed in accordance with the laws of the State
          of Delaware, without regard to Delaware conflict of law provisions, and without regard to
          the United Nations Convention on Contracts for the International Sale of Goods. The
          parties submit to the exclusive jurisdiction of the United States District Court for the
          District of Delaware and waive any defenses of lack of personal jurisdiction, the laying
          of venue or forum nonconveniens. If any provision of the Agreement is held unenforceable,
          the remaining provisions of this Agreement will remain in full effect and force.
        </div>
      </Stack>
    </div>
  );

  const contentPrivacy = (
    <div>
      <Text {...createTextStyle}>PRIVACY POLICY</Text>
      <Stack {...stackStyle}>
        <div className={styles.title}>Privacy Terms</div>
        <div className={styles.paragraph}>Last updated: June 4, 2021</div>
        <div className={styles.paragraph}>
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.
        </div>
        <div className={styles.paragraph}>
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy.
          This Privacy Policy has been created with the help of the cy Policy Generator.
        </div>
        <div className={styles.title}>Interpretation</div>
        <div className={styles.paragraph}>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural
        </div>
        <div className={styles.title}>Definitions</div>
        <div className={styles.paragraph}>For the purposes of this Privacy Policy:</div>
        <div className={styles.paragraph}>
          Account means a unique account created for You to access our Service or parts of our
          Service.
        </div>
        <div className={styles.paragraph}>
          Company (referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement)
          refers to Cardoze Incorporated, 300-136 Market Ave., Winnipeg, MB, Canada
        </div>
        <div className={styles.paragraph}>
          Cookies are small files that are placed on Your computer, mobile device or any other
          device by a website, containing the details of Your browsing history on that website among
          its many uses.
        </div>
        <div className={styles.paragraph}>Country refers to: Manitoba, Canada</div>
        <div className={styles.paragraph}>
          Device means any device that can access the Service such as a computer, a cellphone or a
          digital tablet.
        </div>
        <div className={styles.paragraph}>
          Personal Data is any information that relates to an identified or identifiable individual.
        </div>
        <div className={styles.paragraph}>Service refers to the Website</div>
        <div className={styles.paragraph}>
          Service Provider means any natural or legal person who processes the data on behalf of the
          Company. It refers to third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing how the Service is
          used.
        </div>
        <div className={styles.paragraph}>
          Usage Data refers to data collected automatically, either generated by the use of the
          Service or from the Service infrastructure itself (for example, the duration of a page
          visit).
        </div>
        <div className={styles.paragraph}>
          Website refers to Cardoze - Language at your fingertips!, accessible from
          https://cardozeapp.com/
        </div>
        <div className={styles.paragraph}>
          You means the individual accessing or using the Service, or the company, or other legal
          entity on behalf of which such individual is accessing or using the Service, as
          applicable.
        </div>
        <div className={styles.title}>Personal Data</div>
        <div className={styles.paragraph}>
          While using Our Service, We may ask You to provide Us with certain personally identifiable
          information that can be used to contact or identify You. Personally identifiable
          information may include, but is not limited to:
        </div>
        <div className={styles.paragraph}>Email address</div>
        <div className={styles.paragraph}>Usage Data</div>
        <div className={styles.title}>Usage Data</div>
        <div className={styles.paragraph}>
          Usage Data is collected automatically when using the Service. Usage Data may include
          information such as Your Device's Internet Protocol address, browser type, browser
          version, the pages of our Service that You visit, the time and date of Your visit, the
          time spent on those pages, unique device identifiers and other diagnostic data.
        </div>
        <div className={styles.paragraph}>
          When You access the Service by or through a mobile device, We may collect certain
          information automatically, including, but not limited to, the type of mobile device You
          use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
          operating system, the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </div>
        <div className={styles.paragraph}>
          We may also collect information that Your browser sends whenever You visit our Service or
          when You access the Service by or through a mobile device.
        </div>
        <div className={styles.title}>Tracking Technologies and Cookies</div>
        <div className={styles.paragraph}>
          We use Cookies and similar tracking technologies to track the activity on Our Service and
          store certain information. Tracking technologies used are beacons, tags, and scripts to
          collect and track information and to improve and analyze Our Service. The technologies We
          use may include:
        </div>
        <div className={styles.paragraph}>
          Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can
          instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
          However, if You do not accept Cookies, You may not be able to use some parts of our
          Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our
          Service may use Cookies.
        </div>
        <div className={styles.paragraph}>
          Flash Cookies. Certain features of our Service may use local stored objects (or Flash
          Cookies) to collect and store information about Your preferences or Your activity on our
          Service. Flash Cookies are not managed by the same browser settings as those used for
          Browser Cookies. For more information on how You can delete Flash Cookies, please read
          'Where can I change the settings for disabling, or deleting local shared objects?'
          available at
          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
        </div>
        <div className={styles.paragraph}>
          Web Beacons. Certain sections of our Service and our emails may contain small electronic
          files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
          gifs) that permit the Company, for example, to count users who have visited those pages or
          opened an email and for other related website statistics (for example, recording the
          popularity of a certain section and verifying system and server integrity).
        </div>
        <div className={styles.paragraph}>
          Cookies can be 'Persistent' or 'Session' Cookies. Persistent Cookies remain on Your
          personal computer or mobile device when You go offline, while Session Cookies are deleted
          as soon as You close Your web browser. You can learn more about cookies here: All About
          Cookies by TermsFeed.
        </div>
        <div className={styles.paragraph}>
          We use both Session and Persistent Cookies for the purposes set out below
        </div>
        <div className={styles.title}>Necessary / Essential Cookies</div>
        <div className={styles.paragraph}>Type: Session Cookies</div>
        <div className={styles.paragraph}>Administered by: Us</div>
        <div className={styles.paragraph}>
          Purpose: These Cookies are essential to provide You with services available through the
          Website and to enable You to use some of its features. They help to authenticate users and
          prevent fraudulent use of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide You with those
          services.
        </div>
        <div className={styles.title}>Cookies Policy / Notice Acceptance Cookies</div>
        <div className={styles.paragraph}>Type: Persistent Cookies</div>
        <div className={styles.paragraph}>Administered by: Us</div>
        <div className={styles.paragraph}>
          Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
        </div>
        <div className={styles.title}>Functionality Cookies</div>
        <div className={styles.paragraph}>Type: Persistent Cookies</div>
        <div className={styles.paragraph}>Administered by: Us</div>
        <div className={styles.paragraph}>
          Purpose: These Cookies allow us to remember choices You make when You use the Website,
          such as remembering your login details or language preference. The purpose of these
          Cookies is to provide You with a more personal experience and to avoid You having to
          re-enter your preferences every time You use the Website.
        </div>
        <div className={styles.paragraph}>
          For more information about the cookies we use and your choices regarding cookies, please
          visit our Cookies Policy or the Cookies section of our Privacy Policy
        </div>
        <div className={styles.title}>Use of Your Personal Data</div>
        <div className={styles.paragraph}>
          The Company may use Personal Data for the following purposes:
        </div>
        <div className={styles.paragraph}>
          To provide and maintain our Service, including to monitor the usage of our Service.
        </div>
        <div className={styles.paragraph}>
          To manage Your Account: ;to manage Your registration as a user of the Service. The
          Personal Data You provide can give You access to different functionalities of the Service
          that are available to You as a registered user.
        </div>
        <div className={styles.paragraph}>
          For the performance of a contract: the development, compliance and undertaking of the
          purchase contract for the products, items or services You have purchased or of any other
          contract with Us through the Service.
        </div>
        <div className={styles.paragraph}>
          To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms
          of electronic communication, such as a mobile application's push notifications regarding
          updates or informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or reasonable for
          their implementation.
        </div>
        <div className={styles.paragraph}>
          To provide You with news, special offers and general information about other goods,
          services and events which we offer that are similar to those that you have already
          purchased or enquired about unless You have opted not to receive such information.
        </div>
        <div className={styles.paragraph}>
          To manage Your requests: To attend and manage Your requests to Us.
        </div>
        <div className={styles.paragraph}>
          For business transfers: We may use Your information to evaluate or conduct a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
          or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by Us about our Service users is among the
          assets transferred.
        </div>
        <div className={styles.paragraph}>
          For other purposes: We may use Your information for other purposes, such as data analysis,
          identifying usage trends, determining the effectiveness of our promotional campaigns and
          to evaluate and improve our Service, products, services, marketing and your experience.
        </div>
        <div className={styles.paragraph}>
          We may share Your personal information in the following situations:
        </div>
        <div className={styles.paragraph}>
          With Service Providers: share Your personal information with Service Providers to monitor
          and analyze the use of our Service, to contact You.
        </div>
        <div className={styles.paragraph}>
          For business transfers: We may share or transfer Your personal information in connection
          with, or during negotiations of, any merger, sale of Company assets, financing, or
          acquisition of all or a portion of Our business to another company.
        </div>
        <div className={styles.paragraph}>
          With Affiliates: We may share Your information with Our affiliates, in which case we will
          require those affiliates to honor this Privacy Policy. Affiliates include Our parent
          company and any other subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </div>
        <div className={styles.paragraph}>
          With business partners: We may share Your information with Our business partners to offer
          You certain products, services or promotions.
        </div>
        <div className={styles.paragraph}>
          With other users: when You share personal information or otherwise interact in the public
          areas with other users, such information may be viewed by all users and may be publicly
          distributed outside.
        </div>
        <div className={styles.paragraph}>
          With Your consent: We may disclose Your personal information for any other purpose with
          Your consent.
        </div>
        <div className={styles.title}>Retention of Your Personal Data</div>
        <div className={styles.paragraph}>
          The Company will retain Your Personal Data only for as long as is necessary for the
          purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the
          extent necessary to comply with our legal obligations (for example, if we are required to
          retain your data to comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </div>
        <div className={styles.paragraph}>
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data is used to
          strengthen the security or to improve the functionality of Our Service, or We are legally
          obligated to retain this data for longer time periods.
        </div>
        <div className={styles.title}>Transfer of Your Personal Data</div>
        <div className={styles.paragraph}>
          Your information, including Personal Data, is processed at the Company's operating offices
          and in any other places where the parties involved in the processing are located. It means
          that this information may be transferred to — and maintained on — computers located
          outside of Your state, province, country or other governmental jurisdiction where the data
          protection laws may differ than those from Your jurisdiction.
        </div>
        <div className={styles.paragraph}>
          Your consent to this Privacy Policy followed by Your submission of such information
          represents Your agreement to that transfer.
        </div>
        <div className={styles.paragraph}>
          The Company will take all steps reasonably necessary to ensure that Your data is treated
          securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
          will take place to an organization or a country unless there are adequate controls in
          place including the security of Your data and other personal information.
        </div>
        <div className={styles.title}>Disclosure of Your Personal Data</div>
        <div className={styles.paragraph}>
          Business Transactions: If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before Your Personal Data is
          transferred and becomes subject to a different Privacy Policy.
        </div>
        <div className={styles.paragraph}>
          Law enforcement: Under certain circumstances, the Company may be required to disclose Your
          Personal Data if required to do so by law or in response to valid requests by public
          authorities (e.g. a court or a government agency).
        </div>
        <div className={styles.paragraph}>
          Other legal requirements: The Company may disclose Your Personal Data in the good faith
          belief that such action is necessary to:
        </div>
        <div className={styles.paragraph}>Comply with a legal obligation</div>
        <div className={styles.paragraph}>
          Protect and defend the rights or property of the Company
        </div>
        <div className={styles.paragraph}>
          Prevent or investigate possible wrongdoing in connection with the Service
        </div>
        <div className={styles.paragraph}>
          Protect the personal safety of Users of the Service or the public
        </div>
        <div className={styles.paragraph}>Protect against legal liability</div>
        <div className={styles.title}>Security of Your Personal Data</div>
        <div className={styles.paragraph}>
          The security of Your Personal Data is important to Us, but remember that no method of
          transmission over the Internet, or method of electronic storage is 100% secure. While We
          strive to use commercially acceptable means to protect Your Personal Data, We cannot
          guarantee its absolute security.
        </div>
        <div className={styles.title}>Protection of Certain Personally-Identifying Information</div>
        <div className={styles.paragraph}>
          Cardoze Incorporated will not rent or sell potentially personally-identifying or
          personally-identifying information to anyone. Cardoze Incorporated only discloses
          potentially personally-identifying and personally-identifying information, without your
          permission, in response to a subpoena, court order or other governmental request. Cardoze
          Incorporated takes all measures reasonably necessary to protect against the unauthorized
          access, use, alteration or destruction of potentially personally-identifying and
          personally-identifying information.
        </div>
        <div className={styles.title}>Children's Privacy</div>
        <div className={styles.paragraph}>
          Our Service does not address anyone under the age of 13. We do not knowingly collect
          personally identifiable information from anyone under the age of 13. If You are a parent
          or guardian and You are aware that Your child has provided Us with Personal Data, please
          contact Us. If We become aware that We have collected Personal Data from anyone under the
          age of 13 without verification of parental consent, We take steps to remove that
          information from Our servers.
        </div>
        <div className={styles.paragraph}>
          If We need to rely on consent as a legal basis for processing Your information and Your
          country requires consent from a parent, We may require Your parent's consent before We
          collect and use that information.
        </div>
        <div className={styles.title}>Links to Other Websites</div>
        <div className={styles.paragraph}>
          Our Service may contain links to other websites that are not operated by Us. If You click
          on a third party link, You will be directed to that third party's site. We strongly advise
          You to review the Privacy Policy of every site You visit.
        </div>
        <div className={styles.paragraph}>
          We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third party sites or services.
        </div>
        <div className={styles.title}>Changes to this Privacy Policy</div>
        <div className={styles.paragraph}>
          We may update Our Privacy Policy from time to time. We will notify You of any changes by
          posting the new Privacy Policy on this page.
        </div>
        <div className={styles.paragraph}>
          We will let You know via email and/or a prominent notice on Our Service, prior to the
          change becoming effective and update the 'Last updated' date at the top of this Privacy
          Policy.
        </div>
        <div className={styles.paragraph}>
          You are advised to review this Privacy Policy periodically for any changes. Changes to
          this Privacy Policy are effective when they are posted on this page.
        </div>
        <div className={styles.title}>Contact Us</div>
        <div className={styles.paragraph}>
          If you have any questions about this Privacy Policy, You can contact us by email:
          hello@cardozeapp.com
        </div>
      </Stack>
    </div>
  );

  const TermsPrivacyBody: React.FC = () => {
    return (showTerms ? contentTerms : contentPrivacy);
  };

  return (
    <Modal size="xl" isOpen={true} scrollBehavior="inside" onClose={onClose}>
      <ModalOverlay />
      <ModalContent {...modalContentStyle}>
        <ModalHeader {...modalHeaderStyle}>
          <div className={styles.headerBlock}>
            <div className={styles.backRegister}>
              <IconButton icon={<img alt="back to" src={termsBackIcon} />}
                aria-label="back to" onClick={onClose} variant="link">
              </IconButton>
              <Button {...backStyle} variant="link" onClick={onClose}>
                REGISTER
              </Button>
            </div>
            <div className={styles.optionBox}>
              <Button {...linkStyle} variant="link" onClick = {
                () => setShowTerms(true) }>Terms and Conditions</Button>
              <Button {...linkStyle} variant ="link" onClick = {
                () => setShowTerms(false)}>Privacy Policy</Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody {...modalBody}>
            <TermsPrivacyBody />
          <ModalFooter {...footerStyle}>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Terms;
